<ng-container *ngIf="!hide && field.show !== false">
    <ng-container *transloco="let translate;" [ngSwitch]="getFieldType(field, parentRecord ? parentRecord : record, parentRecord ? record : undefined)">
        <ng-container *ngSwitchCase="'TEXT'">
            <ng-container *ngIf="field.array || (field.conditionalArray && isConditionalArray(field))">
                <div class="row">
                    <div class="col-12">
                        <ng-container *ngFor="let valueObj of field.values; let i = index">
                            <div class="integrationRecordArrayTextFieldContainer">
                                <div class="integrationRecordTextFieldContainer">
                                    <material-input [autofocus]="false" [model]="valueObj.value" [placeholder]="translate(field.displayName)" (modelChange)="valueObj.value = $event; field.errors && field.errors[i] = []; isFormValid(record, field, subSectionRecord)" [required]="field.required && i === 0" [errors]="$any(field.errors?.[i])" [debounceAfter]="0"></material-input>
                                </div>
                                <div class="integrationRecordTextFieldActions">
                                    <div class="integrationRecordFieldActionText">
                                        <mat-icon (click)="field.values.push({ value: ''})">add</mat-icon>
                                    </div>
                                    <div class="integrationRecordFieldActionText">
                                        <mat-icon (click)="deleteFieldValue(field, i, record)">delete_forever</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="(!field.array && !field.conditionalArray) || (field.conditionalArray && !isConditionalArray(field))">
                <div class="subjectInput">
                    <material-input [autofocus]="false" [placeholder]="translate(field.displayName)" [model]="field.value" (modelChange)="field.value = $event; isFormValid(record, field, subSectionRecord);" [name]="translate(field.displayName)" [id]="field.id" [required]="field.required" [errors]="field.errors" [disabled]="isFieldDisabled()" [debounceAfter]="0"></material-input>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'INPUT_RADIO'">
            <div class="row" style="line-height: 65px">
                <div class="radioFieldName">
                    <label style="display: inline-block; margin-right: 0px;">
                        {{translate(field.displayName)}}
                    </label>
                </div>
                <div>
                    <div style="display: inline-block">
                        <mat-radio-group [ngClass]="field.placeHolderMapping ? 'MappingradioButton' :'inputRadioGroup'" [(ngModel)]="field.value" (change)="toggleFieldValues(field, record, field.value); isFormValid(record, field, subSectionRecord); toggleValueChanged(field, record, field.value);">
                            <mat-radio-button class="inputRadioButton" *ngFor="let option of getOptions(field, record)" [value]="option.value">
                                {{option.displayName}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'PASSWORD'">
            <material-input-password [autofocus]="false" [errors]="field.errors" [name]="translate(field.displayName)" [placeholder]="translate(field.displayName)" [model]="field.value" [id]="field.id" (modelChange)="field.value = $event;  isFormValid(record, field, subSectionRecord);" [required]="field.required" [debounceAfter]="0">
            </material-input-password>
        </ng-container>
        <ng-container *ngSwitchCase="'MASTER_DROPDOWN'">
            <material-select [required]="field.required" [emitFirstEvent]="true" [options]="masterTables" [errors]="field.errors" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'itemId'" [placeholder]="translate(field.displayName)" [model]="field.value" (modelChange)="field.value = $event; getMasterColumns.emit(field.value);isFormValid(record, field, subSectionRecord);">
            </material-select>
        </ng-container>
        <ng-container *ngSwitchCase="'OPTIONS'">
            <material-select [required]="field.required" [options]="field.options" [createOptionRequired]="this.field?.createOptionRequired" [errors]="field.errors" [placeholder]="translate(field.displayName)" [model]="field.value" (modelChange)="field.value = $event; field.onChange && field.onChange(field.value, field); isFormValid(record, field, subSectionRecord); changekValidateButton();" (createOptionCallBack)="createOptionClick()" (editOptionCallBack)="editOptionClick($event)">
            </material-select>
        </ng-container>
        <ng-container *ngSwitchCase="'DROPDOWN'">
            <ng-container *ngIf="field.values === undefined">
                <material-select [required]="field.required" [options]="masterTableColumns" [errors]="field.errors" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'id'" [placeholder]="field.dynamicField ? field.displayName : translate(field.displayName)" [model]="field.value" (modelChange)="field.value = $event;isFormValid(record, field, subSectionRecord);">
                </material-select>
            </ng-container>
            <ng-container *ngIf="field.values !== undefined">
                <material-select [required]="field.required" [options]="masterTableColumns" [errors]="field.errors" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'id'" [placeholder]="field.dynamicField ? field.displayName : translate(field.displayName)" [model]="field.values[0].value" (modelChange)="field.values[0].value = $event;isFormValid(record, field, subSectionRecord);">
                </material-select>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'TEXTAREA'">
            <material-textarea [placeholder]="translate(field.displayName)" [model]="field.value" (modelChange)="field.value = $event;isFormValid(record, field, subSectionRecord);" [name]="translate(field.displayName)" [id]="field.id" [required]="field.required" [errors]="field.errors" [debounceAfter]="0"></material-textarea>
        </ng-container>
        <ng-container *ngSwitchCase="'CHECKBOX'">
            <ng-container *ngIf="!field.hideLabel">
                <material-checkbox [name]="translate(field.displayName)" [title]="translate(field.displayName)" [model]="field.value" (modelChange)="field.value = $event; field.errors = []; isFormValid(record, field, subSectionRecord);">
                </material-checkbox>
            </ng-container>
            <ng-container *ngIf="field.hideLabel">
                <material-checkbox [name]="" [id]="field.id" title="{{field.id}}" [model]="field.value" (modelChange)="field.value = $event; field.errors = []; isFormValid(record, field, subSectionRecord);">
                </material-checkbox>
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'SOURCES_DROPDOWN'">
            <material-select [required]="field.required" [options]="sources" [errors]="field.errors" [placeholder]="translate(field.displayName)" [model]="field.value" (modelChange)="field.value = $event; isFormValid(record);">
            </material-select>
        </ng-container>
        <ng-container *ngSwitchCase="'CHAIN_DROPDOWN'">
            <material-selectgroup [required]="field.required" [options]="field.options" [errors]="field.errors" [placeholder]="translate(field.displayName)" [model]="field.value" [optionDisplayNameProperty]="'chainName'" [optionValueProperty]="'chainName'" (modelChange)="field.value = $event; isFormValid(record); chainChanged.emit(field.value)" [groupDisplayNameProperty]="'orgName'" [groupValueProperty]="'chains'" [disabled]="isFieldDisabled()" (selectedOption)="chainChanged.emit($event)" [emitFirstEvent]="true">
            </material-selectgroup>
        </ng-container>
        <ng-container *ngSwitchCase="'INTERNALCONFIG_DROPDOWN'">
            <material-select [required]="field.required" [options]="field.options" [errors]="field.errors"
                             [placeholder]="translate(field.displayName)" [model]="field.value"
                             [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'id'" [emitFirstEvent]="true"
                             (modelChange)="field.value = $event; isFormValid(record);">
            </material-select>
        </ng-container>
        <ng-container *ngSwitchCase="'DYNAMIC_PARAMS_DROPDOWN'">
            <material-select [required]="field.required" [options]="field.options" [errors]="field.errors"
                             [placeholder]="translate(field.displayName)" [model]="field.value || field.options[0]?.id"
                             [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'id'" [emitFirstEvent]="true"
                             (modelChange)="field.value = $event; field.onChange && field.onChange(field.value, field); isFormValid(parentRecord, field, record);">
            </material-select>
        </ng-container>
        <ng-container *ngSwitchCase="'TRANSFORMATIONS_DROPDOWN'">
            <material-select [required]="field.required" [options]="field.options" [errors]="field.errors" [placeholder]="translate(field.displayName)" [model]="field.value" [optionDisplayNameProperty]="'displayName'" [optionValueProperty]="'id'" [emitFirstEvent]="true" (modelChange)="field.value = $event; isFormValid(record); transformationsChanged.emit(field.value)" [disabled]="isFieldDisabled()" [emitEvent]="true">
            </material-select>
        </ng-container>
        <ng-container *ngSwitchCase="'API_CONFIGURED_LIST'">
            <material-selectgroup [required]="field.required" [options]="configuredRecords || field.options" [errors]="field.errors" [placeholder]="translate(field.displayName)" [model]="field.value" [optionDisplayNameProperty]="'name'" [optionValueProperty]="'name'" (modelChange)="field.value = $event; isFormValid(record, field, subSectionRecord);" [groupDisplayNameProperty]="'orgName'" [groupValueProperty]="'configs'" (selectedOption)="configNameChanged.emit($event)">
            </material-selectgroup>
        </ng-container>
        <ng-container *ngSwitchCase="'API_CONFIG'">
            <material-select [required]="field.required" [options]="field.options" [errors]="field.errors" [placeholder]="translate(field.displayName)" [disabled]="isFieldDisabled()" [optionValueProperty]="'value'" [model]="field.value" [emitFirstEvent]="true" (modelChange)="field.value = $event; apiConfigChange.emit(field.value); isFormValid(record, field, subSectionRecord);">
            </material-select>
        </ng-container>
        <ng-container *ngSwitchCase="'MASTER_TRANSFORMATIONS_DROPDOWN'">
            <material-select [required]="field.required" [options]="field.options" [errors]="field.errors" [placeholder]="translate(field.displayName)" [model]="field.value" [optionDisplayNameProperty]="'name'" [optionValueProperty]="'name'" (modelChange)="field.value = $event; isFormValid(record, field, subSectionRecord);">
            </material-select>
        </ng-container>
        <ng-container *ngSwitchCase="'TRANSFORMATION_PANEL'">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{translate('Transformations')}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div class="col-12 nopadding" *ngFor="let mapping of field.options">
                        <div class="col-6 displyInlineBlock nopadding">{{mapping.displayName}} : </div>
                        <div class="col-6 displyInlineBlock nopadding">
                            <material-input [autofocus]="false" [placeholder]="translate('Folder Path')" [model]="mapping.value" (modelChange)="mapping.value = $event; setPanelTransformation(field, mapping, $event); isFormValid(record, field, subSectionRecord);" [name]="translate('Folder Path')" [id]="mapping.displayName" [required]="true" [errors]="field.errors" [debounceAfter]="0"></material-input>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </ng-container>
        <ng-container *ngSwitchCase="'ORGANIZATIONS_CHECKBOX'">
            <material-group-checkbox [menuName]="translate(field.displayName)" [options]="subTenants" [value]="field.value" [optionDisplayNameProperty]="'displayTenantName'" [optionValueProperty]="'id'" [required]="field.required" [dontShowValue]="true" [errors]="field.errors?.length > 0 && $any(field.errors)" (onOptionsChanged)="valueChanged($event)"></material-group-checkbox>
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{field | json}}
        </ng-container>
    </ng-container>
</ng-container>
