import { createReducer, on } from '@ngrx/store';

import * as sessionActions from '../actions/session.actions';
import { customState } from '../states/custom.state';

export const CUSTOM_REDUCER = createReducer(
    customState,
    on(sessionActions.SetCurrentOrganization, (state, { organization }) => ({
        ...state,
        currentOrganizationId: organization?.id,
    })),
    on(sessionActions.SessionLogoutSuccessfull, () => customState)
);
export const CUSTOM_REDUCER_KEY = 'custom';
