import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '@env';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { filter, Subject, takeUntil } from 'rxjs';
import {
    CommonUtilsService,
    CompletedSwitchToOrganization,
    GetApplicationProperties,
    getApplicationProperties$,
    SetCurrentOrganization,
    SetLogoHref,
    StoreService,
    UtilsService,
} from 'taxilla-library';

import { RootScope } from '../../services/rootscope.service';
import { VersionCheckService } from '../../services/version-check/version-check.service';

@Component({
    selector: 'app-main-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'taxilla';
    isPostLogin: any;
    RouteRefreshSubscription: any;
    hasOrgId: string;
    hideBody = false;
    public supportSystem = environment.supportSystem;
    public isNewUI: boolean;
    private unSubscribe = new Subject<void>();

    constructor(
        protected _breakpointObserver: BreakpointObserver,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        public R: RootScope,
        private _utils: UtilsService,
        private _commonUtils: CommonUtilsService,
        private _broadcaster: BroadcasterService,
        public versionCheckService: VersionCheckService,
        private _store: StoreService,
        private store$: Store,
        private actions$: Actions
    ) {
        if (environment?.defaultNewUISkin || environment?.['environment']?.defaultNewUISkin) {
            const onNewUI = this._commonUtils.getCookie('new-ui');
            (onNewUI === undefined || onNewUI === null) && this._commonUtils.setCookie('new-ui', 'true');
        }
        this.setBodyContext(this._router.url);
        const origin = window.location.origin;
        if (this._commonUtils.isBrowserIE()) {
            this.addStorageChangeEvent();
        }
        if (origin) {
            this.setTitle(origin);
        }
        this.isNewUI = this._commonUtils.getCookie('new-ui') === 'true';
        const organization = this._commonUtils.getFromStorage('currentOrganization');
        organization?.id?.length > 0 && this.store$.dispatch(SetCurrentOrganization({ organization }));
    }

    setTitle = (origin) => {
        const isKpmg = origin.indexOf('kpmg') > -1;
        const isEmg = origin.indexOf('emg') > -1 || origin.indexOf('easemygst') > -1;
        const title = isKpmg ? 'KPMG GST' : isEmg ? 'EaseMyGST' : 'enComply';
        this._utils.setTitle(title);
    };

    addStorageChangeEvent = () => {
        window.onstorage = (e: any) => {
            console.log(e?.key);
        };
    };

    triggerResponsiveness = () => {
        if (this.R.device !== 'device-desktop' && !this.isPostLogin) {
            this.R.responsive = 'app-responsive';
        } else {
            this.R.responsive = '';
        }
    };

    refreshCurrentRoute = () => {
        const currentUrl = this._router.url;
        ((url) => {
            this.RouteRefreshSubscription = this._router.events.pipe(takeUntil(this.unSubscribe)).subscribe((event) => {
                if (event instanceof ChildActivationEnd) {
                    this._router.navigate([url]);
                }
            });
            setTimeout(() => {
                this.RouteRefreshSubscription?.unsubscribe?.();
            }, 1000);
        })(currentUrl);
    };

    private logout = () => {
        this._commonUtils.setInStorage('session', false);
        this.hasOrgId = undefined;
        if (this._router.url === '/login') {
            this.refreshCurrentRoute();
        }
        // $('.modal').modal('hide');
    };

    private checkIfPostLogin = () => {
        const routes = window.location.pathname.split('/');
        const orgIndex = routes.indexOf('organizations');
        this.hasOrgId = orgIndex > -1 && routes[orgIndex + 1];
    };

    private hideBodyIfNotInContext = (href: string) => {
        href = location.origin + href;
        const isInEnReport = href.indexOf(environment['enreport-ui']) > -1;
        const isInEnInvoice =
            href.indexOf(environment['eninvoice-ui']) > -1 || href.indexOf(environment['encomply-ui'] + '/collaboration') > -1;
        const isInEnCollab = href.indexOf(environment['encollab-ui']) > -1;
        const isInEnReconcile = href.indexOf(environment['enreconcile-ui']) > -1;
        const isInGstFiling = href.indexOf(environment['gst-filing']) > -1;
        const isInCustomUI = href.indexOf(environment['custom']) > -1;
        const isInEnComply = !isInEnReport && !isInEnInvoice && !isInEnCollab && !isInEnReconcile && !isInGstFiling && !isInCustomUI;
        const bodyClass = document.querySelector('body').getAttribute('class');
        if (isInEnComply && bodyClass.indexOf('enComplyMainContainer') === -1) {
            this.hideBody = true;
        } else if (isInEnReport && bodyClass.indexOf('enReportMainContainer') === -1) {
            this.hideBody = true;
        } else if (isInEnInvoice && bodyClass.indexOf('enInvoiceMainContainer') === -1) {
            this.hideBody = true;
        } else if (isInEnCollab && bodyClass.indexOf('enCollabMainContainer') === -1) {
            this.hideBody = true;
        } else if (isInEnReconcile && bodyClass.indexOf('enReconcileMainContainer') === -1) {
            this.hideBody = true;
        } else if (isInGstFiling && bodyClass.indexOf('gstFilingMainContainer') === -1) {
            this.hideBody = true;
        }
    };

    private setBodyContext = (href: string) => {
        href = location.origin + href;
        const isInAuthUI = href.indexOf(environment['auth-ui']) > -1;
        const isInEnReport = href.indexOf(environment['enreport-ui']) > -1;
        const isInEnInvoice =
            href.indexOf(environment['eninvoice-ui']) > -1 || href.indexOf(environment['encomply-ui'] + '/collaboration') > -1;
        const isInEnCollab = href.indexOf(environment['encollab-ui']) > -1;
        const isInEnRecon = href.indexOf(environment['enreconcile-ui']) > -1;
        const isInGstFiling = href.indexOf(environment['gst-filing']) > -1;
        const isInCustomUI = href.indexOf(environment['custom']) > -1;
        const isInEnComply =
            !isInEnReport && !isInEnInvoice && !isInAuthUI && !isInEnCollab && !isInEnRecon && !isInGstFiling && !isInCustomUI;
        const bodyClass = document.querySelector('body').getAttribute('class');
        const onNewUI = this._commonUtils.getCookie('new-ui') === 'true';
        if (isInAuthUI && bodyClass.indexOf('autheticationContainer') === -1) {
            this._commonUtils.setBodyContext('autheticationContainer enComplyMainContainer');
        } else if (
            isInEnComply &&
            (bodyClass.indexOf('enComplyMainContainer') === -1 || bodyClass.indexOf('autheticationContainer') > -1)
        ) {
            this._commonUtils.setBodyContext('enComplyMainContainer');
        } else if (isInEnComply && onNewUI && bodyClass.indexOf('onNewUI') === -1) {
            this._commonUtils.setBodyContext('enComplyMainContainer');
        } else if (isInEnReport && bodyClass.indexOf('enReportMainContainer') === -1) {
            this._commonUtils.setBodyContext('enReportMainContainer');
        } else if (isInEnInvoice && bodyClass.indexOf('enInvoiceMainContainer') === -1) {
            this._commonUtils.setBodyContext('enInvoiceMainContainer');
        } else if (isInEnCollab && bodyClass.indexOf('enCollabMainContainer') === -1) {
            this._commonUtils.setBodyContext('enCollabMainContainer');
        } else if (isInEnRecon && bodyClass.indexOf('enReconMainContainer') === -1) {
            this._commonUtils.setBodyContext('enReconMainContainer');
        } else if (isInGstFiling && bodyClass.indexOf('gstFilingMainContainer') === -1) {
            this._commonUtils.setBodyContext('gstFilingMainContainer');
        }
        this.hideBody = false;
    };

    protected getApplicationProperties = (baseUrl: string) => {
        if (baseUrl === 'mapro') {
            this.store$.dispatch(GetApplicationProperties());
        }
    };

    setApplicationProperties = (response) => {
        if (response?.['analytics.service.types']?.length > 0) {
            this._commonUtils.analyticsServiceTypes = response['analytics.service.types'];
        }
    };

    ngOnInit() {
        this.store$.dispatch(SetLogoHref({ logoHref: environment.logoHref }));
        this._breakpointObserver
            .observe(['(orientation: portrait)'])
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((result) => {
                if (this.R.orientation !== 'orientation-portrait' && result.matches) {
                    this.R.orientation = 'orientation-portrait';
                }
                this.triggerResponsiveness();
            });

        this._breakpointObserver
            .observe(['(orientation: landscape)'])
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((result) => {
                if (this.R.orientation !== 'orientation-landscape' && result.matches) {
                    this.R.orientation = 'orientation-landscape';
                }
                this.triggerResponsiveness();
            });

        this._breakpointObserver
            .observe(['(min-width: 1200px)'])
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((result) => {
                result.matches && (this.R.device = 'device-desktop');
                this.triggerResponsiveness();
            });

        this._breakpointObserver
            .observe(['(max-width: 1199px)'])
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((result) => {
                result.matches && (this.R.device = 'device-handset');
                this.triggerResponsiveness();
            });

        this._router.events.pipe(takeUntil(this.unSubscribe)).subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.hideBodyIfNotInContext(event.url);
            } else if (event instanceof NavigationEnd) {
                this.setBodyContext(event.url);
                this.isPostLogin =
                    this._activatedRoute.snapshot.children &&
                    this._activatedRoute.snapshot.children[0] &&
                    this._activatedRoute.snapshot.children[0].data &&
                    this._activatedRoute.snapshot.children[0].data.secured;
                this.triggerResponsiveness();
                this.checkIfPostLogin();
            }
        });

        this._broadcaster
            .on('logout')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data: { noRedirect: boolean; hidePopup: boolean }) => {
                this._store.logout();
                this.logout();
                this._utils.postLogout(data && (data.noRedirect !== undefined ? !data.noRedirect : false), data && data.hidePopup);
                const origin = window.location.origin;
                if (origin) {
                    this.setTitle(origin);
                }
            });

        this._broadcaster
            .on('switchingOrganization')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(() => {
                this._store.clearLoading();
            });

        this.actions$
            .pipe(
                ofType(CompletedSwitchToOrganization),
                filter((data) => data.switched)
            )
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((data) => {
                this._store.clearLoading();
                this._commonUtils.setInStorage('currentOrganizationId', data.organization?.id);
                data.organization && this._commonUtils.setInStorage('currentOrganization', data.organization);
            });

        this._broadcaster
            .on('session')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event: boolean) => {
                this.R.session.next(event);
            });

        this._broadcaster
            .on('loggingIn')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((event: boolean) => {
                this.R.loggingIn = event;
            });

        setTimeout(() => {
            this.triggerResponsiveness();
            this.versionCheckService.initVersionCheck();
        });
        this._broadcaster
            .on('setStoreContext')
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(() => this._store.setOrganizationId());

        this.store$
            .select(getApplicationProperties$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((res) => res !== undefined)
            )
            .subscribe({
                next: (res) => {
                    this.setApplicationProperties(res);
                },
            });
        this.getApplicationProperties('mapro');
    }

    ngOnDestroy() {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
