import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { RootScope } from '@enreport/modules/core/services/rootscope/rootscope.service';
import { environment } from '@env';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { AssetService } from '../../models/assetservice.class';
import { ApiService } from '../../services/api/api.service';
import { AssetsService } from '../../services/assets/assets.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { SubscriptionsService } from '../../services/subscriptions/subscriptions.service';
import { UtilsService } from '../../services/utils/utils.service';
import { SetCurrentOrganization } from '../../store/actions';

@Component({
    selector: 'app-cards',
    templateUrl: './home-processes.component.html',
    styleUrls: ['./home-processes.component.scss'],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: '',
        },
    ],
})
export class HomeProcessesComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() subscribedApps: BehaviorSubject<AssetService[]>;
    @Input() loadingCall: any;
    @Input() newProcessInSameTab?: boolean;
    @Input() allProcessesInSameTab?: boolean;
    @Input() processInSameTab?: boolean;
    @Input() navigateToAppHomeOnly: boolean;
    @Input() allReportsInSameTab: boolean;
    @Input() showMarquee: boolean;
    @Input() marqueeText: string;
    @Input() inEncomply: boolean;
    @Input() inEnreport: boolean;

    private apps: AssetService[];
    public appsToShow: AssetService[] = [];
    public searchText = '';
    private appliedSearchText = '';
    private searchAbleApps: AssetService[] = [];
    public loading: any = false;
    public isSearch = false;
    public noApps = false;
    public isFilterVisible = false;
    public tagKey: string;
    public tagValue: string;
    public selectedAsset: string;
    public totalKeys = [];
    public keyValues = [];
    public searchedAssets = [];
    public keys: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public values: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public assets: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public isTagBasedSearch = false;
    private isAppNewObj = {};
    private lastPublilshedTimeObj = {};
    private recentServiceIds: any[];
    private recentServicesLoading = false;
    private unSubscribe = new Subject<void>();
    public hideMarqueeMsg: boolean;
    private appsLoading: string[] = [];
    public isLoadMoreDisable: boolean = false;
    public uiList: {
        routeLink: string;
        displayName: string;
        show: boolean;
        beta?: boolean;
    }[] = [];

    constructor(
        public _utils: UtilsService,
        private _assets: AssetsService,
        private _api: ApiService,
        private subscriptions: SubscriptionsService,
        private store$: Store,
        private _ngZone: NgZone,
        public R: RootScope
    ) {}

    public isAppNew = (app) => {
        let isAppNewBool = false;
        isAppNewBool = this.isAppNewObj[app.serviceId];
        if (isAppNewBool !== undefined) {
            return isAppNewBool;
        } else {
            isAppNewBool =
                new Date().getTime() -
                    new Date(
                        CommonUtilsService.transformDate(app.lastModifiedDate, 'DD/MM/YYYY HH:MM:SS AM', 'MM-DD-YYYY HH:MM:SS AM')
                    ).getTime() <
                30 * 24 * 60 * 60 * 1000;
            this.isAppNewObj[app.serviceId] = isAppNewBool;
        }
        return isAppNewBool;
    };

    public getOriginalLastModifiedDate = (app) => {
        let lastModifiedDate = '';
        lastModifiedDate = this.lastPublilshedTimeObj[app.serviceId];
        if (lastModifiedDate !== undefined) {
            return lastModifiedDate;
        } else {
            lastModifiedDate = CommonUtilsService.transformDateToLocale(
                app.originalLastModifiedDate,
                'YYYY-MM-DDTHH:MM:SSZ',
                'DD-MM-YYYY HH:MM:SS AM',
                false
            );
            this.lastPublilshedTimeObj[app.serviceId] = lastModifiedDate;
        }
        return lastModifiedDate;
    };

    private getRecentlyAccessedServices = (isFirstSearch?) => {
        this.subscriptions.getRecentlyAccessedServices({
            successCallback: (res) => {
                this.recentServiceIds = res?.response?.recentlyAccessedServices;
                this.showApps(this.recentServiceIds);
                isFirstSearch ? (this.recentServicesLoading = false) : true;
            },
            failureCallback: () => {
                isFirstSearch ? (this.recentServicesLoading = false) : true;
                this.showApps();
            },
        });
    };

    private startComponent = (isFirstSearch?) => {
        this.appsToShow = [];
        if (!this.noApps && !this.recentServicesLoading) {
            if (isFirstSearch) {
                this.recentServicesLoading = true;
                this.getRecentlyAccessedServices(isFirstSearch);
            } else {
                this.showApps();
            }
        }
    };

    public loadMoreApps = () => {
        if (!this.recentServicesLoading) {
            this._ngZone.run(() => {
                if (!this.appsToShow?.length && !this.recentServiceIds?.length) {
                    this.getRecentlyAccessedServices();
                } else {
                    this.showApps();
                }
            });
        }
    };

    /**
     * Method to push apps for rendering
     * @param recentServiceIds List of serviceIds of last used services
     * @returns void
     */
    public showApps = async (recentServiceIds?: any[]) => {
        if (this.loading) {
            return;
        }
        const length = this.appsLoading.length + this.appsToShow.length;
        let lowerLimit = length > 0 ? length : 0;
        const upperLimit = length > 0 ? length + 5 : 5;
        if (length % 5 !== 0) {
            return;
        }
        this.loading = true;
        if (this.apps && this.apps.length > 0) {
            this.apps = this._utils.transformSortingOrder(this.apps, 'name');
        }
        let services = [];
        if ((this.appliedSearchText && this.appliedSearchText.length > 0) || this.isTagBasedSearch) {
            services = CommonUtilsService.cloneObject(this.searchAbleApps);
        } else {
            services = CommonUtilsService.cloneObject(this.apps);
        }
        const appsToShow = [];
        if (recentServiceIds?.length > 0) {
            services?.sort((a, b) => {
                let aIndex = recentServiceIds.indexOf(a.serviceId);
                let bIndex = recentServiceIds.indexOf(b.serviceId);
                if (aIndex === -1 && bIndex === -1) {
                    return 0;
                }
                if (aIndex === -1) {
                    return 1;
                }
                if (bIndex === -1) {
                    return -1;
                }
                return aIndex - bIndex;
            });
        }
        services
            ?.filter((_app, index) => {
                return index >= lowerLimit && index < upperLimit;
            })
            ?.forEach((app) => {
                app.loading = app.loading || { assetMetaData: false };
                appsToShow.push(app);
            });
        this.appsLoading = [
            ...(this.appsLoading || []),
            ...appsToShow.reduce((appsLoading, app) => {
                appsLoading.push(app.id);
                return appsLoading;
            }, []),
        ];
        await this._api.permissions.transformAppsWithPermissions(appsToShow, true);
        appsToShow.forEach((app) => {
            this.appsLoading.splice(
                this.appsLoading.findIndex((id) => id === app.id),
                1
            );
            app.loading = app.loading || { assetMetaData: false };
            this.appsToShow.push(app);
        });
        this.loadMoreDisable();
        this.loading = false;
    };

    private loadMoreDisable = () => {
        this.isLoadMoreDisable = false;
        if (this.appsToShow.length % 5 !== 0) {
            this.isLoadMoreDisable = true;
        } else if (this.apps.length === this.appsToShow.length) {
            this.isLoadMoreDisable = true;
        }
    };

    public searchFocus = (event) => {
        if (event.type === 'blur') {
            this.isSearch = false;
        } else {
            this.isSearch = true;
        }
    };

    public checkSearchTextEvent = (event) => {
        this.isSearch = true;
        if (event.keyCode === 8) {
            if (!this.searchText || this.searchText.length === 0) {
                this.clearSearch();
            }
        } else if (event.keyCode === 13) {
            if (this.searchText && this.searchText.length > 0) {
                this.applySearch();
            }
        }
    };

    public clearSearch = (startComponent?: boolean) => {
        this.searchText = '';
        this.selectedAsset = '';
        this.tagValue = undefined;
        this.tagKey = undefined;
        this.keyValues = [];
        this.values.next([]);
        this.searchedAssets = [];
        this.assets.next([]);
        if (this.appliedSearchText?.length > 0 || this.isTagBasedSearch || startComponent) {
            this.appliedSearchText = undefined;
            this.isTagBasedSearch = false;
            this.startComponent(true);
        }
    };

    public applySearch = () => {
        let search = '';
        const inputElement = document.querySelector('.mat-autocomplete-panel') as HTMLInputElement;
        if (this.isFilterVisible || this.isTagBasedSearch) {
            this.searchText = undefined;
            if (this.selectedAsset && this.selectedAsset.length > 0) {
                search = this.selectedAsset && this.selectedAsset.trim();
                let selectedApps = [];
                selectedApps = this.apps.filter((app) => {
                    const success = this.isRequiredApp(app);
                    return success;
                });
                this.appliedSearchText = search;
                this.searchAbleApps = selectedApps.filter((app) => {
                    let success = true;
                    let index = -1;
                    const text = this.appliedSearchText.split('');
                    let appName = app.displayName || app.name;
                    text.forEach((character) => {
                        const currentIndex = appName.toLowerCase().indexOf(character.toLowerCase());
                        success = success && currentIndex > -1 && currentIndex >= index;
                        if (success) {
                            appName = appName.substring(0, currentIndex) + appName.substring(currentIndex + 1, appName.length);
                            index = currentIndex;
                        }
                    });
                    return success;
                });
                this.startComponent();
            } else {
                this.isTagBasedSearch = true;
                this.searchAbleApps = this.apps.filter((app) => {
                    const success = this.isRequiredApp(app);
                    return success;
                });
                this.startComponent();
            }
        } else {
            search = this.searchText.trim();
            if (search && search.length > 0) {
                this.appliedSearchText = search;
                this.searchAbleApps = this.apps.filter((app) => {
                    let success = true;
                    let index = -1;
                    const text = this.appliedSearchText.split('');
                    let appName = app.displayName || app.name;
                    text.forEach((character) => {
                        const currentIndex = appName.toLowerCase().indexOf(character.toLowerCase());
                        success = success && currentIndex > -1 && currentIndex >= index;
                        if (success) {
                            appName = appName.substring(0, currentIndex) + appName.substring(currentIndex + 1, appName.length);
                            index = currentIndex;
                        }
                    });
                    return success;
                });
                this.startComponent();
            }
        }
        this.isFilterVisible = false;
        if (inputElement !== null) {
            inputElement.style.visibility = 'hidden';
        }
    };

    private isRequiredApp = (app) => {
        let count = 0;
        this.searchedAssets.forEach((element) => {
            if (element.id === app.assetMetaUId) {
                count = count + 1;
            }
        });
        if (count) {
            return true;
        } else {
            return false;
        }
    };

    public onSearchFocus = () => {
        this.isFilterVisible = true;
        const searchInput = document.querySelector('.processAppsSearchInput') as HTMLInputElement;
        const inputKeyFocus = document.querySelector('.tagKeyFocus') as HTMLInputElement;
        const matSelectPanel = document.querySelector('.mat-autocomplete-panel') as HTMLInputElement;
        searchInput?.blur();
        if (this.totalKeys && this.totalKeys.length === 0) {
            this.getAllKeys();
        } else {
            if (this.tagKey) {
                inputKeyFocus?.blur();
            } else {
                setTimeout(() => {
                    inputKeyFocus?.focus();
                }, 1000);
            }
            setTimeout(() => {
                if (matSelectPanel) {
                    matSelectPanel.style.visibility = 'visible';
                }
            }, 1000);
        }
    };

    public closeFilterPopup = () => {
        this.isFilterVisible = false;
        const inputElement = document.querySelector('.mat-autocomplete-panel') as HTMLInputElement;
        if (inputElement !== null) {
            inputElement.style.visibility = 'hidden';
        }
    };

    private getAllKeys = () => {
        const inputFocus = document.querySelector('.tagKeyFocus') as HTMLInputElement;
        const data = {
            metadataTypes: 'ASSET,BRIDGEASSET,RECON',
        };
        this._assets.getAllkeys(data).then((response) => {
            if (response && response.length > 0) {
                response.forEach((element) => {
                    const obj = {};
                    obj['value'] = element;
                    obj['displayName'] = element;
                    this.totalKeys.push(obj);
                });
                this.keys.next(this.totalKeys);
                if (this.keys.value) {
                    setTimeout(() => {
                        inputFocus?.focus();
                    }, 1000);
                }
            } else {
                this.keys.next([]);
            }
        });
    };

    public getTagValuesByKey = () => {
        if (this.tagKey && this.tagKey.length > 0) {
            this.tagValue = '';
            this.selectedAsset = '';
            const data = {
                key: this.tagKey,
                metadataTypes: 'ASSET,BRIDGEASSET',
            };
            this._assets.getTagValuesByKey(data).then((response) => {
                this.keyValues = [];
                response?.forEach((element) => {
                    const obj = {};
                    obj['value'] = element;
                    obj['displayName'] = element;
                    this.keyValues.push(obj);
                });
                this.values.next(this.keyValues);
            });
        }
    };

    public searchServices = () => {
        this.searchedAssets = [];
        this.selectedAsset = '';
        const keyValuepair = this.tagKey + ':' + this.tagValue;
        const data = {
            key: keyValuepair,
            serviceTypes: ['ASSET', 'BRIDGEASSET'],
            subscriptionStatus: 'APPROVED',
        };
        this._assets.getServicesByTagValues(data, {
            successCallback: (res) => {
                if (res?.length > 0) {
                    res.forEach((app) => {
                        const obj = {};
                        const displayName = this.getDisplayName(app);
                        obj['value'] = displayName || app.metadataName;
                        obj['displayName'] = displayName || app.metadataName;
                        obj['id'] = app.metadataId;
                        this.searchedAssets.push(obj);
                    });
                    this.assets.next(this.searchedAssets);
                } else {
                    this.assets.next([]);
                }
            },
        });
    };

    private getDisplayName = (app) => {
        let displayName;
        this.apps.forEach((element) => {
            if (element.assetMetaUId === app.metadataId) {
                displayName = element.displayName || element.name;
            }
        });
        return displayName;
    };

    public setRoute = (route: string) => {
        this._utils.navigateToOrganization(undefined, route as any);
    };

    ngOnInit() {
        this.uiList = [
            {
                displayName: 'GST Filing',
                show: !environment?.restrictedProcessingUIs?.includes('gst-filing'),
                routeLink: 'gst-filing',
                beta: true,
            },
            {
                displayName: 'enComply',
                show: !environment?.restrictedProcessingUIs?.includes('encomply') && !this.inEncomply,
                routeLink: 'encomply',
            },
            {
                displayName: 'enInvoice',
                show: !environment?.restrictedProcessingUIs?.includes('eninvoice'),
                routeLink: 'eninvoice',
            },
            {
                displayName: 'enReport',
                show: !environment?.restrictedProcessingUIs?.includes('enreport') && !this.inEnreport,
                routeLink: 'enreport',
            },
            {
                displayName: 'enReconcile',
                show: !environment?.restrictedProcessingUIs?.includes('enreconcile'),
                routeLink: 'enreconcile',
            },
            {
                displayName: 'custom',
                show: !environment?.restrictedProcessingUIs?.includes('custom'),
                routeLink: 'custom',
            },
        ];
    }

    ngAfterViewInit() {
        this.subscribedApps.pipe(delay(0), takeUntil(this.unSubscribe)).subscribe((apps) => {
            if (apps === undefined) {
                return;
            }
            this.apps = CommonUtilsService.cloneObject(apps) || [];
            this.searchText = '';
            this.appliedSearchText = undefined;
            this.noApps = apps?.length < 1;
            this.clearSearch(true);
        });
        this.store$
            .select(SetCurrentOrganization)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe(() => {
                this.recentServicesLoading = false;
                this.loading = false;
            });
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
