import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
    AppIntegrationState,
    IntegrationOrganizationsState,
    IntegrationsState,
    IntergrationsConfigState,
} from '../interface/integration-state.interface';
import { INTEGRATIONS_REDUCER_KEY } from '../reducers';
import { getSelectedTableId$ } from './master-management.selector';

const currentState = createFeatureSelector<IntegrationsState>(INTEGRATIONS_REDUCER_KEY);

export const getIntegrationActiveTab$ = createSelector(currentState, (state) => state.activeTab);

export const getIntegrationOrganization$ = createSelector(currentState, (state) => state.currentOrganizationId);

export const getIntegrationsOrganizationEntity$ = createSelector(currentState, (state) => {
    return state.organizations.entities?.[state.currentOrganizationId];
});

export const getIntegrationConfigEntity$ = createSelector(currentState, (state) => {
    const organization: IntegrationOrganizationsState = state.organizations.entities[state.currentOrganizationId];
    return organization?.configs?.entities?.[state.activeTab];
});

export const getAppIntegrationConfigs$ = createSelector(currentState, (state) => state.appIntegrationConfigs);

export const getIntegrationsSupportedTypes$ = createSelector(currentState, (state) => state.integrationsSupprtedTypes);

export const getIntegrationConfigurations$ = createSelector(currentState, (state) => {
    const organization: IntegrationOrganizationsState = state.organizations.entities[state.currentOrganizationId];
    const config: IntergrationsConfigState = organization?.configs?.entities?.[state.activeTab];
    const integrationsEntity = config?.integrations;
    return (integrationsEntity?.ids as string[])?.reduce((integrations, id) => {
        integrations.push(integrationsEntity?.entities[id]);
        return integrations;
    }, []);
});

export const getAppIntegration$ = createSelector(currentState, getSelectedTableId$, (state, serviceId) => {
    const organization: IntegrationOrganizationsState = state.organizations.entities[state.currentOrganizationId];
    const appIntegration: AppIntegrationState = organization?.appIntegrations?.entities[serviceId];
    const appIntegrationsEntity = appIntegration?.integrationDetails;
    return (appIntegrationsEntity?.ids as string[])?.reduce((appIntegrations, id) => {
        appIntegrations.push(appIntegrationsEntity?.entities[id]);
        return appIntegrations;
    }, []);
});

export const getDynamicParamsKeys$ = createSelector(currentState, (state) => state.dynamicParamsKeys);
