import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger, MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { SharedState } from 'src/app/modules/taxilla-library/lib/store/states';
import { LocationService } from 'src/app/services/location.service';
import {
    CommonUtilsService,
    getCurrentOrganizationId$,
    getLeftNavMap$,
    getLeftNavSearchText$,
    getLogo$,
    SetSearchedValue,
    SetSelectedCategory,
} from 'taxilla-library';

@Component({
    selector: 'app-left-nav-bar',
    templateUrl: './left-nav-bar.component.html',
    styleUrls: ['./left-nav-bar.component.scss'],
})
export class LeftNavBarComponent implements OnInit, OnDestroy {
    activeTab: string = 'transactions';
    unsubscribe = new Subject<void>();
    public selectedLeftNav: SharedState['leftNavMap'][0];
    public leftNavMap: SharedState['leftNavMap'];
    public currentOrganizationId: string;
    public locationUrl: string;
    public imgSrc: string;
    private unSubscribe = new Subject<void>();
    public packages = [];
    public groups = [];
    public apps = [];
    public filterAppName: string = '';
    public isInEnComply: boolean;
    public selectedTab: string;
    public isBridgeDialogOpen: boolean = false;

    @ViewChild('matAppsMenuTrigger') matAppsMenuTrigger: MatMenuTrigger;

    constructor(
        private _router: Router,
        private _broadcaster: BroadcasterService,
        private _commonUtils: CommonUtilsService,
        private locationService: LocationService,
        private store$: Store
    ) {}

    public navigate = (tab: string) => {
        this.store$.dispatch(SetSearchedValue({ searchText: '' }));
        const url = '/organizations/' + this.currentOrganizationId + '/category/' + tab;
        this._router.navigate([url]);
    };

    public handleRouterEvents(): void {
        this._router.events?.pipe(takeUntil(this.unsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.locationUrl = location.pathname;
                this.handleNavigationEvent();
            }
        });
    }

    public handleNavigationEvent(): void {
        const url = this.locationUrl;
        const basePath = '/organizations/' + this.currentOrganizationId + '/category/';
        if (url === basePath + 'data-preparation') {
            this.activeTab = 'data-preparation';
        } else if (url === basePath + 'transactions') {
            this.activeTab = 'transactions';
        } else if (url === basePath + 'closures') {
            this.activeTab = 'closures';
        } else if (url === basePath + 'returns') {
            this.activeTab = 'returns';
        } else if (url === basePath + 'utilities') {
            this.activeTab = 'utilities';
        } else {
            this.activeTab = this._commonUtils.getFromStorage('active-tab') || 'utilities';
        }
        this._commonUtils.setInStorage('active-tab', this.activeTab);
        this.store$.dispatch(SetSelectedCategory({ selectedCategory: this.activeTab }));
    }

    public getApps = (leftNav) => {
        this.filterAppName = '';
        this.isBridgeDialogOpen = false;
        this.selectedTab = leftNav.id;
        this.store$.dispatch(SetSearchedValue({ searchText: '' }));
    };

    public analyzeRouteContext = () => {
        this.isInEnComply =
            !this.checkUI('enreport-ui') &&
            !this.checkUI('eninvoice-ui') &&
            !this.checkUI('encollab-ui') &&
            !this.checkUI('enreconcile-ui') &&
            !this.checkUI('enreport-ui') &&
            !this.checkUI('gst-filing') &&
            !this.checkUI('custom');
    };

    public checkUI = (ui) => {
        return this.locationService.href.indexOf(environment[ui]) > -1;
    };

    public closeMatmenu = (matAppsMenuTrigger: MatLegacyMenuTrigger) => {
        matAppsMenuTrigger?.closeMenu();
        this.activeTab = this.selectedTab;
        this._commonUtils.setInStorage('active-tab', this.activeTab);
        this.store$.dispatch(SetSelectedCategory({ selectedCategory: this.activeTab }));
        this.selectedTab = '';
    };

    public removeSelectedTab = () => {
        if (!this.isBridgeDialogOpen) {
            this.selectedTab = '';
        }
    };

    ngOnInit(): void {
        this.analyzeRouteContext();
        this.locationUrl = location.pathname;
        this.handleRouterEvents();
        this.store$
            .select(getCurrentOrganizationId$)
            ?.pipe(takeUntil(this.unsubscribe))
            .subscribe((event) => {
                this.currentOrganizationId = event;
            });
        this.store$
            .select(getLogo$)
            ?.pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.imgSrc = data;
            });
        this.store$
            .select(getLeftNavMap$)
            ?.pipe(takeUntil(this.unsubscribe))
            .subscribe((res) => {
                const clone = CommonUtilsService.cloneObject(res);
                this.leftNavMap = clone;
            });
        this._router.events?.pipe(takeUntil(this.unSubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd || event instanceof NavigationSkipped) {
                this.analyzeRouteContext();
                this.matAppsMenuTrigger?.closeMenu();
                this.selectedTab = '';
            }
        });
        this._broadcaster
            .on('switchedOrganization')
            ?.pipe(takeUntil(this.unSubscribe), delay(10))
            .subscribe(() => {
                this.analyzeRouteContext();
            });
        this.store$
            .select(getLeftNavSearchText$)
            ?.pipe(takeUntil(this.unSubscribe))
            .subscribe((searchText) => {
                this.filterAppName = searchText;
            });
    }

    ngOnDestroy(): void {
        this.matAppsMenuTrigger?.closeMenu();
        this._commonUtils.removeFromStorage('active-tab');
        this.unsubscribe?.next();
        this.unsubscribe?.complete();
    }
}
