import {
    IntegrationConfigField,
    IntegrationModal,
    IntegrationsSubSection,
    IntegrationSubSectionRecord,
    IntegrationTabStructure,
    IntergrationFieldType,
} from '../../interface/integrationmodal.interface';
import { MastersTableMetaDataInterface } from '../../interface/mastertablesmetadata.interface';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { AssetService } from '../assetservice.class';
import { BridgeNode } from '../bridgeNode.interface';
import { AppIntegrationRecord } from './appintegrationrecord.class';

export class AppNewIntegrationRecordPayload {
    chainName: string;

    configName: string;

    configs: {
        configuredData: string;
        resourceName: string;
        integrationType: string;
        locationId: string;
        schedulerExpression: string;
        serviceId: string;
        transformationName: string;
        id: string;
        chainName: string;
        configName: string;
        createdBy: string;
        createdOn: string;
        integrationMethod: string;
        async: boolean;
        internal: boolean;
        internalConfig: string;
        internalRetryCount: number;
        internalSchedulerInterval: number;
    }[];

    integrationType: string;

    locationId: string;

    schedulerExpression: string;

    serviceId: string;

    id: string;

    transformationName: string;

    constructor(
        record: AppIntegrationRecord,
        currentTab: IntegrationModal,
        service: AssetService,
        subApp: BridgeNode,
        master: MastersTableMetaDataInterface
    ) {
        const setFieldValue = (
            payloadObject: any,
            fieldId: string,
            value: any,
            encodingType: string,
            valueAsArrayInPayload: boolean,
            isInConfiguredData: boolean
        ) => {
            value = typeof value === 'string' ? value.trim() : value;
            if (encodingType) {
                switch (encodingType) {
                    case 'btoa':
                        payloadObject[fieldId] = window.btoa(value);
                        break;
                    default:
                        payloadObject[fieldId] = value;
                        break;
                }
            } else {
                value = valueAsArrayInPayload ? (!Array.isArray(value) ? [value] : value) : value;
                if (isInConfiguredData) {
                    payloadObject['configuredData'] = payloadObject['configuredData'] || {};
                    payloadObject['configuredData'][fieldId] = value;
                } else {
                    payloadObject[fieldId] = value;
                }
            }
        };

        const getFieldType = (
            field: IntegrationModal['fields'][0],
            tabRecord?: IntegrationTabStructure['records'][0]
        ): IntegrationConfigField['type'] => {
            let fieldType: IntegrationConfigField['type'] = 'TEXT';
            if (field.inputTypeConditonal && Array.isArray(field.type)) {
                for (let i = 0; i < field.type.length; i++) {
                    const fieldTypeObj = (field.type as IntergrationFieldType[])[i];
                    let requiredField;
                    if (tabRecord) {
                        requiredField = tabRecord.fields.find((recordField) => recordField.id === fieldTypeObj.fieldId);
                        if (!requiredField) {
                            requiredField = tabRecord.recordActions.find((recordAction) => recordAction.id === fieldTypeObj.fieldId);
                        }
                    } else {
                        requiredField = record.getRecordField(fieldTypeObj.fieldId);
                    }
                    if (fieldTypeObj.requiredValue !== undefined) {
                        if (fieldTypeObj.requiredValue === requiredField?.value) {
                            fieldType = fieldTypeObj.type;
                        }
                    }
                }
            } else {
                fieldType = field.type;
            }
            if (field.array && (!field.values || field.values.length === 0)) {
                field.values = [{ value: '' }];
            }
            return fieldType;
        };

        const getDefaultValue = (
            field: IntegrationConfigField,
            tabRecord: IntegrationTabStructure['records'][0]
        ): IntegrationConfigField['type'] => {
            let value: any;
            const fieldType = getFieldType(field, tabRecord);
            switch (fieldType) {
                case 'TEXT':
                    value = '';
                    break;
                case 'DROPDOWN':
                    value = '';
                    break;
                case 'CHECKBOX':
                    value = false;
                    break;
                case 'INPUT_RADIO':
                    value = false;
                    break;
                case 'TEXTAREA':
                    value = '';
                    break;
                default:
                    value = '';
                    break;
            }
            return value;
        };

        const setObject = (
            configuredObject: object,
            recordData: AppIntegrationRecord,
            subFields: string[],
            subFieldId: string,
            subFieldValue: any,
            tab: IntegrationTabStructure,
            encodingType: IntegrationConfigField['encodingType'],
            valueAsArrayInPayload: boolean,
            isInConfiguredData: boolean
        ) => {
            const currentFieldId = subFields[0];
            if (subFields.length > 0) {
                const field = recordData.getRecordField(currentFieldId);
                const property =
                    (field && (field.value as string)) || (tab && tab.id && currentFieldId === tab.id ? currentFieldId : undefined);
                if (property) {
                    if (configuredObject[property] === undefined) {
                        configuredObject[property] = {};
                    }
                    const newSubFields = CommonUtilsService.cloneObject(subFields);
                    (newSubFields as string[]).splice(0, 1);
                    setObject(
                        configuredObject[property],
                        recordData,
                        newSubFields,
                        subFieldId,
                        subFieldValue,
                        tab,
                        encodingType,
                        valueAsArrayInPayload,
                        isInConfiguredData
                    );
                }
            } else if (subFields.length === 0) {
                if (tab) {
                    if (tab.array) {
                        configuredObject[subFieldId] = [];
                        setTabRecord(configuredObject[subFieldId], tab);
                    } else {
                        setFieldValue(configuredObject, subFieldId, subFieldValue, encodingType, valueAsArrayInPayload, isInConfiguredData);
                    }
                } else {
                    setFieldValue(configuredObject, subFieldId, subFieldValue, encodingType, valueAsArrayInPayload, isInConfiguredData);
                }
            }
        };

        const setSubSectionValues = (
            subSectionPayload: { [property: string]: any },
            subSections: IntegrationsSubSection[],
            subSectionRecord: IntegrationSubSectionRecord
        ) => {
            subSections
                ?.filter((subSection) => !record.canHideSubSection(subSection, subSectionRecord))
                .forEach((subSection) => {
                    if (subSection.haveTabs) {
                        subSection.tabs?.forEach((tab) => {
                            if (tab.array) {
                                if (subSection.isInSubFieldObjectOnly) {
                                    setObject(
                                        subSectionPayload,
                                        record,
                                        subSection.subFieldObject.split('.'),
                                        tab.id,
                                        undefined,
                                        tab,
                                        undefined,
                                        false,
                                        tab.isFieldInConfiguredData
                                    );
                                } else {
                                    // tslint:disable-next-line: no-use-before-declare
                                    if (tab.isFieldInConfiguredData) {
                                        subSectionPayload.configuredData = subSectionPayload.configuredData || {};
                                        subSectionPayload.configuredData[tab.id] = [];
                                        let tabPayload;
                                        if (subSection.tabsInProperty) {
                                            subSectionPayload.configuredData[tab.id] = {};
                                            subSectionPayload.configuredData[tab.id][subSection.tabsInProperty] = [];
                                            tabPayload = subSectionPayload.configuredData[tab.id][subSection.tabsInProperty];
                                        } else {
                                            subSection.id && (subSectionPayload[subSection.id] = []);
                                            tabPayload = subSectionPayload.configuredData[tab.id];
                                        }
                                        setTabRecord(tabPayload, tab);
                                    } else {
                                        subSectionPayload[tab.id] = [];
                                        setTabRecord(subSectionPayload[tab.id], tab);
                                    }
                                }
                            } else {
                                let tabPayload;
                                if (tab.isFieldInConfiguredData) {
                                    subSectionPayload.configuredData = subSectionPayload.configuredData || {};
                                    subSectionPayload.configuredData[tab.id] = subSectionPayload.configuredData[tab.id] || {};
                                    if (tab.noSubObjectForFields) {
                                        tabPayload = subSectionPayload.configuredData;
                                    } else {
                                        tabPayload = subSectionPayload.configuredData[tab.id];
                                    }
                                } else {
                                    subSectionPayload[tab.id] = subSectionPayload[tab.id] || {};
                                    tabPayload = subSectionPayload[tab.id];
                                }
                                tab.fields.forEach((field) => {
                                    if (field.copyToPayload === false) {
                                        return;
                                    }
                                    if (field.value !== undefined) {
                                        if (subSection.isInSubFieldObjectOnly) {
                                            const subFieldIds = subSection.subFieldObject.split('.') || [];
                                            subFieldIds.push(tab.id);
                                            setObject(
                                                tabPayload,
                                                record,
                                                subFieldIds,
                                                field.id,
                                                field.value,
                                                tab,
                                                field.encodingType,
                                                field.valueAsArrayInPayload,
                                                field.isFieldInConfiguredData
                                            );
                                        } else {
                                            setFieldValue(
                                                tabPayload,
                                                field.id,
                                                field.value,
                                                field.encodingType,
                                                field.valueAsArrayInPayload,
                                                field.isFieldInConfiguredData
                                            );
                                        }
                                    }
                                });
                            }
                        });
                    } else if (subSection.array) {
                        subSectionPayload[subSection.id] = [];
                        // tslint:disable-next-line: no-use-before-declare
                        setTabRecord(subSectionPayload[subSection.id], undefined, subSection.records);
                    } else if (subSection.appendType) {
                        let value: any[];
                        switch (subSection.appendType) {
                            case 'cronExpression':
                                const field = record.getRecordField('schedulerExpression');
                                value = [`0 ${field.value} *`];
                                // value = [0];
                                // const frequencyValue = record.getRecordField('frequency').value;
                                // if (!frequencyValue) {
                                //     break;
                                // }
                                // for (let i = subSection.fields.length - 1; i >= 0; i--) {
                                //     if (subSection.fields[i].value !== undefined) {
                                //         if (record.canHideField(subSection.fields[i])) {
                                //             if (subSection.fields[i].id === 'minute') {
                                //                 if (
                                //                     frequencyValue !== 'month' &&
                                //                     frequencyValue !== 'hour' &&
                                //                     (frequencyValue as string).indexOf('h') > -1
                                //                 ) {
                                //                     value[1] = '0';
                                //                 } else {
                                //                     value[1] = '*';
                                //                 }
                                //             } else if (subSection.fields[i].id === 'hour') {
                                //                 value[2] = '*';
                                //             } else if (subSection.fields[i].id === 'date') {
                                //                 if (
                                //                     frequencyValue === 'week' ||
                                //                     ['20m', '25m', '30m', '35m', '40m', '45m', '50m', '55m'].indexOf(frequencyValue) > -1 ||
                                //                     frequencyValue.endsWith('m') ||
                                //                     ['hour', '2h', '3h', '4h', '6h', '12h'].indexOf(frequencyValue) > -1 ||
                                //                     frequencyValue.endsWith('h')
                                //                 ) {
                                //                     value[3] = '?';
                                //                 } else {
                                //                     value[3] = '*';
                                //                 }
                                //             } else if (subSection.fields[i].id === 'month') {
                                //                 value[4] = '*';
                                //             } else if (subSection.fields[i].id === 'frequency') {
                                //                 value[5] = '*';
                                //             }
                                //         } else {
                                //             if (subSection.fields[i].id === 'minute') {
                                //                 value[1] = subSection.fields[i].value;
                                //             } else if (subSection.fields[i].id === 'hour') {
                                //                 value[2] = subSection.fields[i].value;
                                //             } else if (subSection.fields[i].id === 'date') {
                                //                 if (
                                //                     frequencyValue === 'week' ||
                                //                     ['20m', '25m', '30m', '35m', '40m', '45m', '50m', '55m'].indexOf(frequencyValue) > -1 ||
                                //                     frequencyValue.endsWith('m') ||
                                //                     ['hour', '2h', '3h', '4h', '6h', '12h'].indexOf(frequencyValue) > -1 ||
                                //                     frequencyValue.endsWith('h')
                                //                 ) {
                                //                     value[3] = '?';
                                //                 } else {
                                //                     value[3] = subSection.fields[i].value;
                                //                 }
                                //             } else if (subSection.fields[i].id === 'month') {
                                //                 value[4] = subSection.fields[i].value;
                                //             } else if (subSection.fields[i].id === 'frequency') {
                                //                 if (value[5] === undefined) {
                                //                     let str = '';
                                //                     const optionToBePushed = subSection.fields[i].options.find(
                                //                         (option) => option.value === subSection.fields[i].value
                                //                     );
                                //                     value[5] = optionToBePushed && optionToBePushed.pushValue;
                                //                     const subValue = subSection.fields[i].value;
                                //                     if (
                                //                         subValue !== 'month' &&
                                //                         subValue !== 'hour' &&
                                //                         (subValue as string).indexOf('h') > -1
                                //                     ) {
                                //                         str += '*/';
                                //                         str += (subValue as string).substring(0, (subValue as string).length - 1);
                                //                         value[2] = str;
                                //                     } else if (subValue !== 'month' && (subValue as string).indexOf('m') > -1) {
                                //                         str += '*/';
                                //                         str += (subValue as string).substring(0, (subValue as string).length - 1);
                                //                         value[1] = str;
                                //                     }
                                //                 }
                                //             } else if (subSection.fields[i].id === 'day') {
                                //                 value[5] = subSection.fields[i].value;
                                //             }
                                //         }
                                //     } else {
                                //         if (subSection.fields[i].id === 'minute') {
                                //             if (
                                //                 frequencyValue !== 'month' &&
                                //                 frequencyValue !== 'hour' &&
                                //                 (frequencyValue as string).indexOf('h') > -1
                                //             ) {
                                //                 value[1] = '0';
                                //             } else {
                                //                 value[1] = '*';
                                //             }
                                //         } else if (subSection.fields[i].id === 'hour') {
                                //             value[2] = '*';
                                //         } else if (subSection.fields[i].id === 'date') {
                                //             if (
                                //                 frequencyValue === 'week' ||
                                //                 ['20m', '25m', '30m', '35m', '40m', '45m', '50m', '55m'].indexOf(frequencyValue) > -1 ||
                                //                 frequencyValue.endsWith('m') ||
                                //                 ['hour', '2h', '3h', '4h', '6h', '12h'].indexOf(frequencyValue) > -1 ||
                                //                 frequencyValue.endsWith('h')
                                //             ) {
                                //                 value[3] = '?';
                                //             } else {
                                //                 value[3] = '*';
                                //             }
                                //         } else if (subSection.fields[i].id === 'month') {
                                //             value[4] = '*';
                                //         } else if (subSection.fields[i].id === 'frequency') {
                                //             value[5] = '*';
                                //         }
                                //     }
                                // }
                                break;
                        }
                        subSectionPayload[subSection.id] = value.join(' ');
                    } else {
                        subSection.fields &&
                            subSection.fields.forEach((field) => {
                                if (field.copyToPayload === false) {
                                    return;
                                }
                                if (field.value !== undefined) {
                                    if (field.isFieldInMainData || field.isFieldInMainDataOnly) {
                                        setFieldValue(
                                            this,
                                            field.fieldIdInMainData || field.id,
                                            field.value,
                                            field.encodingType,
                                            field.valueAsArrayInPayload,
                                            field.isFieldInConfiguredData
                                        );
                                    }
                                    if (field.subFieldObject && field.isFieldInConfiguredDataOnly !== true) {
                                        if (typeof field.subFieldObject === 'string') {
                                            setObject(
                                                subSectionPayload,
                                                record,
                                                field.subFieldObject.split('.'),
                                                field.fieldIdInSubObject || field.id,
                                                field.value,
                                                undefined,
                                                field.encodingType,
                                                field.valueAsArrayInPayload,
                                                field.isFieldInConfiguredData
                                            );
                                        } else if (Array.isArray(field.subFieldObject)) {
                                            field.subFieldObject.forEach((fieldSubObject) => {
                                                setObject(
                                                    subSectionPayload,
                                                    record,
                                                    fieldSubObject.subString.split('.'),
                                                    fieldSubObject.fieldId || field.fieldIdInSubObject || field.id,
                                                    field.value,
                                                    undefined,
                                                    field.encodingType,
                                                    field.valueAsArrayInPayload,
                                                    field.isFieldInConfiguredData
                                                );
                                            });
                                        }
                                    }
                                    if (!field.idFieldInSubObjectOnly && !field.isFieldInMainDataOnly) {
                                        if (field.payloadType === 'ORGANIZATION') {
                                            const values = [];
                                            if (field.array) {
                                                field.value.forEach((value) => {
                                                    const tabRecord = {};
                                                    tabRecord[field.fieldIdInPayloadAs] = value;
                                                    values.push(tabRecord);
                                                });
                                            }
                                            setFieldValue(
                                                subSectionPayload,
                                                field.id,
                                                values,
                                                field.encodingType,
                                                field.valueAsArrayInPayload,
                                                field.isFieldInConfiguredData
                                            );
                                        } else {
                                            setFieldValue(
                                                subSectionPayload,
                                                field.id,
                                                field.value,
                                                field.encodingType,
                                                field.valueAsArrayInPayload,
                                                field.isFieldInConfiguredData
                                            );
                                        }
                                    }
                                }
                            });
                    }
                });
        };
        const checkConfiguredDataObject = (configObject) => {
            for (const key in configObject) {
                if (configObject[key] === undefined) {
                    delete configObject[key];
                } else if (typeof configObject[key] === 'string' && configObject[key] === '') {
                    delete configObject[key];
                } else if (Array.isArray(configObject[key])) {
                    if (configObject[key].length === 0) {
                        delete configObject[key];
                    } else if (key === 'filters') {
                        const transformedFilters = {};
                        configObject[key].forEach((filter) => {
                            transformedFilters[filter.key !== undefined ? filter.key : ''] =
                                filter.values !== undefined ? filter.values : '';
                        });
                        configObject[key] = transformedFilters;
                    }
                } else if (typeof configObject[key] === 'object') {
                    if (Object.keys(configObject[key]).length === 0) {
                        delete configObject[key];
                    } else {
                        for (const secondaryKey in configObject[key]) {
                            if (configObject[key].hasOwnProperty(secondaryKey)) {
                                checkConfiguredDataObject(configObject[key][secondaryKey]);
                            }
                        }
                        if (Object.keys(configObject[key]).length === 0) {
                            checkConfiguredDataObject(configObject);
                        }
                    }
                }
            }
        };
        const setTabRecord = (recordsArray: any[], tab: IntegrationTabStructure, subSectionRecords?: IntegrationsSubSection['records']) => {
            tab?.records.forEach((tabRecord) => {
                const tabRecordPayload = {};
                tabRecord.fields
                    .filter((field) => !record.canHideField(field, tab, tabRecord))
                    .forEach((field) => {
                        if (field.copyToPayload === false) {
                            return;
                        }
                        let value = field.value;
                        if (field.valueSameAsFieldId) {
                            const requiredField = tabRecord.fields.find((tabRecordField) => tabRecordField.id === field.valueSameAsFieldId);
                            value = requiredField.value;
                        }
                        if (field.defaultValueIf && record.canAssignDefaultValue(field, tabRecord)) {
                            value = field.defaultValue;
                        }
                        if (value === null || value === undefined) {
                            value = getDefaultValue(field, tabRecord);
                        }
                        if (field.payloadType === 'ATTRIBUTE') {
                            setFieldValue(
                                tabRecordPayload,
                                field.fieldIdInPayloadAs,
                                field.id,
                                field.encodingType,
                                field.valueAsArrayInPayload,
                                field.isFieldInConfiguredData
                            );
                            setFieldValue(
                                tabRecordPayload,
                                field.fieldValueInPayloadAs,
                                value,
                                field.encodingType,
                                field.valueAsArrayInPayload,
                                field.isFieldInConfiguredData
                            );
                            if (field.copyToPayload) {
                                Object.keys(field.copyToPayload).forEach((property) => {
                                    setFieldValue(
                                        tabRecordPayload,
                                        property,
                                        field.copyToPayload[property],
                                        undefined,
                                        undefined,
                                        field.isFieldInConfiguredData
                                    );
                                });
                            }
                        } else {
                            setFieldValue(
                                tabRecordPayload,
                                field.id,
                                value,
                                field.encodingType,
                                field.valueAsArrayInPayload,
                                field.isFieldInConfiguredData
                            );
                        }
                    });
                tabRecord.recordActions.forEach((field) => {
                    if (field.copyToPayload === false) {
                        return;
                    }
                    setFieldValue(
                        tabRecordPayload,
                        field.id,
                        field.value,
                        field.encodingType,
                        field.valueAsArrayInPayload,
                        field.isFieldInConfiguredData
                    );
                });
                Object.keys(tabRecordPayload).length > 0 && recordsArray.push(tabRecordPayload);
            });
            subSectionRecords?.forEach((subSectionRecord) => {
                const subSectionRecordPayload = {};
                subSectionRecord.fields.forEach((field) => {
                    if (field.copyToPayload === false) {
                        return;
                    }
                    let value = field.value;
                    if (field.valueSameAsFieldId) {
                        let requiredField = subSectionRecord.fields.find(
                            (tabRecordField) => tabRecordField.id === field.valueSameAsFieldId
                        );
                        if (!requiredField) {
                            requiredField = record.getRecordField(field.valueSameAsFieldId);
                        }
                        value = requiredField.value;
                    }
                    if (field.defaultValueIf && record.canAssignDefaultValue(field, subSectionRecord)) {
                        value = field.defaultValue;
                    }
                    if (value === null || value === undefined) {
                        value = getDefaultValue(field, subSectionRecord);
                    }
                    setFieldValue(
                        subSectionRecordPayload,
                        field.id,
                        value,
                        field.encodingType,
                        field.valueAsArrayInPayload,
                        field.isFieldInConfiguredData
                    );
                });
                if (subSectionRecord.childSubSections?.length > 0) {
                    setSubSectionValues(subSectionRecordPayload, subSectionRecord.childSubSections, subSectionRecord);
                }
                if (subSectionRecordPayload['configuredData']) {
                    if (Object.keys(subSectionRecordPayload['configuredData']).length > 0) {
                        const configData = subSectionRecordPayload['configuredData'];
                        checkConfiguredDataObject(configData);
                        subSectionRecordPayload['configuredData'] = JSON.stringify(configData);
                    }
                }
                Object.keys(subSectionRecordPayload).length > 0 && recordsArray.push(subSectionRecordPayload);
            });
        };

        const payload = {};
        record.recordData.fields.forEach((field) => {
            if (field.copyToPayload === false) {
                return;
            }
            if (field.value !== undefined) {
                if (field.isFieldInMainData || field.isFieldInMainDataOnly) {
                    setFieldValue(
                        this,
                        field.fieldIdInMainData || field.id,
                        field.value,
                        field.encodingType,
                        field.valueAsArrayInPayload,
                        field.isFieldInConfiguredData
                    );
                }
                if (field.array || (field.conditionalArray && record.isConditionalArray(field))) {
                    const values = [];
                    field.values.forEach((valueObject) => {
                        if (
                            (typeof (valueObject.value as any) === 'string' && (valueObject.value as any).length > 0) ||
                            typeof (valueObject.value as any) === 'boolean'
                        ) {
                            values.push(valueObject.value);
                        }
                    });
                    field.value = values;
                }
                if (field.isFieldInConfiguredData !== false && field.isFieldInMainDataOnly !== true) {
                    if (field.subFieldObject && field.isFieldInConfiguredDataOnly !== true) {
                        if (typeof field.subFieldObject === 'string') {
                            setObject(
                                payload,
                                record,
                                field.subFieldObject.split('.'),
                                field.fieldIdInSubObject || field.id,
                                field.value,
                                undefined,
                                field.encodingType,
                                field.valueAsArrayInPayload,
                                field.isFieldInConfiguredData
                            );
                        } else if (Array.isArray(field.subFieldObject)) {
                            field.subFieldObject.forEach((fieldSubObject) => {
                                setObject(
                                    payload,
                                    record,
                                    fieldSubObject.subString.split('.'),
                                    fieldSubObject.fieldId || field.fieldIdInSubObject || field.id,
                                    field.value,
                                    undefined,
                                    field.encodingType,
                                    field.valueAsArrayInPayload,
                                    field.isFieldInConfiguredData
                                );
                            });
                        }
                    }
                    if (!field.idFieldInSubObjectOnly) {
                        setFieldValue(
                            payload,
                            field.id,
                            field.value,
                            field.encodingType,
                            field.valueAsArrayInPayload,
                            field.isFieldInConfiguredData
                        );
                    }
                }
            }
        });
        record.recordData.subSections && setSubSectionValues(payload, record.recordData.subSections, undefined);
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                this[key] = payload[key];
            }
        }
        this.integrationType = currentTab.api;
        this.locationId = record.locationId;
        this.id = record?.originalData?.id;
        if (record.recordData.displayName === 'Master Scheduler') {
        } else if (!this['triggerJob']) {
            this['schedulerExpression'] = '';
        }
        delete this['triggerJob'];
        this.serviceId = master
            ? master.itemId
            : subApp?.name
            ? (service?.serviceId && `${service.serviceId}.${subApp.name}`) || ''
            : service?.serviceId;
    }
}
