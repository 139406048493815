import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createEntityAdapter } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AppIntegration } from '../../interface/appintegration.interface';
import { Integration } from '../../interface/integration.interface';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { GetDynamicParamsKeys, SetAuthorizationSystemOptions, SetDynamicParamsKeys } from '../actions';
import * as actions from '../actions/integrations.actions';
import { SetCurrentOrganization } from '../actions/session.actions';
import { AlertError, AlertSuccess } from '../actions/shared.actions';
import { AppIntegrationState, IntegrationOrganizationsState, IntergrationsConfigState } from '../interface/integration-state.interface';
import {
    getAppIntegration$,
    getAppIntegrationConfigs$,
    getAuthorizationSystemOptions$,
    getCurrentOrganizationId$,
    getIntegrationActiveTab$,
    getIntegrationConfigEntity$,
    getIntegrationConfigurations$,
    getIntegrationOrganization$,
    getIntegrationsOrganizationEntity$,
} from '../selectors';
import { integrationAdapters } from '../states';

@Injectable()
export class IntegrationEffects {
    constructor(private actions$: Actions, private _api: ApiService, private store$: Store) {}

    setCurrentOrganization$ = createEffect(() => {
        return this.actions$.pipe(
            delay(10),
            ofType(SetCurrentOrganization),
            withLatestFrom(this.store$.select(getIntegrationsOrganizationEntity$)),
            mergeMap(([action, organizationEntity]) => {
                if (!organizationEntity) {
                    const configsAdapter = createEntityAdapter<IntergrationsConfigState>();
                    const appDetailsAdapter = createEntityAdapter<AppIntegrationState>();
                    const organizationObject: IntegrationOrganizationsState = {
                        id: action.organization.id,
                        configs: configsAdapter.getInitialState(),
                        appIntegrations: appDetailsAdapter.getInitialState(),
                    };
                    integrationAdapters[action.organization.id] = configsAdapter;
                    return of(
                        actions.SetIntegrationOrganizationEntity({
                            organizationEntity: organizationObject,
                            organizationsEntityAdapter: integrationAdapters.organizationsAdapter,
                        })
                    );
                }
                return [];
            })
        );
    });

    getSupportedIntegrationConfigurations$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetIntegrationsConfig),
            withLatestFrom(this.store$.select(getAppIntegrationConfigs$), this.store$.select(getAuthorizationSystemOptions$)),
            mergeMap(([_action, supportedMethods, existingSystems]) => {
                if (supportedMethods !== undefined && (existingSystems?.RESTAPI?.length > 0 || existingSystems?.EMAIL?.length > 0)) {
                    return [];
                }
                return from(this._api.integrations.getIntegrationsConfig()).pipe(
                    map(
                        (res: {
                            supportedMethods: { inbound: string[]; outbound: string[]; masterinbound: string[] };
                            oAuth2CodeFlowSystems: { [property: string]: string };
                            emailConfigs: { [property: string]: string };
                            supportedAuthenticationTypes: { [property: string]: string };
                            supportedGrantTypes: { [property: string]: string };
                        }) => {
                            this.store$.dispatch(
                                SetAuthorizationSystemOptions({
                                    RESTAPI: Object.keys(res?.oAuth2CodeFlowSystems).reduce((options, key) => {
                                        options.push({
                                            id: key,
                                            displayName: res?.oAuth2CodeFlowSystems[key],
                                        });
                                        return options;
                                    }, []),
                                    EMAIL: Object.keys(res?.emailConfigs).reduce((options, key) => {
                                        options.push({
                                            id: key,
                                            displayName: res?.emailConfigs[key],
                                        });
                                        return options;
                                    }, []),
                                })
                            );

                            this.store$.dispatch(
                                actions.SetIntegrationsSupportedTypes({
                                    supportedAuthenticationTypes: Object.keys(res?.supportedAuthenticationTypes).reduce((options, key) => {
                                        options.push({
                                            id: key,
                                            displayName: res?.supportedAuthenticationTypes[key],
                                            value: key,
                                        });
                                        return options;
                                    }, []),
                                    supportedGrantTypes: Object.keys(res?.supportedGrantTypes).reduce((options, key) => {
                                        options.push({
                                            id: key,
                                            displayName: res?.supportedGrantTypes[key],
                                            value: key,
                                        });
                                        return options;
                                    }, []),
                                })
                            );
                            const formatMethods = (methods: string[]) =>
                                methods.map((method) => ({
                                    id: method.toLowerCase(),
                                    displayName: method,
                                    value: method,
                                })) || [];
                            return actions.SetAppIntegrationConfigs({
                                appIntegrationConfigs: {
                                    inbound: formatMethods(res?.supportedMethods?.inbound || []),
                                    outbound: formatMethods(res?.supportedMethods?.outbound || []),
                                    masterinbound: formatMethods(res?.supportedMethods?.masterinbound || []),
                                },
                            });
                        }
                    ),
                    catchError((e) => of(AlertError({ message: e.msg })))
                );
            })
        );
    });

    setActiveIntegrationTab$ = createEffect(() => {
        return this.actions$.pipe(
            delay(10),
            ofType(actions.SetActiveIntegrationTab),
            withLatestFrom(this.store$.select(getIntegrationsOrganizationEntity$), this.store$.select(getIntegrationConfigEntity$)),
            mergeMap(([{ tab }, organizationEntity, configsEntity]) => {
                if (!configsEntity) {
                    const integrationsAdapter = createEntityAdapter<Integration>();
                    integrationAdapters[`${organizationEntity?.id}|${tab}`] = integrationsAdapter;
                    const configEntity = {
                        id: tab,
                        integrations: integrationsAdapter.getInitialState(),
                    };
                    return of(
                        actions.SetIntegrationConfigEntity({
                            organizationEntity: organizationEntity,
                            organizationAdapter: integrationAdapters.organizationsAdapter,
                            configsEntity: configEntity,
                            configsEntityAdapter: integrationAdapters[organizationEntity.id],
                        })
                    );
                }
                return [];
            })
        );
    });

    getIntegrationRecords$ = createEffect(() => {
        return this.actions$.pipe(
            delay(10),
            ofType(actions.GetIntegrationRecords),
            withLatestFrom(
                this.store$.select(getIntegrationActiveTab$),
                this.store$.select(getIntegrationOrganization$),
                this.store$.select(getIntegrationConfigurations$)
            ),
            mergeMap(([action, config, currentOrganizationId, integrations]) => {
                if (integrations?.length > 0) {
                    return [];
                }
                return from(
                    this._api.integrations.getIntegrationConfigurations({
                        config: action.config || config,
                        dontFetchUsingMasters: action.dontFetchUsingMasters,
                        isMaster: action.isMaster,
                    })
                ).pipe(
                    map((response) =>
                        actions.SetIntegrationRecords({
                            organizationAdapter: integrationAdapters.organizationsAdapter,
                            configsAdapter: integrationAdapters[currentOrganizationId],
                            integrationsAdapter: integrationAdapters[`${currentOrganizationId}|${config}`],
                            records: response,
                        })
                    ),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    testIntegrationConfiguration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.TestIntegrationConfiguration),
            mergeMap((action) => {
                return from(this._api.integrations.testConnection(action.payload)).pipe(
                    map((response) => {
                        if (response?.connected) {
                            return AlertSuccess({
                                message: response?.msg || translate('Successfully tested the connection'),
                            });
                        } else {
                            return AlertError({
                                message: response?.msg || translate('Testing connection failed'),
                            });
                        }
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    saveIntegrationConfiguration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SaveIntegrationConfiguration),
            withLatestFrom(this.store$.select(getIntegrationOrganization$), this.store$.select(getIntegrationActiveTab$)),
            mergeMap(([action, organizationId, config]) => {
                return from(this._api.integrations.saveIntegrationRecord(action.payload)).pipe(
                    map((response) => {
                        this.store$.dispatch(
                            AlertSuccess({
                                message: translate('Configuration saved successfully'),
                            })
                        );
                        if (!action.payload.id) {
                            return actions.PushIntegrationConfiguration({
                                organizationAdapter: integrationAdapters.organizationsAdapter,
                                configsAdapter: integrationAdapters[organizationId],
                                integrationsAdapter: integrationAdapters[`${organizationId}|${config}`],
                                record: response,
                            });
                        }
                        return actions.UpdateIntegrationConfigurationChange({
                            organizationAdapter: integrationAdapters.organizationsAdapter,
                            configsAdapter: integrationAdapters[organizationId],
                            integrationsAdapter: integrationAdapters[`${organizationId}|${config}`],
                            record: response,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    validateIntegrationConfiguration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ValidateIntegrationConfiguration),
            withLatestFrom(this.store$.select(getIntegrationOrganization$), this.store$.select(getIntegrationActiveTab$)),
            mergeMap(([action, organizationId, config]) => {
                return from(this._api.integrations.validateIntegrationRecord(action.payload)).pipe(
                    map((response: any) => {
                        if (response?.config) {
                            if (!action.payload.id) {
                                this.store$.dispatch(
                                    actions.PushIntegrationConfiguration({
                                        organizationAdapter: integrationAdapters.organizationsAdapter,
                                        configsAdapter: integrationAdapters[organizationId],
                                        integrationsAdapter: integrationAdapters[`${organizationId}|${config}`],
                                        record: response.config,
                                    })
                                );
                            }
                            this.store$.dispatch(
                                actions.UpdateIntegrationConfigurationChange({
                                    organizationAdapter: integrationAdapters.organizationsAdapter,
                                    configsAdapter: integrationAdapters[organizationId],
                                    integrationsAdapter: integrationAdapters[`${organizationId}|${config}`],
                                    record: response.config,
                                })
                            );
                        }
                        if (response?.authorizationURI?.length > 0) {
                            return actions.ValidateIntegrationConfigurationSuccess({ redirectURL: response.authorizationURI });
                        }
                        return AlertSuccess({
                            message: translate(`Authentication successful.`),
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    toggleIntegrationConfiguration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.ToggleIntegrationConfiguration),
            withLatestFrom(this.store$.select(getIntegrationOrganization$), this.store$.select(getIntegrationActiveTab$)),
            mergeMap(([action, organizationId, config]) => {
                return from(this._api.integrations.saveIntegrationRecord(action.payload)).pipe(
                    map((response) => {
                        this.store$.dispatch(
                            AlertSuccess({
                                message: translate(`configuration ${action.payload.active ? 'activated' : 'deactivated'}`),
                            })
                        );
                        return actions.UpdateIntegrationConfigurationChange({
                            organizationAdapter: integrationAdapters.organizationsAdapter,
                            configsAdapter: integrationAdapters[organizationId],
                            integrationsAdapter: integrationAdapters[`${organizationId}|${config}`],
                            record: response,
                        });
                    }),
                    catchError((errorResponse) => of(AlertError({ message: errorResponse })))
                );
            })
        );
    });

    deleteIntegrationConfiguration$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.DeleteIntegrationConfiguration),
            withLatestFrom(this.store$.select(getIntegrationOrganization$), this.store$.select(getIntegrationActiveTab$)),
            mergeMap(([action, organizationId, config]) => {
                return this._api.integrations
                    .deleteIntegrationRecord({
                        name: action.record.name,
                        organizationId: action.record.organizationId || organizationId,
                        type: action.record.type.toLowerCase(),
                    })
                    .pipe(
                        map((response) => {
                            this.store$.dispatch(
                                AlertSuccess({ message: translate(response?.msg || 'Successfully deleted the integration record') })
                            );
                            return actions.RemoveIntegrationConfiguration({
                                organizationAdapter: integrationAdapters.organizationsAdapter,
                                configsAdapter: integrationAdapters[organizationId],
                                integrationsAdapter: integrationAdapters[`${organizationId}|${config}`],
                                record: action.record,
                            });
                        }),
                        catchError((errorResponse) =>
                            of(
                                AlertError({
                                    message: errorResponse || translate('Unable to delete integration record'),
                                })
                            )
                        )
                    );
            })
        );
    });

    getAppIntegrationDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.GetAppIntegration),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getAppIntegration$)),
            mergeMap(([action, currentOrganizationId, appIntegration]) => {
                const appIntegrationAdapter = integrationAdapters[currentOrganizationId];
                const integrationsAdapter =
                    integrationAdapters[`${currentOrganizationId}|${appIntegration}`] ||
                    createEntityAdapter<AppIntegration>({
                        selectId: (a: AppIntegration) => {
                            return a.configName;
                        },
                    });
                if (!integrationAdapters[`${currentOrganizationId}|${appIntegration}`]) {
                    integrationAdapters[`${currentOrganizationId}|${appIntegration}`] = integrationsAdapter;
                }
                if (appIntegration?.length > 0) {
                    return of(
                        actions.SetAppIntegration({
                            organizationAdapter: integrationAdapters.organizationsAdapter,
                            appIntegrationAdapter,
                            integrationsAdapter,
                            serviceId: action?.serviceId,
                            appIntegrationDetails: CommonUtilsService.cloneObject(appIntegration),
                        })
                    );
                }
                return this._api.integrations
                    .getAppIntegrationsObservable({
                        integrationType: action?.integrationType,
                        serviceId: action?.serviceId,
                    })
                    .pipe(
                        map((res: AppIntegration) => {
                            return actions.SetAppIntegration({
                                organizationAdapter: integrationAdapters.organizationsAdapter,
                                appIntegrationAdapter,
                                integrationsAdapter,
                                serviceId: action?.serviceId,
                                appIntegrationDetails: res,
                            });
                        }),
                        catchError((e) => of(AlertError({ message: e })))
                    );
            })
        );
    });

    deleteAppIntegrationDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.DeleteAppIntegrationDetails),
            withLatestFrom(this.store$.select(getCurrentOrganizationId$), this.store$.select(getAppIntegration$)),
            mergeMap(([action, currentOrganizationId, appIntegration]) => {
                const appIntegrationAdapter = integrationAdapters[currentOrganizationId];
                const integrationsAdapter =
                    integrationAdapters[`${currentOrganizationId}|${appIntegration}`] ||
                    createEntityAdapter<AppIntegration>({
                        selectId: (a: AppIntegration) => {
                            return a.configName;
                        },
                    });
                if (!integrationAdapters[`${currentOrganizationId}|${appIntegration}`]) {
                    integrationAdapters[`${currentOrganizationId}|${appIntegration}`] = integrationsAdapter;
                }
                return of(
                    actions.DeleteAppIntegrationDetailsSuccess({
                        organizationAdapter: integrationAdapters.organizationsAdapter,
                        appIntegrationAdapter,
                        integrationsAdapter,
                        configName: action?.configName,
                        serviceId: action?.serviceId,
                    })
                );
            })
        );
    });

    public getDynamicParamsKeys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDynamicParamsKeys),
            mergeMap(() =>
                this._api.assets.getDynamicParamsKeys().pipe(
                    map((res) =>
                        SetDynamicParamsKeys({
                            dynamicParamsKeys: res,
                        })
                    )
                )
            )
        )
    );
}
