<mat-stepper [linear]="false" #stepper [selectedIndex]="selectedIndex" (selectionChange)="matStepperStepChange($event)">
    <mat-step label="Select App" [stepControl]="firstFormGroup">
        <div class="row w-100">
            <mat-form-field class="col-6 m-auto" appearance="outline" appRemoveAriaOwns>
                <mat-label>Select App</mat-label>
                <mat-select [formControl]="appControl" required #select>
                    <mat-option>None</mat-option>
                    <mat-form-field class="searchEntities searchInputField selectFilter">
                        <input matInput placeholder="Search App" type="text" [(ngModel)]="selectApp" stop-propagation>
                        <button mat-button matSuffix mat-icon-button aria-label="clear" (click)="selectApp = ''">
                            <mat-icon class='searchEntitiesIcons' *ngIf='selectApp'>
                                close
                            </mat-icon>
                        </button>
                    </mat-form-field>
                    <ng-container *ngFor="let app of apps | multiFilter: selectApp; trackBy:trackByFn">
                        <ng-container [ngSwitch]="app.assetType">
                            <ng-container *ngSwitchCase="'BRIDGE_ASSET'">
                                <mat-option [value]="app" (click)="getSubApps(app)">
                                    <span class="d-flex align-items-center">
                                        <span *ngIf="select?.panelOpen" class="material-icons mr-3" style="font-size: 19px; color: green;">list</span>
                                        {{app.displayName ?? app.name}}
                                    </span>
                                </mat-option>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                                <mat-option [value]="app" (click)="resetBridgeStatus()">
                                    <span class="d-flex align-items-center">
                                        <span *ngIf="select?.panelOpen" class="material-icons mr-3" style="font-size: 19px; color: green;">article</span>
                                        {{app.displayName ?? app.name}}
                                    </span>
                                </mat-option>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="appControl.hasError('required')">Please choose an app</mat-error>
            </mat-form-field>
            <mat-form-field class="col-6 m-auto" appearance="outline" *ngIf="subAppsPresent" appRemoveAriaOwns>
                <mat-label>Select Sub App</mat-label>
                <mat-select [formControl]="subAppControl" required>
                    <mat-option>None</mat-option>
                    <ng-container *ngFor="let app of subApps | ObjNgFor; trackBy:trackByFn">
                        <mat-option [value]="subApps[app]" *ngIf="subApps[app].componentType === 'ASSET'">
                            {{(subApps[app].displayName || subApps[app].name)}}
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="subAppControl.hasError('required')">Please choose a sub app</mat-error>
            </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
            <button mat-raised-button matStepperNext [disabled]="!appControl.value || (subAppsPresent && !subAppControl.value)" (click)="onNextClick()" [color]="'primary'">Next</button>
        </div>
    </mat-step>
    <mat-step label="Pre-Capture Attributes" [optional]="isOptional" [stepControl]="secondFormGroup">
        <div class="row w-100">
            <ng-container *ngIf="filingAttributeFields?.length > 0; else noFilingAtributes">
                <ng-container *ngFor="let field of filingAttributeFields">
                    <div class="col-4" *ngIf="!field.autoCalculate && field.show">
                        <filingattribute-field [field]="field" [record]="filingAttributeFields" [service]="subAppControl?.value || appControl?.value" [type]="'filingDate'"></filingattribute-field>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #noFilingAtributes>
                <p class="text-center">No filing attributes found for this app.</p>
            </ng-template>
        </div>
        <div class="d-flex justify-content-end">
            <button class="stepBtns" mat-raised-button matStepperPrevious>Back</button>
            <button mat-raised-button [color]="'primary'" (click)="validateFilingAttributes()">Next</button>
        </div>
    </mat-step>
    <mat-step label="Select Transformation" [stepControl]="thirdFormGroup">
        <div class="row w-100">
            <mat-form-field class="w-50 m-auto" appearance="outline" appRemoveAriaOwns>
                <mat-label>Select Transformation</mat-label>
                <mat-select [formControl]="transformationControl" required (selectionChange)="transformationSelectionChange()">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let transformation of sourcePartTransformations" [value]="transformation">
                        {{transformation.displayName ?? transformation.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="transformationControl.hasError('required')">Please choose an transformation</mat-error>
            </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
            <button class="stepBtns" mat-raised-button matStepperPrevious>Back</button>
            <button mat-raised-button matStepperNext [disabled]="!transformationControl.value" [color]="'primary'">Next</button>
        </div>
    </mat-step>
    <mat-step label="Review Association" [optional]="isOptional">
        <div class="row">
            <div class="col-3 text-success mb-3">Source App&nbsp;:</div>
            <div class="col-3">{{sourceApp?.displayName}}</div>
            <div class="col-3 text-success mb-3">Target App&nbsp;:</div>
            <div class="col-3">{{subAppsPresent ? (subAppControl?.value?.displayName || subAppControl?.value?.name) : appControl?.value?.displayName}}</div>
            <div class="col-3 text-success mb-3">Selected Transformation&nbsp;:</div>
            <div class="col-3">{{transformationControl?.value?.chainDisplayName || transformationControl?.value?.displayName}}</div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="stepBtns" mat-raised-button matStepperPrevious>Back</button>
            <button mat-raised-button (click)="stepper.reset()">Reset</button>
            <button mat-raised-button (click)="submitConsolidation()" [color]="'primary'" [disabled]="!transformationControl.value">Submit</button>
        </div>
    </mat-step>
</mat-stepper>
