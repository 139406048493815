import { Component, ElementRef, Inject, Input, OnInit, Optional, SecurityContext, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as pdfjsLib from 'pdfjs-dist';
import * as vkbeautify from 'vkbeautify';
import * as XLSX from 'xlsx';

@Component({
    selector: 'doc-preview',
    templateUrl: './doc-preview.component.html',
    styleUrls: ['./doc-preview.component.scss'],
})
export class DocPreviewComponent implements OnInit {
    @Input() inputData: any;
    @Input() showThumbnails?: boolean = true;
    @Input() isViewDocument: boolean = false;

    @ViewChild('pdfCanvas') pdfCanvas!: ElementRef<HTMLCanvasElement>;
    @ViewChild('textLayer') textLayer!: ElementRef<HTMLDivElement>;

    public safeHtmlContent: SafeResourceUrl;
    public docData: any;
    public doc: {
        fileUrl: any;
        fileName: string;
        acceptheader: string;
        fileExtension: string;
    } = {
        fileUrl: this._sanitizer.bypassSecurityTrustResourceUrl(''),
        fileName: '',
        acceptheader: '',
        fileExtension: '',
    };
    public pdfPages: any[] = [];
    public currentPageData: string | null = null;
    public pdfThumbnails: string[] = [];
    private pdfDoc: any;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        public data: {
            blob: Blob;
            htmlContent: any;
            fileName: string;
            fileLink: string;
            acceptHeader: string;
            fileExtension: string;
        },
        private _sanitizer: DomSanitizer
    ) {}

    private formatHtmlContent() {
        if (this.docData?.htmlContent) {
            if (this.docData.fileExtension === 'json') {
                this.docData.htmlContent = this._sanitizer.sanitize(
                    SecurityContext.HTML,
                    this._sanitizer.bypassSecurityTrustHtml(vkbeautify.json(this.docData.htmlContent))
                );
            } else if (this.docData.fileExtension === 'xml' || 'txt' || 'D' || 'ft' || 'tds') {
                this.docData.htmlContent = this._sanitizer.sanitize(
                    SecurityContext.HTML,
                    this._sanitizer.bypassSecurityTrustHtml(vkbeautify.xml(this.docData.htmlContent))
                );
            }
        }
    }

    public toggleThumbnails(): void {
        this.showThumbnails = !this.showThumbnails;
    }

    constructAndSanitizeUrl = () => {
        this.doc.fileName = this.docData?.fileName || '';
        this.doc.acceptheader = this.docData?.acceptHeader || '';
        this.doc.fileExtension = this.docData?.fileExtension || '';
        const url = this.docData?.blob !== null && window.URL.createObjectURL(this.docData.blob);
        if (url?.length) {
            if (this.doc.fileExtension === 'xlsx' || this.doc.fileExtension === 'xlsm' || this.doc.fileExtension === 'xls') {
                this.renderXLSX();
            } else if (this.doc.fileExtension === 'csv') {
                this.renderCSV();
            } else if (this.doc.fileExtension === 'pdf') {
                this.renderPDF();
            } else {
                this.doc.fileUrl = this._sanitizer.bypassSecurityTrustResourceUrl(url);
            }
        }
        this.safeHtmlContent =
            this.docData.fileExtension === 'html' &&
            this._sanitizer.bypassSecurityTrustResourceUrl('data:text/html;charset=utf-8,' + encodeURIComponent(this.docData.htmlContent));
    };

    private renderXLSX = () => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const data = e.target.result;
            const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'binary', sheetStubs: true });
            let finalHtml = '';
            workbook.SheetNames.forEach((sheetName, index) => {
                const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
                const html = this.convertSheetToHtml(worksheet);
                const sheetHtml = index === 0 ? html : `<div style="margin-top: 20px;"></div>${html}`;
                finalHtml += sheetHtml;
            });
            const blob = new Blob([finalHtml], { type: 'text/html' });
            const fileUrl = URL.createObjectURL(blob);
            this.doc.fileUrl = this._sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
        };
        reader.readAsBinaryString(this.docData.blob);
    };

    private renderPDF = () => {
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const data = e.target.result;
            const loadingTask = pdfjsLib.getDocument({ data });
            loadingTask.promise.then(
                (pdf) => {
                    this.pdfDoc = pdf;
                    this.pdfThumbnails = [];
                    for (let i = 1; i <= pdf.numPages; i++) {
                        this.renderThumbnail(i);
                    }
                    this.navigateToPage(1);
                },
                (error) => {
                    console.error('Error loading PDF', error);
                }
            );
        };
        reader.readAsArrayBuffer(this.docData.blob);
    };

    private renderThumbnail = (pageNumber: number) => {
        this.pdfDoc.getPage(pageNumber).then((page: any) => {
            const scale = 0.5;
            const viewport = page.getViewport({ scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            page.render(renderContext).promise.then(() => {
                this.pdfThumbnails[pageNumber - 1] = canvas.toDataURL();
            });
        });
    };

    private renderPage(pageNumber: number) {
        this.pdfDoc.getPage(pageNumber).then((page: any) => {
            const canvas = this.pdfCanvas.nativeElement;
            const context = canvas.getContext('2d')!;
            const viewport = page.getViewport({ scale: 1.5 });
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            page.render(renderContext).promise.then(() => {
                this.extractText(page, viewport);
            });
        });
    }

    private extractText(page: any, viewport: any) {
        page.getTextContent().then((textContent) => {
            const { items, styles } = textContent;
            this.textLayer.nativeElement.innerHTML = '';
            items.forEach((item) => {
                const span = document.createElement('span');
                span.textContent = item.str;
                const [scaleX, , , scaleY, x, y] = item.transform;
                const fontSize = Math.sqrt(scaleX ** 2 + scaleY ** 2);
                const [adjustedX, adjustedY] = viewport.convertToViewportPoint(x, y);
                const fontInfo = styles[item.fontName];
                const fontFamily = fontInfo?.fontFamily || 'default';
                const scaledWidth = (item.width * scaleX) / viewport.scale;
                span.style.position = 'absolute';
                span.style.left = `${adjustedX}px`;
                span.style.top = `${adjustedY - fontSize}px`;
                span.style.fontSize = `${fontSize}px`;
                span.style.transformOrigin = '0 0';
                span.style.whiteSpace = 'pre';
                span.style.width = `${scaledWidth}px`;
                span.style.height = `${fontSize}px`;
                span.style.color = 'transparent';
                span.style.fontFamily = fontFamily;
                this.textLayer.nativeElement.appendChild(span);
            });
            this.updateTextLayerDimensions(this.pdfCanvas.nativeElement, viewport);
        });
    }

    private updateTextLayerDimensions = (_canvas, _viewport) => {
        this.textLayer.nativeElement.style.zIndex = '1';
    };

    public navigateToPage(pageNumber: number): void {
        this.renderPage(pageNumber);
    }

    private convertSheetToHtml(worksheet: XLSX.WorkSheet): string {
        let html = '<table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse;">';
        let numRows = 0;
        let numCols = 0;
        const dataCellAddresses = Object.keys(worksheet).filter((cellAddress) => !cellAddress.startsWith('!'));

        for (const cellAddress of dataCellAddresses) {
            const row = parseInt(cellAddress.match(/\d+/)[0]);
            const col = cellAddress
                .match(/[A-Z]+/)[0]
                .split('')
                .reduce((acc, char) => acc * 26 + (char.charCodeAt(0) - 64), 0);
            numRows = Math.max(numRows, row);
            numCols = Math.max(numCols, col);
        }

        for (let row = 1; row <= numRows; row++) {
            let rowHtml = '';
            let prevCol = 0;

            for (let col = 1; col <= numCols; col++) {
                const colName = this.getColumnName(col);
                const cellAddress = colName + row;
                const cell = worksheet[cellAddress];
                const cellValue = cell?.w ?? '';
                const wrappedCellValue = cellValue?.startsWith("'") ? `<pre>${cellValue}</pre>` : cellValue;

                for (let i = prevCol + 1; i < col; i++) {
                    rowHtml += `<td></td>`;
                }

                rowHtml += `<td>${wrappedCellValue}</td>`;
                prevCol = col;
            }

            html += `<tr>${rowHtml}</tr>`;
        }

        html += '</table>';
        return html;
    }

    private getColumnName(col: number): string {
        let name = '';
        while (col > 0) {
            const remainder = (col - 1) % 26;
            name = String.fromCharCode(65 + remainder) + name;
            col = Math.floor((col - 1) / 26);
        }
        return name;
    }

    private renderCSV = () => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const data = e.target.result;
            const workbook: XLSX.WorkBook = { Sheets: {}, SheetNames: [] };
            const sheet = XLSX.read(data, { type: 'binary' }).Sheets;
            const sheetName = Object.keys(sheet)[0];
            workbook.Sheets[sheetName] = sheet[sheetName];
            workbook.SheetNames.push(sheetName);
            const html = XLSX.write(workbook, { bookType: 'html', bookSST: false, type: 'base64' });
            const decodedHtml = atob(html);
            const modifiedHtml = decodedHtml.replace(
                /<table/g,
                '<table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse;"'
            );
            const blob = new Blob([modifiedHtml], { type: 'text/html' });
            const fileUrl = URL.createObjectURL(blob);
            this.doc.fileUrl = this._sanitizer.bypassSecurityTrustResourceUrl(fileUrl);
        };
        reader.readAsBinaryString(this.docData.blob);
    };

    ngOnInit(): void {
        this.docData = this.inputData !== undefined ? this.inputData : this.data;
        this.constructAndSanitizeUrl();
        this.formatHtmlContent();
    }
}
