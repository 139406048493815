import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { translate } from '@ngneat/transloco';

import { TableData } from '../../interface/table-data.interface';
import { Message } from '../../models/record/message.class';
import { RecordField } from '../../models/record/recordfield.class';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ValidationsService } from '../../services/validations/validations.service';
import { GridFieldModalComponent } from '../grid-field-modal/grid-field-modal.component';

@Component({
    selector: 'grid-field',
    templateUrl: './grid-field.component.html',
    styleUrls: ['./grid-field.component.scss'],
})
export class GridFieldComponent implements OnInit {
    @Input() field: RecordField;
    @Input() readOnly: boolean;
    @Input() recordUpdateDisabled: boolean;
    @Input() recordId: string;
    @Input() hideDisplayNameData: boolean;

    gridFieldModal: MatDialogRef<GridFieldModalComponent>;

    constructor(private _validations: ValidationsService, private _utils: UtilsService, private dialog: MatDialog) {}

    openGridModel = () => {
        this.gridFieldModal = this.dialog.open(GridFieldModalComponent, {
            panelClass: ['gridFieldModal', 'matDialogContainer'],
            disableClose: true,
        });
        this.gridFieldModal.componentInstance.field = this.field;
        this.gridFieldModal.componentInstance.saveAndClose = this.saveAndClose;
        this.gridFieldModal.componentInstance.readOnly = this.readOnly || this.hideDisplayNameData;
        this.gridFieldModal.componentInstance.recordUpdateDisabled = this.recordUpdateDisabled;
        this.gridFieldModal.componentInstance.initiateGridData();
    };

    saveAndClose = async () => {
        const field: RecordField = CommonUtilsService.cloneObject(this.gridFieldModal.componentInstance.errorField || this.field);
        const data: TableData[] = CommonUtilsService.cloneObject(this.gridFieldModal.componentInstance.tableData.value);
        const constraints = field.gridConstraints;
        const cellsMetaData = constraints.cellDetails;
        if (!field.gridData) {
            field.gridData = {
                cells: [],
                hasCellErros: false,
                id: field.id,
                message: new Message(undefined),
                modified: true,
            };
        }
        const gridData = field.gridData;
        cellsMetaData.forEach((cellData) => {
            const rowIndex = cellData.rowIndex;
            const columnIndex = cellData.columnIndex;
            const record = data.find((_tableRecord, index) => index === rowIndex);
            let cellValue = (record as any) && record[columnIndex];
            const gridCell = gridData.cells.find(
                (cell) => cell.columnIndex === cellData.columnIndex && cell.rowIndex === cellData.rowIndex
            );
            if (gridCell) {
                gridCell.errors = [];
                gridCell.warnings = [];
                gridCell.modified = true;
                gridCell.value = cellValue as any;
            } else {
                gridData.cells.push({
                    columnIndex: cellData.columnIndex,
                    errors: [],
                    warnings: [],
                    modified: true,
                    rowIndex: cellData.rowIndex,
                    value: cellValue as any,
                });
            }
        });
        const hasErrors = await this._validations.validateGrid(field);
        if (hasErrors) {
            this.gridFieldModal.componentInstance.errorField = field;
            this.gridFieldModal.componentInstance.buildTableData();
            return this._utils.alertError(translate('Fix issues to proceed'));
        } else {
            this.field.gridData = field.gridData;
        }
        this.gridFieldModal.close();
    };

    ngOnInit() {}

    ngOnDestroy() {
        this.dialog.closeAll();
    }
}
