import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

import { IntegrationOrganizationsState, IntegrationsState } from '../interface/integration-state.interface';

const organizationsAdapter = createEntityAdapter<IntegrationOrganizationsState>();

export const integrationAdapters: { [property: string]: EntityAdapter<any> } = {
    organizationsAdapter: organizationsAdapter,
};

export const integrationsState: IntegrationsState = {
    activeTab: undefined,
    currentOrganizationId: undefined,
    organizations: organizationsAdapter.getInitialState(),
    authorizationSystems: undefined,
    appIntegrationConfigs: undefined,
    integrationsSupprtedTypes: undefined,
    dynamicParamsKeys: undefined,
};
