import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RootScope } from '@encomply-core/services/rootscope/rootscope.service';
import { translate } from '@ngneat/transloco';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BroadcasterService } from 'ng-broadcaster';
import { QueryBuilderConfig } from 'ng-query-builder';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MasterRecordsResponse, MastersResponseRecord } from '../../interface/master-records-response.interface';
import { MastersTableMetaDataInterface } from '../../interface/mastertablesmetadata.interface';
import { RecordChangeLog, RecordUserChangeLog } from '../../interface/record-change-log.interface';
import { TableColumn } from '../../interface/table-column.interface';
import { TableData } from '../../interface/table-data.interface';
import { AppIntegrationRecord } from '../../models/integrations/appintegrationrecord.class';
import { AppNewIntegrationRecordPayload } from '../../models/integrations/appnewintegrationpayload.class';
import { Mapping } from '../../models/mappings.class';
import { MasterField } from '../../models/masters/masterfield.class';
import { MasterRecord } from '../../models/masters/masterrecord.class';
import { MasterRequest } from '../../models/masters/masterRequest.class';
import { MastersMetaData } from '../../models/masters/mastersmetadata.class';
import { Organization } from '../../models/organization.class';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { ConfirmationDialogService } from '../../services/confirmation/confirmation-dialog.service';
import { ElasticsearchService } from '../../services/elasticsearch/elasticsearch.service';
import { FileUpload } from '../../services/file-upload/fileuploadservice';
import { IntegrationsConfigService } from '../../services/integrations-config/integrations-config.service';
import { IntegrationsUtilsService } from '../../services/integrations-utils/integrations-utils.service';
import { RootScopeService } from '../../services/rootscope/rootscope.service';
import { UtilsService } from '../../services/utils/utils.service';
import { ValidationsService } from '../../services/validations/validations.service';
import {
    AlertSuccess,
    CreateRecord,
    CRUDOperationRecord,
    DeleteAppIntegrationDetails,
    DeleteMasterRecords,
    GenerateMasterReport,
    GenerateMasterReportFailure,
    GenerateMasterReportSuccess,
    GetAppIntegration,
    GetCustomFilters,
    GetMasterChainDisplayNames,
    GetMasterLanguageTemplate,
    GetMasterMetadata,
    GetMasterRecordChangeLogs,
    GetMasterRecords,
    GetMasterRequests,
    GetMasters,
    GetMastersInboundMappings,
    GetMastersOutboundMappings,
    GetMasterUploadFiles,
    GetParentMasterRecords,
    GetParentPrimaryKeys,
    GetReportDetails,
    GetSearchedMasterRecords,
    SetMasterRecordChangeLogs,
    SetMasterRecordPagination,
    SetMasterRecords,
    SetMasterRequests,
    SetMasterRequestsPagination,
    SetMasterTable,
    SetParentTableId,
    SetParentTableRestApiName,
    SetSearchedRecordsCount,
    UpdateMasterRecord,
} from '../../store/actions';
import {
    getAppIntegration$,
    getCurrentOrganizationId$,
    getMasterchainDisplayNames$,
    getMasterMetadata$,
    getMasterRecords$,
    getMasterRecordsPagingState$,
    getMasterRequests$,
    getMasters$,
    getMastersInboundMappings$,
    getMastersOutboundMappings$,
    getMasterUploadFiles$,
    getParentMasterRecords$,
    getParentPrimaryKeys$,
    getReportDetails$,
    getSearchedRecordsCount$,
    getSearchFilters$,
    getSelectedRestApiName$,
    getSelectedTableId$,
} from '../../store/selectors';
import { MasterChangeLogComponent } from '../masterchangelogs/masterchangelog.component';
import { MasterRequestsNotificationsComponent } from '../masterrequests/masterrequests.component';
import { MastersearchComponent } from '../mastersearch/mastersearch.component';

@Component({
    selector: 'app-common-masters',
    templateUrl: './common-masters.component.html',
    styleUrls: ['./common-masters.component.scss'],
})
export class CommonMastersComponent implements OnInit, OnDestroy {
    @Input() isFromEnComply: boolean;
    @Input() instanceId: string;
    @Input() isFromEnInvoice: boolean;
    @Input() assetMasterIds: string[];

    @Output() onCloseModal = new EventEmitter();

    @ViewChild('matMasterMenuTrigger') matMasterMenuTrigger: MatMenuTrigger;
    @ViewChild('drawer', { static: false }) matDrawer: MatDrawer;
    @ViewChild('masterSearchInput', { static: false }) _masterSearchInput: ElementRef;

    public organization: Organization = new Organization(this._commonUtils.getFromStorage('currentOrganization'));
    private mastersTable: BehaviorSubject<MasterRecord[]> = new BehaviorSubject([]);
    public selectedMasterTable: MastersTableMetaDataInterface = {};
    private masterMetaData: MastersMetaData = new MastersMetaData({});
    private taxilla_api: string = this.R.TAXILLA_API_URL;
    public records: MasterRecord[] = [];
    public editRecord: MasterRecord;
    public allMasters: any;
    public subTenants: any[] = this.R.subTenants;
    public hideRecords: boolean = false;
    public pagination: {
        pagingState: string;
        size?: number;
        count: number;
        pageIndex: number;
        page: {
            property?: any;
        };
        pages: any[];
        disableNext: boolean;
    } = {
        pagingState: '',
        size: 10,
        count: 0,
        pageIndex: 0,
        page: {},
        pages: [],
        disableNext: true,
    };
    public requestsPagination: {
        pagingState: string;
        size?: number;
        count: number;
        pageIndex: number;
        page: {
            property?: any;
        };
        pages: any[];
        disableNext: boolean;
        timeRange: any[];
    } = {
        pagingState: '',
        size: 10,
        count: 0,
        pageIndex: 0,
        page: {},
        pages: [],
        disableNext: true,
        timeRange: null,
    };
    private currentOrgId: string;
    public noRecordsText: string;
    public noRecordsIcon: string;
    public routeMasterId = '';
    private masterData: any;
    public masterDataName: any;
    private masterRestApiName: any;
    private masterDatakeyColumns: any;
    public location = '';
    public hiddenFields = ['recordKey', 'unit_id'];
    public updateRecord = false;
    public list = [];
    private searchModalRef: MatDialogRef<MastersearchComponent>;
    private searchConfig: QueryBuilderConfig = {
        fields: {},
    };
    private sortConfig: QueryBuilderConfig = {
        fields: {},
    };
    public searchQuery: any;
    public sortQuery: any;
    private searchFilters = [];
    public masterKeyMetaData = {};
    public parentPrimaryKeyValues = {};
    public openDrawer = false;
    public defaultFilter: any;
    public units_associated = [];
    public unassignMaster = false;
    public locationIdList = [];
    public checkedList = [];
    public outboundTransformationSourceFile = false;
    public outboundMappings = {};
    public masterDownloads = [];
    public downloadMasterFlag = false;
    private isDownloadReport = false;
    public uploadMasterFlag = false;
    public masterDataOutboundMap: any;
    public transformationSourceFile = false;
    public mappings = {};
    private transformationRepositoryId = '';
    public isUploadedFiles = false;
    public hasChecksum = false;
    private checkSumDetails = [];
    public mastersUploadData = {
        filename: '',
        url: '',
        title: translate('Import'),
        return: true,
    };
    public fileName = translate('No file chosen') as string;
    public clearFiles = new BehaviorSubject('clear');
    private transformationName = '';
    public files: any;
    public masterDataMap: any;
    public parentPrimaryKeyColumns = [];
    private parentKeyColumnIds: string[] = [];
    private parentRecords: MasterRecordsResponse['records'] = [];
    public parentRecord = false;
    private timeout;
    public synchronizeFlag = false;
    public notificationFlag = false;
    private existingConfiguration = [];
    private selectedMethod = '';
    public allPermissions = [];
    public actions_list = {
        update_record: 'UPDATE_RECORD',
        delete_record: 'DELETE_RECORD',
        read_record: 'READ_RECORD',
        add_record: 'ADD_RECORD',
    };
    public record: AppIntegrationRecord;
    public sources = [];
    public downloadReportName = '';
    public transformationMappings: {
        [property: string]: {
            name: string;
            description: string;
            engineProps: {
                type: string;
                version: string;
            };
            sourceParts: {
                name: string;
                sourceDataFormat: string;
                fileExtension: string;
                bulkUpload: false;
                entities: any[];
                settings: {};
            }[];
            mappedTargetMetadata: {
                name: string;
                dataFormat: string;
                fileExtension: string;
                mappedEntities: any[];
                disableStreamBased: boolean;
            };
            mappingMetadata: {
                mapId: string;
                mapName: string;
            };
            defaulted: false;
            xmlVersion: string;
        };
    };
    public bridge = undefined;
    public masterTableColumns = undefined;
    public subApp = undefined;
    public app = undefined;
    public transformedMappings = [];
    private reportCallCount = 0;
    public masterSearchText: string;
    public showRequests: boolean;
    private masterRequests = [];
    public masterRequestrecordActions = [];
    private changeLogs: RecordChangeLog[] = [];
    private changedFieldIds: string[] = [];
    private needToRefresh: boolean;
    public passwordColumns = [];
    public noRelatedMasters = false;
    public config = this._config.inboundMasters;
    public configRecord: AppIntegrationRecord;
    public configuredRecords: any[] = [];
    public hideField = this._integrationUtils.hideField;
    public downloadMasterReports = false;
    public showRefreshIcon = false;
    public timeFilter = {
        startTime: null,
        startDate: null,
        endTime: null,
        endDate: null,
    };
    public searchMode = false;
    private selectedMasterFilter: any;
    public validationFilter: any;
    public allColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([
        {
            name: translate('Created By'),
            type: 'text',
            icon: undefined,
            id: 'createdBy',
            hideInAllColumnsList: false,
            hide: false,
        },
        {
            name: translate('File Name'),
            type: 'ACCORDIAN',
            icon: undefined,
            id: 'fileName',
            hideInAllColumnsList: false,
            hide: false,
        },
        {
            name: translate('Created On'),
            type: 'text',
            icon: undefined,
            id: 'createOn',
            hideInAllColumnsList: false,
            hide: false,
        },
        {
            name: translate('Check Sum'),
            type: 'text',
            icon: undefined,
            id: 'checkSum',
            hideInAllColumnsList: false,
            hide: false,
        },
    ]);
    public selectedFileUploadColumns: string[] = ['createdBy', 'fileName', 'createOn', 'checkSum'];
    public fileDetailstableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    public allMasterRequestColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([
        {
            name: translate('Created Date'),
            type: 'text',
            icon: undefined,
            id: 'createdDate',
            hideInAllColumnsList: false,
            hide: false,
        },
        {
            name: translate('Transformation Name'),
            type: 'text',
            icon: undefined,
            id: 'transformationName',
            hideInAllColumnsList: false,
            hide: false,
        },
        {
            name: translate('Source(s)'),
            type: 'ACCORDIAN',
            icon: undefined,
            id: 'fileName',
            hideInAllColumnsList: false,
            hide: false,
        },
        {
            name: translate('Status'),
            type: 'text',
            icon: undefined,
            id: 'requestState',
            hideInAllColumnsList: false,
            hide: false,
        },
        {
            name: translate('Input Type'),
            type: 'text',
            icon: undefined,
            id: 'sourceType',
            hideInAllColumnsList: false,
            hide: false,
        },
        {
            name: translate('User Name'),
            type: 'text',
            icon: undefined,
            id: 'userName',
            hideInAllColumnsList: false,
            hide: false,
        },
        {
            name: translate('Request ID'),
            type: 'text',
            icon: undefined,
            id: 'requestId',
            hideInAllColumnsList: false,
            hide: false,
        },
    ]);
    public selectedMasterRequestColumns: string[] = [
        'createdDate',
        'transformationName',
        'fileName',
        'requestState',
        'sourceType',
        'userName',
        'requestId',
    ];
    public requeststableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    public allTableColumns: BehaviorSubject<TableColumn[]> = new BehaviorSubject([]);
    public tableData: BehaviorSubject<TableData[]> = new BehaviorSubject([]);
    public selectedColumns = [];
    private unSubscribe = new Subject<void>();
    public tableRecordActions = [];
    public selectedRecordsCount = 0;
    public mapingSelected: boolean = true;
    public chainNamesVsDiplayNames: any;
    private parentRestApiName: string;
    private isUpload: boolean = false;
    private isFromRequests: boolean = false;
    isFromDownload: boolean;
    recordsResponse: MastersResponseRecord[] = [];
    pagingStateResponse: { pagingState: string; count: number } = {
        count: undefined,
        pagingState: undefined,
    };
    private isFromGenerate: boolean;
    private isFromRefresh: boolean;
    private isFromUplodedFiles: boolean;
    private selectedChangelogRecord: any;

    constructor(
        public _libUtils: UtilsService,
        private _validations: ValidationsService,
        private _api: ApiService,
        public _utils: UtilsService,
        private _esService: ElasticsearchService,
        private confirmationService: ConfirmationDialogService,
        private fileupload: FileUpload,
        private _config: IntegrationsConfigService,
        private _integrationUtils: IntegrationsUtilsService,
        public _commonUtils: CommonUtilsService,
        private dialog: MatDialog,
        private _broadcaster: BroadcasterService,
        protected _router: Router,
        private store$: Store,
        public R: RootScope,
        private _lib_R: RootScopeService,
        private actions: Actions
    ) {}

    public apiConfigChange = (value) => {
        let newValue = value;
        if (newValue !== undefined && newValue !== '') {
            newValue =
                newValue === 'FTPSettings' || newValue === 'FTP'
                    ? 'FTP'
                    : newValue === 'WebServiceSettings' || newValue === 'WEBSERVICE'
                    ? 'WEBSERVICE'
                    : newValue;
        }
        this._integrationUtils.getIOConfiguredList(newValue, false, this.configuredRecords, undefined, true);
        if (
            this.configRecord?.originalData &&
            this.configRecord?.originalData['integrationMethod'] !== newValue &&
            this.selectedMethod !== newValue
        ) {
            this.setConfigRecords(newValue);
            setTimeout(() => {
                this.setConfigRecords(newValue);
            }, 1000);
        }
    };

    private startSelectedMaster = () => {
        this._lib_R.selectedMaster = this.allMasters?.find((master) => master.itemId === this.routeMasterId);
        this.selectedMasterTable = this.allMasters?.find((master) => master.itemId === this.routeMasterId);
        this.resetValues();
        clearTimeout(this.timeout);
        if (this.selectedMasterTable && Object.keys(this.selectedMasterTable).length > 0) {
            this.fetchMasterTableMetaData();
            this.getCustomFilter();
        } else {
            this.timeout = setTimeout(this.startSelectedMaster, 1000);
        }
    };

    private resetValues = () => {
        this.parentPrimaryKeyColumns = [];
        this.masterRequests = [];
        this.changeLogs = [];
        this.needToRefresh = false;
        this.allTableColumns.next(undefined);
        this.selectedColumns = [];
        this.list = [];
        this.tableRecordActions = [];
        this.resetDateFilter();
    };

    private fetchMasterTableMetaData = () => {
        this.store$.dispatch(
            GetMasterLanguageTemplate({
                organizationId: this.currentOrgId,
                tableId: this.selectedMasterTable.itemId,
            })
        );
        this.store$.dispatch(
            GetMasterMetadata({
                organizationId: this.currentOrgId,
                tableId: this.selectedMasterTable.itemId,
                isParent: false,
            })
        );
    };

    private buildPermissions = (permissionList) => {
        this.allPermissions = [];
        if (!this._utils.isEmpty(permissionList)) {
            this.allPermissions = permissionList[Object.keys(permissionList)[0]].actions;
        } else {
            this.allPermissions = [];
        }
        return true;
    };

    private masterKeymetaData = (allData) => {
        const tempobj = {};
        allData.forEach((master) => {
            tempobj[master.id] = master;
        });
        return tempobj;
    };

    private updateMandatoryFields = (masterData, keyColumns) => {
        const newMasterData = CommonUtilsService.cloneObject(masterData);
        newMasterData.forEach((md) => {
            md['mandatory'] = keyColumns.indexOf(md.id) !== -1;
        });
        return newMasterData;
    };

    private getPasswordFields = (masterData) => {
        const passwordFields = masterData.filter((md) => {
            return md['uiTypeMetadata'] === 'PASSWORD';
        });
        const passwordColumns = [];
        passwordFields.forEach((field) => {
            passwordColumns.push(field.id);
        });
        return passwordColumns;
    };

    private getParentPrimaryKeys = (tableId: string) => {
        this.store$.dispatch(
            GetParentPrimaryKeys({
                organizationId: this.organization.id,
                tableId: tableId,
            })
        );
    };

    private resetMasterRecords = () => {
        this.store$.dispatch(
            SetMasterRecords({
                records: [],
                organizationId: this.currentOrgId,
                restApiName: this.masterRestApiName,
                isClearSearch: true,
            })
        );
    };

    private resetSearchCount = () => {
        this.store$.dispatch(
            SetSearchedRecordsCount({
                searchRecordsCount: 0,
            })
        );
    };
    public startRecordVariables = () => {
        this.resetPagination();
        if (
            (this.searchQuery && this.searchQuery['rules'] && this.searchQuery['rules'].length) ||
            (this.sortQuery && this.sortQuery['rules'] && this.sortQuery['rules'].length) ||
            this.defaultFilter?.criteria ||
            this.validationFilter?.length > 0
        ) {
            this.getRecordsIfSearchActive();
        } else {
            this.getMasterRecords();
        }
    };

    protected updatePaginationData = () => {
        const recordsData = this.records;
        if (!recordsData) {
            return;
        }
        const recordsSize = recordsData?.length;
        const pagination = this.pagination;
        const pages = Object.keys(pagination.page || {});
        let nextPageExists = false;
        const currentPage = pagination.page[pagination.pageIndex];
        if (pagination.pageIndex + 1 === pages.length) {
            if (currentPage !== undefined) {
                if (Array.isArray(currentPage) && currentPage.length > 0) {
                    nextPageExists = true;
                } else if (typeof currentPage === 'string' && currentPage.length > 0) {
                    nextPageExists = true;
                }
            }
        } else if (pagination.pageIndex + 1 < pages.length) {
            nextPageExists = true;
        }
        const isLatestPage = pages.length - 1 === pagination.pageIndex;
        const incrementBy = isLatestPage && nextPageExists ? 1 : 0;
        let count = 0;
        if (pagination.pageIndex + 1 < pages.length) {
            count = this.pagination.count;
        } else {
            count = recordsSize + (pagination.pageIndex || 0) * (pagination.size || 0) + incrementBy;
        }
        this.pagination.count = count;
    };

    private updatePaginationCount = (res) => {
        this.pagination.count = res;
    };

    private getParentRecords = () => {
        this.parentRestApiName &&
            this.store$.dispatch(
                GetParentMasterRecords({
                    restApiName: this.parentRestApiName,
                    size: 10000,
                    pagingState: '',
                })
            );
    };

    private getMasterRecords = () => {
        let pageIndex = this.pagination?.pageIndex - 1 < 0 ? 0 : this.pagination?.pageIndex - 1;
        const data: {
            size: number;
            pageIndex: number;
            pagingState: any;
        } = {
            size: this.pagination.size,
            pageIndex: this.pagination.pageIndex,
            pagingState: this.pagination?.page?.[pageIndex] || '',
        };
        this.store$.dispatch(
            SetMasterRecordPagination({
                pageSize: data.size,
                pageIndex: data.pageIndex,
                pageState: data.pagingState,
                organizationId: this.currentOrgId,
                restApiName: this.masterRestApiName,
                search: {},
                isUpdate: false,
            })
        );
        this.store$.dispatch(GetMasterRecords(data));
    };

    private onDataFetched = (count: number, pagingState: any) => {
        this.pagination.disableNext = true;
        if (count && count !== 0) {
            this.pagination.page[this.pagination.pageIndex] = pagingState;
            const size = this.pagination.size;
            const index = this.pagination.pageIndex;
            const requiredCount = size * (index + 1);
            this.pagination.pages[this.pagination.pageIndex] = this.pagination.pageIndex + 1;
            this.pagination.disableNext = count < requiredCount;
        } else {
            this.noRecordsText = this.translateMsg('No Records Found');
            this.noRecordsIcon = 'notification_important';
        }
        if (
            this.searchQuery === undefined &&
            this.defaultFilter === undefined &&
            this.sortQuery === undefined &&
            this.validationFilter === undefined
        ) {
            this.updatePaginationData();
        }
    };

    private buildMasterTableCoulmns = (callback?) => {
        this.tableRecordActions = [];
        this.selectedColumns = [];
        this.tableRecordActions.push({
            displayName: this.translateMsg('View Change Log'),
            iconName: 'history',
            onActionTrigger: this.getRecordChangeLog,
        });
        this.tableRecordActions.push({
            displayName: this.translateMsg('Edit'),
            iconName: 'edit',
            onActionTrigger: this.onRecordEdit,
        });
        this.tableRecordActions.push({
            displayName: this.translateMsg('Delete'),
            iconName: 'delete',
            onActionTrigger: this.onDeleteSelectedRecord,
        });
        const columnsData = [];
        const masterTableColumnns = this.masterMetaData?.getAllColumns() || [];
        const primaryColumns = this.masterMetaData?.getPrimaryColumns() || [];
        [...this.parentPrimaryKeyColumns, ...masterTableColumnns]?.forEach((element) => {
            columnsData.push({
                icon: undefined,
                id: element.id,
                name: element.displayName,
                options: undefined,
                type: element.uiTypeMetadata,
                hide: false,
                inputType: element.dataType,
            });
            if (primaryColumns.indexOf(element.id) > -1 || this.selectedColumns?.length < 6) {
                this.selectedColumns.push(element.id);
            }
        });
        columnsData.push({
            icon: undefined,
            hide: false,
            hideInAllColumnsList: true,
            id: 'selectRecord',
            name: undefined,
            type: 'SELECTRECORD',
            showCheckboxInHeader: true,
        });
        this.selectedColumns.unshift('selectRecord');
        this.allTableColumns.next(columnsData);
        callback && callback();
    };

    private buildMasterTableData = (records: MastersResponseRecord[]) => {
        this.records = [];
        if (records.length !== 0) {
            records?.forEach((record) => {
                const childRecord = new MasterRecord(record, this.masterMetaData);
                this.parentPrimaryKeyColumns.forEach((column) => {
                    childRecord.setField(
                        column.id,
                        record.fields.find((field) => field.id === column.id),
                        column
                    );
                });
                this.records.push(childRecord);
            });
        }
        this.mastersTable.next(this.records);
    };

    public fetchItemsInPage = (pageIndex: number | 'next' | 'previous') => {
        if (
            typeof pageIndex === 'number' ||
            (typeof pageIndex === 'string' && pageIndex === 'next' && !this.pagination.disableNext) ||
            (typeof pageIndex === 'string' && pageIndex === 'previous')
        ) {
            let index: number;
            if (typeof pageIndex === 'number') {
                index = pageIndex as number;
            } else if (typeof pageIndex === 'string') {
                switch (pageIndex) {
                    case 'next':
                        index = this.pagination.pageIndex + 1;
                        break;
                    case 'previous':
                        index = this.pagination.pageIndex - 1;
                        break;
                }
            }
            this.pagination.pageIndex = index;
            if (
                (this.searchQuery && this.searchQuery['rules'] && this.searchQuery['rules'].length) ||
                (this.sortQuery && this.sortQuery['rules'] && this.sortQuery['rules'].length) ||
                this.defaultFilter?.criteria ||
                this.validationFilter?.length > 0
            ) {
                this.getRecordsIfSearchActive();
            } else {
                this.getMasterRecords();
            }
        }
    };

    public refreshItemsWithNewPageSize = (data: any) => {
        this.resetPagination();
        this.resetMasterRecords();
        this.pagination.size = data.pageSize;
        if (
            (this.searchQuery && this.searchQuery['rules'] && this.searchQuery['rules'].length) ||
            (this.sortQuery && this.sortQuery['rules'] && this.sortQuery['rules'].length) ||
            this.defaultFilter?.criteria ||
            this.validationFilter?.length > 0
        ) {
            this.getRecordsIfSearchActive();
        } else {
            this.getMasterRecords();
        }
    };

    private getRecordsIfSearchActive = () => {
        let data: {
            searchCriteria?: any;
            searchQuery?: any;
            sortQuery?: any;
            searchConfig?: any;
            pageIndex?: any;
            size?: any;
            dontGetCount?: boolean;
        } = {};
        if (this.selectedMasterFilter === 'validationStatus' && this.validationFilter?.length > 0) {
            const query = {
                condition: 'and',
                rules: [
                    {
                        entity: undefined,
                        field: 'validationStatus',
                        operator: 'default',
                        value: this.validationFilter,
                    },
                ],
            };
            data = {
                searchCriteria: undefined,
                searchQuery: query,
                sortQuery: undefined,
                searchConfig: this.searchConfig,
                pageIndex: this.pagination.pageIndex,
                size: this.pagination.size,
                dontGetCount: true,
            };
        } else {
            data = {
                searchCriteria: this.defaultFilter?.criteria || undefined,
                searchConfig: this.searchConfig,
                searchQuery: this.searchQuery || undefined,
                sortQuery: this.sortQuery || undefined,
                pageIndex: this.pagination.pageIndex,
                size: this.pagination.size,
                dontGetCount: true,
            };
        }
        const preparedCriteria = this._esService.searchMasterEntities(data);
        this.store$.dispatch(
            SetMasterRecordPagination({
                pageSize: data?.size,
                pageIndex: data?.pageIndex,
                pageState: undefined,
                organizationId: this.currentOrgId,
                restApiName: this.masterRestApiName,
                search: {
                    queryString: preparedCriteria?.queryString,
                    sortQuery: preparedCriteria?.sortQueryString,
                },
            })
        );
        this.store$.dispatch(GetSearchedMasterRecords());
    };

    private addMaster = (tempRecord?) => {
        if (tempRecord) {
            this.hideRecords = true;
            this.updateRecord = true;
            this.editRecord = CommonUtilsService.cloneObject(tempRecord);
        } else {
            this.hideRecords = true;
            this.updateRecord = false;
            this.editRecord = new MasterRecord(undefined, undefined);
            this.editRecord.createNewRecord(this.masterMetaData);
            if (this.editRecord.fields) {
                this.editRecord.hasFields = true;
            }
        }
        this.toggleSliders();
    };

    public getDependentPrimaryValues = (currentSelectedId: string) => {
        const selectedIndex = this.parentKeyColumnIds.indexOf(currentSelectedId);
        this.parentKeyColumnIds.forEach((id, index) => {
            if (index > selectedIndex) {
                // First removing values from dependent columns
                this.parentPrimaryKeyValues[id] = [];
                this.editRecord.fields[id].value = '';

                if (index === selectedIndex + 1) {
                    // preparing the next column values
                    this.prepareDependentColumnValues(id);
                }
            }
        });
    };

    private prepareDependentColumnValues = (dependentId: string) => {
        const temp = [];
        const prev_primarySelected = this.checkPreviousPrimaryDependent(dependentId);
        let prevSelectedValuesString: string = '';
        Object.keys(prev_primarySelected)?.forEach((key) => {
            prevSelectedValuesString = prevSelectedValuesString + '_' + prev_primarySelected[key];
        });
        this.parentRecords.forEach((parentData) => {
            let recordValuesString: string = '';
            Object.keys(prev_primarySelected)?.forEach((key) => {
                let value = parentData?.fields?.find((item) => item.id === key)?.value;
                if (value) {
                    recordValuesString = recordValuesString + '_' + value;
                }
            });
            let dependentColumn = parentData?.fields?.find((item) => item.id === dependentId);
            if (prevSelectedValuesString === recordValuesString && !temp.includes(dependentColumn.value)) {
                temp.push(dependentColumn.value);
            }
        });
        this.parentPrimaryKeyValues[dependentId] = temp;
    };

    private checkPreviousPrimaryDependent = (currentSelectedId) => {
        const tempMap = {};
        const currentIndex = this.parentKeyColumnIds.indexOf(currentSelectedId);
        if (currentIndex !== 0) {
            let preIndex = currentIndex - 1;
            while (preIndex >= 0) {
                const primaryId = this.parentKeyColumnIds[preIndex];
                tempMap[primaryId] = this.editRecord.fields?.[primaryId]?.value;
                preIndex = preIndex - 1;
            }
        }
        return tempMap;
    };

    public updateMasterData = (selectedList) => {
        if (selectedList.length !== 0) {
            let payload = {
                data: this.preparePayload(this.updateRecord),
                restApiName: this.masterRestApiName,
            };
            this.store$.dispatch(
                UpdateMasterRecord({
                    data: payload,
                    recordId: this.editRecord?.recordId,
                })
            );
        } else {
            this._libUtils.alertError(this.translateMsg('Please check atleast one location to update record'));
        }
    };

    public submitMaster = async () => {
        if (await this._validations.hasValidMasterRecordData(this.editRecord)) {
            let payload = {
                data: this.preparePayload(this.updateRecord),
                restApiName: this.masterRestApiName,
            };
            if (this.updateRecord) {
                this.store$.dispatch(
                    UpdateMasterRecord({
                        data: payload,
                        recordId: this.editRecord?.recordId,
                    })
                );
            } else {
                this.addMasterData(payload);
            }
        } else {
            this._libUtils.alertError(this.translateMsg('Please fix errors to proceed'));
        }
    };

    private preparePayload = (addBusinesskeys: boolean) => {
        let fields: { [property: string]: string }[] = [];
        let businessKeys: { [property: string]: string } = {};

        for (const [key, fld] of Object.entries(this.editRecord.fields)) {
            if (fld?.value !== undefined) {
                fields.push({ id: key, value: fld?.value });
                if (addBusinesskeys && fld.primaryKey) {
                    businessKeys[key] = fld?.value;
                }
            }
        }
        let payload = {};
        payload['fields'] = fields;
        if (addBusinesskeys) {
            payload['businessKey'] = businessKeys;
        }
        return payload;
    };

    private addMasterData = (payload) => {
        this.store$.dispatch(CreateRecord({ data: payload }));
    };

    public backToTableView = () => {
        this.updateRecord = false;
    };

    public checkedChanged = (event) => {
        this.checkedList = event;
    };

    private onRecordEdit = (event) => {
        this.onRecordSelect(event);
    };

    public onRecordSelect = (event) => {
        this.unassignMaster = false;
        this.updateRecord = true;
        const record = this.records.find((item) => item.recordId === event.recordId);
        this.addMaster(record);
    };

    private onDeleteSelectedRecord = (event) => {
        this.unassignMaster = true;
        this.updateRecord = false;
        const selectedRecords = [];
        selectedRecords.push(event);
        if (selectedRecords?.length > 0) {
            this.deleteMaster(selectedRecords);
        }
    };

    public checkFieldType = (fieldId) => {
        return typeof this.editRecord?.fields[fieldId] !== 'function';
    };

    public toggleSliders = (actionName?: string) => {
        const drawer = this.matDrawer;
        if (drawer.opened) {
            this.openDrawer = false;
            this.unassignMaster = false;
            this.downloadMasterFlag = false;
            this.uploadMasterFlag = false;
            this.isUploadedFiles = false;
            this.parentRecord = false;
            this.masterDataMap = '';
            this.masterDataOutboundMap = '';
            this.checkedList = [];
            this.synchronizeFlag = false;
            this.notificationFlag = false;
            this.showRequests = false;
            this.editRecord = new MasterRecord(undefined, undefined);
        } else {
            this.isFromRequests = false;
            this.openDrawer = true;
        }
        if (actionName && actionName === 'requests' && this.needToRefresh) {
            this.needToRefresh = false;
            this.startRecordVariables();
        }
        drawer.toggle();
        if (!drawer.opened) {
            this.record = undefined;
            this.transformationMappings = undefined;
        }
    };

    public recordSelectedEvent = (data: TableData) => {
        this.tableData?.value.filter((record) => record.recordId === data.recordId).forEach((record) => (record.selected = data.selected));
        this.selectedRecordsCount = this.tableData?.value.filter((record) => record.selected).length;
    };

    private indexMaster = () => {
        const confirmConfig = {
            title: this.translateMsg('Confirmation'),
            message: this.translateMsg('Indexing is time taking process, do you still want to continue?'),
            btnOkText: this.translateMsg('Proceed'),
            btnCancelText: this.translateMsg('Cancel'),
        };
        this.confirmationService
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText)
            .subscribe((action) => {
                if (action) {
                    const data = {
                        orgId: this.currentOrgId,
                        restApiName: this.selectedMasterTable.restApiServiceName,
                    };
                    this._api.subscriptions.indexAppOrMasterData(data, {
                        successCallback: (res) => {
                            this._libUtils.alertSuccess(res?.msg || this.translateMsg('Master Data Indexed successfully'));
                        },
                        failureCallback: (res) => {
                            this._libUtils.alertError(res?.msg || this.translateMsg('Failed to process'));
                        },
                    });
                }
            });
    };

    private notification = () => {
        this.notificationFlag = true;
        this.toggleSliders();
    };

    private getCustomFilter = () => {
        this.store$.dispatch(GetCustomFilters());
    };

    private deleteMaster = (records) => {
        const confirmConfig = {
            title: this.translateMsg('Delete Confirmation'),
            message: this.translateMsg('Are you sure you want to delete?'),
            btnOkText: this.translateMsg('Ok'),
            btnCancelText: this.translateMsg('Cancel'),
        };
        this.confirmationService
            .confirm(confirmConfig.title, confirmConfig.message, confirmConfig.btnOkText, confirmConfig.btnCancelText)
            .subscribe((action) => {
                if (action) {
                    const colummnArray = [];
                    const recordIds = [];
                    if (records.length !== 0) {
                        records.forEach((each) => {
                            const obj = {};
                            [...(this.masterDatakeyColumns || []), ...(this.parentKeyColumnIds || [])].forEach((key) => {
                                obj[key] = each[key];
                            });
                            recordIds.push(each?.recordId);
                            colummnArray.push(obj);
                        });
                    }
                    this.store$.dispatch(DeleteMasterRecords({ data: colummnArray, selectedRecordIds: recordIds }));
                }
            });
    };

    private downloadMaster = () => {
        this.outboundTransformationSourceFile = false;
        this.loadOutboundMappings();
    };

    private loadOutboundMappings = () => {
        this.isFromDownload = true;
        this.store$.dispatch(GetMastersOutboundMappings({ masterTableId: this.selectedMasterTable.itemId }));
        this.store$.dispatch(GetMasterChainDisplayNames({ restAPIName: this.masterRestApiName }));
    };

    public getReportDetails = (isFromGenerateIcon?, isFromRefresh?) => {
        this.isFromGenerate = isFromGenerateIcon;
        this.isFromRefresh = isFromRefresh;
        this.store$.dispatch(
            GetReportDetails({
                tableId: this.selectedMasterTable.itemId,
                isFromGenerate: this.isFromGenerate,
            })
        );
    };

    public selectedOutboundTransformation = (model) => {
        this.downloadMasterReports = false;
        this.showRefreshIcon = false;
        this.reportCallCount = 0;
        if (model === '') {
            this.outboundTransformationSourceFile = false;
            return;
        } else {
            if (this.masterDownloads[this.outboundMappings[this.masterDataOutboundMap].name] !== undefined) {
                const allChains = this.masterDownloads[this.outboundMappings[this.masterDataOutboundMap].name];
                allChains.forEach((element) => {
                    if (element.chainName === model) {
                        this.downloadMasterReports = true;
                        this.downloadReportName = element.reportName;
                    }
                });
            }
            this.outboundTransformationSourceFile = true;
            this.masterDataOutboundMap = model;
        }
    };

    public downloadOutboundFile = (transformationName) => {
        const url = this.taxilla_api + '/masters/v1/' + this.masterRestApiName + '/reports/' + encodeURIComponent(transformationName);
        window.location.href = url;
    };

    public generateReport = (transformationName) => {
        const data = {
            transformationName,
            restApiName: this.masterRestApiName,
        };
        this.store$.dispatch(GenerateMasterReport({ data }));
    };

    private masterDataUpload = () => {
        this.mastersUploadData = {
            filename: '',
            url: '',
            title: this.translateMsg('Browse'),
            return: true,
        };
        this.fileName = translate('No file chosen');
        this.clearFiles.next('clear');
        this.transformationSourceFile = false;
        this.loadMappings();
        this.isUpload = true;
    };

    private loadMappings = () => {
        this.store$.dispatch(GetMastersInboundMappings({ masterTableId: this.selectedMasterTable.itemId }));
        this.store$.dispatch(GetMasterChainDisplayNames({ restAPIName: this.masterRestApiName }));
    };

    public selectedTransformation = (model) => {
        if (!model) {
            this.masterDataMap = '';
            this.mapingSelected = false;
        } else {
            this.masterDataMap = model;
            this.transformationName = model;
            this.transformationSourceFile = true;
            this.mapingSelected = true;
        }
    };

    public getSourceFile = (selectedTransformation, sourcePart) => {
        let url = this.taxilla_api + '/user-template/download';
        url =
            url +
            '?repositoryId=' +
            this.transformationRepositoryId +
            '&metadataId=' +
            this.selectedMasterTable.itemId +
            '&transformationName=' +
            selectedTransformation +
            '&sourceName=' +
            sourcePart.name;
        window.location.href = url;
    };

    public uploadMasterData = (transformationName) => {
        if (this.checkfileFormat(this.files, transformationName)) {
            const data = {
                filename: this.mappings[transformationName].sourceParts[0].name,
            };
            this.fileupload.upload(
                this.files,
                data,
                this.mastersUploadData.url,
                () => {},
                () => {
                    this.clearFiles.next('clearFiles');
                    this.files = undefined;
                    this.mastersUploadData = {
                        filename: '',
                        url: '',
                        title: 'Import',
                        return: true,
                    };
                    this._libUtils.alertSuccess(this.translateMsg('File upload successfully'));
                    this.needToRefresh = true;
                    this.uploadMasterFlag = false;
                    this.resetDateFilter();
                    this.resetRequestsData();
                    this.store$.dispatch(
                        SetMasterRecords({
                            records: [],
                            organizationId: this.currentOrgId,
                            restApiName: this.masterRestApiName,
                            isClearSearch: true,
                        })
                    );
                    this.fetchRequests();
                },
                (res) => {
                    this._libUtils.alertError(res.msg);
                    this.toggleSliders();
                }
            );
        } else {
            return;
        }
    };

    private checkfileFormat = (files, transformationName) => {
        let flag = true;
        if (files === undefined || files === null || transformationName === undefined) {
            this._libUtils.alertError(this.translateMsg('Please upload a file'));
            flag = false;
            return flag;
        }
        files.forEach((file) => {
            if (this.mappings[transformationName].sourceParts[0].fileExtension !== undefined) {
                const splitFile = this.mappings[transformationName].sourceParts[0].fileExtension.split('.');
                const permittedFileExtension = splitFile[splitFile.length - 1].toLowerCase();
                const uploadedFileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
                if (uploadedFileExtension === 'zip') {
                    flag = true;
                    return flag;
                } else if (uploadedFileExtension !== permittedFileExtension) {
                    const errorMsg =
                        this.translateMsg('Please select') +
                        ' ' +
                        permittedFileExtension +
                        ' ' +
                        this.translateMsg('file format to upload');
                    this._libUtils.alertError(errorMsg);
                    flag = false;
                    return flag;
                }
            } else {
                flag = true;
                return flag;
            }
        });
        return flag;
    };

    private getUploadedfiles = () => {
        this.isFromUplodedFiles = true;
        this.store$.dispatch(GetMasterUploadFiles());
    };

    private createTableColumns = () => {
        this.updateTableData(this.checkSumDetails);
    };

    private updateTableData = (details) => {
        this.fileDetailstableData.next(details);
    };

    public onDownloadToggle = (downloadElement) => {
        const splitFileUri = downloadElement.fileUri.split('/');
        const url =
            this.taxilla_api +
            '/process/v1/files?fileUrl=' +
            encodeURIComponent(downloadElement.fileUri) +
            '&fileName=' +
            encodeURIComponent(splitFileUri[splitFileUri.length - 1]);
        window.location.href = url;
    };

    public uploadMaster = () => {
        this.mastersUploadData.filename = this.fileName;
        this.mastersUploadData.url = `${this.taxilla_api}/masters/v1/${this.masterMetaData.masterItem.restApiServiceName}?transformationName=${this.transformationName}`;
        this.mastersUploadData.title = 'Browse';
    };

    private updateCurrentConfig = (config) => {
        const ftp = {
            triggerJob: true,
            cronExpression: '0 */20 * * * *',
            configId: '',
            transformations: {},
            type: 'FTP',
        };

        if (!this._utils.isEmpty(config)) {
            if (config.configuredData === undefined) {
                if (this.selectedMethod === 'FTP') {
                    config['configuredData'] = JSON.stringify(ftp);
                }
            }
        }
        return config;
    };

    private setConfigRecords = (methodName?) => {
        let currentConfig = {
            integrationMethod: methodName,
            subOrganizationId: this.currentOrgId,
        };
        this.selectedMethod = methodName;
        if (methodName !== '') {
            if (this.existingConfiguration.length !== 0) {
                this.existingConfiguration.forEach((config) => {
                    if (config.integrationMethod === methodName) {
                        currentConfig = this.updateExistingConfiguration(config);
                    }
                });
            }
        }
        currentConfig = this.updateCurrentConfig(currentConfig);
        this.deleteIntegrationSettings(currentConfig as any);
        this.configRecord = new AppIntegrationRecord((currentConfig as any) || {}, this.config);
    };

    private updateExistingConfiguration = (config) => {
        let existConfig = JSON.parse(config['configuredData']);
        existConfig.type = existConfig.type === 'FTPSettings' || existConfig.type === 'FTP' ? 'FTP' : 'WEBSERVICE';
        existConfig = JSON.stringify(existConfig);
        config['configuredData'] = existConfig;
        return config;
    };

    private getExistingDetails = () => {
        this.synchronizeFlag = true;
        if (!this.matDrawer.opened) {
            this.toggleSliders();
        }
        this.synchronizeFlag = true;
        this.store$.dispatch(GetAppIntegration({ integrationType: this.config.api, serviceId: this.selectedMasterTable?.itemId }));
    };

    private validateRecord = (record?: AppIntegrationRecord, callback?: (...args: any) => void): boolean => {
        const result = this._integrationUtils.isFormValid((record as any) || this.record);
        if (!result) {
            this._libUtils.alertError(this.translateMsg('Fix issues to proceed'));
        }
        callback && callback();
        return result;
    };

    private masterSynchronize = () => {
        this.unassignMaster = false;
        this.getExistingDetails();
    };

    private navigatTo = (master) => {
        this.selectedMasterTable = master;
        if (this.isFromEnComply) {
            this._router.navigate([
                'organizations',
                this.currentOrgId,
                'settings',
                'masterManagement',
                master.itemId,
                master.restApiServiceName,
            ]);
        } else {
            this.store$.dispatch(
                SetMasterTable({
                    tableId: master.itemId,
                    restApiName: master.restApiServiceName,
                })
            );
        }
    };

    public actionMaster = (from) => {
        this.downloadMasterFlag = false;
        this.needToRefresh = false;
        switch (from) {
            case 'back':
                this.selectedMasterTable = {};
                const url = `/organizations/${this.currentOrgId}/settings/masterManagement`;
                this._utils.setRoute(url);
                this.editRecord = new MasterRecord(undefined, undefined);
                break;
            case 'add':
                this.updateRecord = false;
                this.unassignMaster = false;
                this.parentRecord = this.parentPrimaryKeyColumns.length !== 0;
                this.addMaster();
                if (this.parentPrimaryKeyColumns.length !== 0) {
                    this.parentRecord = true;
                    this.parentPrimaryKeyColumns.forEach((column) => {
                        this.editRecord.setField(column.id, {}, column);
                    });
                }
                break;
            case 'edit':
                this.unassignMaster = false;
                this.updateRecord = true;
                const selectedRecord = this.tableData.value?.find((record) => record.selected);
                const record = this.records.find((item) => item.recordId === selectedRecord.recordId);
                this.addMaster(record);
                break;
            case 'delete':
                this.unassignMaster = true;
                this.updateRecord = false;
                const selectedRecords = this.tableData.value?.filter((record) => record.selected === true);
                if (selectedRecords?.length > 0) {
                    this.deleteMaster(selectedRecords);
                } else {
                    this._utils.alertError(this.translateMsg('Please select records to delete'));
                }
                break;
            case 'upload':
                this.masterDataUpload();
                break;
            case 'download':
                this.downloadMaster();
                break;
            case 'uploadedfiles':
                this.getUploadedfiles();
                break;
            case 'synchronize':
                this.masterSynchronize();
                break;
            case 'search':
                this.handleSearchMdClick();
                break;
            case 'index':
                this.indexMaster();
                break;
            case 'clearsearch':
                this.clearSearch();
                break;
            case 'notification':
                this.notification();
                break;
            case 'requests':
                this.fetchRequests();
                break;
            case 'refresh':
                this.resetMasterRecords();
                this.startRecordVariables();
                break;
            default:
                this.selectedMasterTable = {};
                this._router.navigate(['organizations', this.currentOrgId, 'settings', 'masterManagement']);
                break;
        }
    };

    private fetchRequests = (timeRange?) => {
        this.showRequests = true;
        this.setMasterRequestsTableData(timeRange);
        this.getMasterRequests();
    };

    public downloadFile = (record) => {
        const payload = {
            restApiName: this.masterRestApiName,
            resourceId: record.requestId,
        };
        this._api.requests
            .downloadNewUploadedFiles(payload)
            .then((filesDataArray) => {
                filesDataArray.forEach((element) => {
                    if (element.fileUri.indexOf(record.downloadFile) !== -1) {
                        const splitFileUri = element.fileUri.split('/');
                        const url =
                            this.taxilla_api +
                            '/process/v1/files?fileUrl=' +
                            encodeURIComponent(element.fileUri) +
                            '&fileName=' +
                            encodeURIComponent(splitFileUri[splitFileUri.length - 1]);
                        window.open(url, 'target = "_blank"');
                    }
                });
            })
            .catch((res) => {
                this._libUtils.alertError(res?.msg || this.translateMsg('Failed to get uploaded files'));
            });
    };

    private getMasterRequests = () => {
        this.isFromRequests = true;
        let pageIndex = this.requestsPagination?.pageIndex - 1 < 0 ? 0 : this.requestsPagination?.pageIndex - 1;
        this.store$.dispatch(
            SetMasterRequestsPagination({
                pageSize: this.requestsPagination.size,
                pageIndex: this.requestsPagination.pageIndex,
                pageState: this.requestsPagination.page[pageIndex] || '',
                organizationId: this.currentOrgId,
                restApiName: this.masterRestApiName,
                timeRange: this.requestsPagination?.timeRange,
                isUpdate: false,
            })
        );
        this.store$.dispatch(
            GetMasterRequests({
                restApiName: this.masterRestApiName,
                size: this.requestsPagination.size,
                pageIndex: this.requestsPagination.pageIndex,
                previousPagingState:
                    this.requestsPagination.page[this.requestsPagination.pageIndex - 1] !== undefined
                        ? this.requestsPagination.page[this.requestsPagination.pageIndex - 1]
                        : '',
                timeRange: this.requestsPagination?.timeRange,
            })
        );
    };

    private updateRequestTableData = (requests) => {
        this.requeststableData.next(requests);
    };

    private updateRequestPaginationData = () => {
        const recordsData = this.masterRequests;
        if (!recordsData) {
            return;
        }
        const recordsSize = recordsData?.length;
        const pagination = this.requestsPagination;
        const pages = Object.keys(pagination.page || {});
        let nextPageExists = false;
        const currentPage = pagination.page[pagination.pageIndex];
        if (pagination.pageIndex + 1 === pages.length) {
            if (currentPage !== undefined) {
                if (Array.isArray(currentPage) && currentPage.length > 0) {
                    nextPageExists = true;
                } else if (typeof currentPage === 'string' && currentPage.length > 0) {
                    nextPageExists = true;
                }
            }
        } else if (pagination.pageIndex + 1 < pages.length) {
            nextPageExists = true;
        }
        const isLatestPage = pages.length - 1 === pagination.pageIndex;
        const incrementBy = isLatestPage && nextPageExists ? 1 : 0;
        let count = 0;
        if (pagination.pageIndex + 1 < pages.length) {
            count = this.requestsPagination.count;
        } else {
            count = recordsSize + (pagination.pageIndex || 0) * (pagination.size || 0) + incrementBy;
        }
        this.requestsPagination.count = count;
    };

    private setMasterRequestsTableData = (timeRange) => {
        this.requestsPagination = {
            size: this.requestsPagination?.size || 10,
            pagingState: undefined,
            count: 0,
            pageIndex: 0,
            page: {},
            pages: [],
            disableNext: true,
            timeRange: timeRange,
        };
        this.masterRequestrecordActions = [
            {
                displayName: 'Notifications',
                iconName: 'notifications',
                onActionTrigger: this.fetchMasterRequestNotifications,
            },
        ];
    };

    public fetchMasterRequestsInPage = (pageIndex: number | 'next' | 'previous') => {
        let index: number;
        if (typeof pageIndex === 'number') {
            index = pageIndex as number;
        } else if (typeof pageIndex === 'string') {
            switch (pageIndex) {
                case 'next':
                    index = this.requestsPagination.pageIndex + 1;
                    break;
                case 'previous':
                    index = this.requestsPagination.pageIndex - 1;
                    break;
            }
        }
        this.requestsPagination.pageIndex = index;
        this.getMasterRequests();
    };

    public refreshMasteritemsWithNewPageSize = (data: any) => {
        this.resetRequestPagination();
        this.resetRequestsData();
        this.requestsPagination.size = data?.pageSize;
        this.fetchRequests(this.requestsPagination.timeRange);
    };

    private fetchMasterRequestNotifications = (event) => {
        this.dialog.open(MasterRequestsNotificationsComponent, {
            panelClass: ['eventLogNotifications', 'matDialogContainer'],
            disableClose: true,
            data: {
                resourceId: event.requestId,
                createdRequestDate: event.createdDate,
            },
        });
    };

    private resetRequestsData = () => {
        this.store$.dispatch(
            SetMasterRequests({
                requests: [],
                organizationId: this.currentOrgId,
                restApiName: this.masterRestApiName,
                isClearSearch: true,
            })
        );
    };

    private resetRequestPagination = () => {
        this.requestsPagination = {
            pagingState: '',
            size: 10,
            count: 0,
            pageIndex: 0,
            page: {},
            pages: [],
            disableNext: true,
            timeRange: null,
        };
    };

    public searchByTimeRange = (timeRange) => {
        this.resetRequestPagination();
        this.resetRequestsData();
        this.searchMode = true;
        this.fetchRequests(timeRange);
    };

    public clearAllFilters = () => {
        this.resetRequestPagination();
        this.resetRequestsData();
        this.resetDateFilter();
        this.fetchRequests();
        this._broadcaster.broadcast('resetDateFilter');
    };

    private resetDateFilter = () => {
        this.searchMode = false;
        this.timeFilter = {
            startTime: null,
            startDate: null,
            endTime: null,
            endDate: null,
        };
    };

    private clearSearch = () => {
        this.defaultFilter = undefined;
        this.validationFilter = undefined;
        this.searchQuery = undefined;
        this.sortQuery = undefined;
        this.selectedMasterFilter = undefined;
        this.getCustomFilter();
        this.resetMasterRecords();
        this.resetSearchCount();
        this.startRecordVariables();
    };

    /**
     * Method that gets rendereded when the user clicks on the 'Search' button in the top bar.
     */
    private buildSearchAndSortConfig = () => {
        const searchObj = {};
        const sortObj = {};
        this.masterData?.forEach((md) => {
            if (md.searchable) {
                this.getElasticSearchObject(md, searchObj);
            }
            if (md.sortable) {
                this.getElasticSortObject(md, sortObj);
            }
        });

        this.searchConfig['fields'] = searchObj;
        this.sortConfig['fields'] = sortObj;
    };

    private handleSearchMdClick = () => {
        this.renderSearchModal();
    };

    private getElasticSearchObject = (fieldMd, contObj) => {
        const dataType = this._utils.getDataType(fieldMd.dataType);
        const id = fieldMd.id;
        if (!id) {
            return;
        }
        contObj[id] = {
            name: fieldMd.displayName || fieldMd.name,
            type: dataType,
            operators: this._utils.getSearchOperatorsPerDataType(dataType),
            nullable: true,
        };
        switch (dataType) {
            case 'number':
                contObj[id]['minimum'] = 0;
                break;
            case 'category':
                contObj[id]['choices'] = ['SUCCESS', 'ERROR'];
                break;
            case 'date':
                contObj[id]['defaultValue'] = new Date();
                contObj[id]['format'] = fieldMd.dateFormat || 'dd-mm-yyyy';
                break;
            case 'boolean':
                contObj[id]['options'] = [
                    { name: 'true', value: 'true' },
                    { name: 'false', value: 'false' },
                ];
                break;
        }
    };

    private getElasticSortObject = (fieldMd, contObj) => {
        if (!fieldMd || !fieldMd.id) {
            return;
        }
        contObj[fieldMd.id] = {
            name: fieldMd.displayName || fieldMd.name,
            type: 'category',
            operators: ['='],
            nullable: true,
            options: [
                {
                    name: translate('Ascending'),
                    value: 'ASC',
                },
                {
                    name: translate('Descending'),
                    value: 'DESC',
                },
            ],
        };
    };

    private searchQueryChangeListener = (query) => {
        this.searchQuery = query;
    };

    private sortQueryChangeListener = (query) => {
        this.sortQuery = query;
    };

    private renderSearchModal = () => {
        let searchQueryStringRef;
        let sortQueryStringRef;
        let searchCriteriaObj;
        let isFieldsAvailable = true;
        if (!(this.searchQuery || this.sortQuery) && this.defaultFilter) {
            searchCriteriaObj = JSON.parse(this.defaultFilter.criteria);
            searchQueryStringRef = searchCriteriaObj.searchQueryString;
            if (!searchQueryStringRef && searchCriteriaObj.query) {
                searchQueryStringRef = {
                    bool: {
                        must: searchCriteriaObj['query'],
                    },
                };
            }
            sortQueryStringRef = searchCriteriaObj.sortQueryString;
            this._esService.buildQueryObj(searchQueryStringRef);
            this._esService.buildSortQueryObject(sortQueryStringRef);
        }
        if (!Object.keys(this.searchConfig['fields']).length) {
            isFieldsAvailable = false;
        }
        this.searchModalRef = this.dialog.open(MastersearchComponent, {
            panelClass: ['elasticSearchModal', 'matDialogContainer'],
            disableClose: true,
        });
        this.searchModalRef.componentInstance.title = this.translateMsg('Search Master Records');
        this.searchModalRef.componentInstance.searchConfig = this.searchConfig;
        this.searchModalRef.componentInstance.sortConfig = this.sortConfig;
        this.searchModalRef.componentInstance.canShowSortQuery = Object.keys(this.sortConfig.fields).length > 0;
        this.searchModalRef.componentInstance.isFromMaster = true;
        this.searchModalRef.componentInstance.searchFilters = this.searchFilters;
        this.searchModalRef.componentInstance.defaultFilter = this.defaultFilter;
        this.searchModalRef.componentInstance.validationFilter = this.validationFilter;
        this.searchModalRef.componentInstance.selectedOutlookFilter = this.selectedMasterFilter || 'validationStatus';
        this.searchModalRef.componentInstance.isFieldsAvailable = isFieldsAvailable;
        this.searchModalRef.componentInstance.size = this.pagination.size;
        this.searchModalRef.componentInstance.searchQuery = this.searchQuery;
        this.searchModalRef.componentInstance.sortQuery = this.sortQuery;
        this.searchModalRef.componentInstance.onDataFetched = this.onDataFetched;
        this.searchModalRef.componentInstance.searchQueryChangeListener = this.searchQueryChangeListener;
        this.searchModalRef.componentInstance.sortQueryChangeListener = this.sortQueryChangeListener;
        this.searchModalRef.componentInstance.updateDefaultFilter = this.updateDefaultFilter;
        this.searchModalRef.componentInstance.updateValidationFilter = this.updateValidationFilter;
        this.searchModalRef.componentInstance.getCustomFilter = this.getCustomFilter;
        this.searchModalRef.componentInstance.updatePaginationCount = this.updatePaginationCount;
        this.searchModalRef.componentInstance.clearSearch = this.clearSearch;
        this.searchModalRef.componentInstance.updateSelectedMasterFilter = this.updateSelectedMasterFilter;
        this.searchModalRef.componentInstance.resetPagination = this.resetPagination;
    };

    private resetPagination = () => {
        this.pagination = {
            size: 10,
            pagingState: '',
            count: 0,
            pageIndex: 0,
            page: {},
            pages: [],
            disableNext: true,
        };
        this.pagination.pages[this.pagination.pageIndex] = this.pagination.pageIndex + 1;
    };

    private updateSelectedMasterFilter = (filter) => {
        this.selectedMasterFilter = filter;
    };

    private updateDefaultFilter = (filter) => {
        this.defaultFilter = filter;
    };

    private updateValidationFilter = (filter) => {
        this.validationFilter = filter;
    };

    public submitConfiguration = () => {
        if (this.validateRecord()) {
            const payload = new AppNewIntegrationRecordPayload(this.record, this.config, undefined, undefined, this.selectedMasterTable);
            if (!payload.locationId) {
                payload.locationId = this.currentOrgId;
            }
            this.saveSourceConfiguration(payload);
            if (this.record?.originalData?.serviceId?.length) {
                this.store$.dispatch(
                    DeleteAppIntegrationDetails({
                        configName: this.record?.configName,
                        serviceId: this.selectedMasterTable?.itemId,
                    })
                );
                setTimeout(() => {
                    this.getExistingDetails();
                }, 100);
            }
        }
    };

    private saveSourceConfiguration = (payload: AppNewIntegrationRecordPayload) => {
        this._integrationUtils.saveAppIntegrationResourceConfiguration(payload).then((res) => {
            this.record.originalData = res?.response;
        });
    };

    public deleteConfiguration = (event?) => {
        const currentRecord: AppIntegrationRecord = event?.originalRecordData || this.record;
        this._integrationUtils.deleteConfiguration(currentRecord).then(() => {
            this.store$.dispatch(
                DeleteAppIntegrationDetails({
                    configName: currentRecord?.configName,
                    serviceId: this.selectedMasterTable?.itemId,
                })
            );
            this.getExistingDetails();
        });
    };

    public initiateTrigger = (record?: AppIntegrationRecord) => {
        this._integrationUtils.triggerAppResourceIntegrationConfiguration(record || this.record, this.config);
    };

    public canShowImmediateTrigger = (record?: AppIntegrationRecord) => {
        record = record || this.record;
        if (record?.originalData?.locationId) {
            const triggerJob = record.getRecordField('triggerJob');
            if (triggerJob.value === false) {
                return true;
            }
        }
        return false;
    };

    public hideSubSection = (subSection?: any, record?: any, subSectionRecord?: any) => {
        return this._integrationUtils.hideSubSection(subSection, record, subSectionRecord);
    };

    public onMasterSelect = (name) => {
        this.matMasterMenuTrigger?.closeMenu();
        let selectedMaster = this.allMasters?.find((element) => element?.itemName === name);
        this.navigatTo(selectedMaster);
    };

    public docListTrakBy = (index) => index;

    public clearMasterMenuSearch = () => {
        this.masterSearchText = '';
    };

    public focusSearchField = () => {
        this._masterSearchInput?.nativeElement.focus();
    };

    private getRecordChangeLog = (data) => {
        this.selectedChangelogRecord = data;
        this.changeLogs = [];
        const record = this.records.find((record) => record.recordId === this.selectedChangelogRecord?.recordId);
        if (record) {
            let changeLogFields = this.masterData
                .filter((field) => !field.primaryKey)
                .reduce((fields, field) => {
                    fields.push(field.id);
                    return fields;
                }, []);
            const keyvalues = Object.keys(record.fields)
                .filter((id) => !['errorCount', 'recordId'].includes(id))
                .filter((id) => !changeLogFields.includes(id))
                .reduce((keysMap, id) => {
                    keysMap[id] = record.fields[id];
                    return keysMap;
                }, {});
            this.store$.dispatch(
                GetMasterRecordChangeLogs({
                    keyvalues,
                })
            );
        }
    };

    private getChangelogRecords = (records) => {
        this.changeLogs = [];
        const logs: RecordChangeLog[] = [];
        this.changedFieldIds = [];
        records.forEach((record) => {
            if (this.changedFieldIds.indexOf(record.fieldId) === -1) {
                this.changedFieldIds.push(record.fieldId);
            }
            const logIndex = logs.findIndex((logRecord) => logRecord.modifiedDate === record.modifiedDate);
            let log: RecordChangeLog;
            if (logIndex === -1) {
                log = {
                    modifiedDate: record.modifiedDate,
                    users: [],
                };
                logs.push(log);
            } else {
                log = logs[logIndex];
            }
            const userIndex = log.users.findIndex((userRecord) => userRecord.userName === record.userName);
            let user: RecordUserChangeLog;
            if (userIndex === -1) {
                user = {
                    userName: record.userName,
                    logs: [],
                };
                log.users.push(user);
            } else {
                user = log.users[userIndex];
            }
            user.logs.push(record);
            this.changeLogs = logs;
        });
        this.setRecordChangeLogs();
    };

    private setRecordChangeLogs = () => {
        if (this.changeLogs?.length > 0) {
            let selectedRecordItem;
            for (let i = 0; i < this.records.length; i++) {
                if (this.records[i].recordId === this.selectedChangelogRecord.recordId) {
                    selectedRecordItem = this.records[i];
                    break;
                }
            }
            this.dialog.open(MasterChangeLogComponent, {
                panelClass: ['masterChangeLogModal'],
                disableClose: true,
                data: {
                    changeLogs: this.changeLogs,
                    masterRecord: selectedRecordItem,
                },
            });
        } else {
            this._libUtils.alertNotify(this.translateMsg('Change logs not found. As this might be the first change'));
        }
    };

    public searchRelatedMasters = () => {
        let matOptionsCount = 0;
        matOptionsCount = document.querySelectorAll('.masterDisplayName').length;
        if (matOptionsCount === 0) {
            this.noRelatedMasters = true;
        } else {
            this.noRelatedMasters = false;
        }
    };

    private buildMasterData = (masters: MasterRecord[]) => {
        const tableData: TableData[] = [];
        masters?.forEach((record) => {
            const allColumns = this.allTableColumns.value;
            const recordData: TableData = {
                recordId: record.recordId,
                errorsCount: record?.errors?.length > 0 ? record.errors.length : 0,
            };
            allColumns?.forEach((column) => {
                if (column.id !== 'selectRecord') {
                    const field = record.getFieldByFieldId(column.id);
                    recordData[column.id] = field.value;
                }
            });
            tableData.push(recordData);
        });
        this.tableData.next(tableData);
        let selectedTableData = this.tableData.value.filter((val) => {
            return val?.selected;
        });
        this.selectedRecordsCount = selectedTableData.length;
    };

    public clearMastersPage = () => {
        if (this.isFromEnComply) {
            this.actionMaster('back');
        } else if (this.instanceId || this.isFromEnInvoice) {
            this.onCloseModal.emit();
        }
    };

    public translateMsg = (msg: string): string => translate('' + msg);

    public trackByIndexMethod = (index: number): any => {
        return index;
    };

    private deleteIntegrationSettings = (record: AppIntegrationRecord) => {
        record?.configs?.forEach((config) => {
            delete config['integrationSettings'];
            delete config['method'];
        });
    };

    private prepareParentPrimaryKeyColumns = (keyColumns) => {
        this.parentPrimaryKeyColumns = [];
        keyColumns.forEach((column) => {
            this.parentPrimaryKeyColumns.push(new MasterField(column, true, []));
        });
        this.parentPrimaryKeyColumns
            .filter((column) => this.parentKeyColumnIds.indexOf(column.id) === -1)
            .forEach((column) => {
                this.parentKeyColumnIds.push(column.id);
            });
    };

    private prepareParentDependentActions = () => {
        this.parentPrimaryKeyValues = {};
        this.parentKeyColumnIds.forEach((parentKey, index) => {
            this.parentPrimaryKeyValues[parentKey] = [];
            if (index === 0) {
                this.parentRecords.forEach((record) => {
                    const columns = record.fields;
                    const field = columns.find((item) => item.id === parentKey);
                    const index = field && this.parentPrimaryKeyValues[parentKey].indexOf(field.value);
                    if (index === -1) {
                        this.parentPrimaryKeyValues[parentKey].push(field.value);
                    }
                });
            }
        });
    };

    private onRequestsFetch = (res) => {
        this.masterRequests = [];
        const pagingState = res?.pagingState;
        const responseRecords = res?.requests;
        this.requestsPagination.disableNext = true;
        if (responseRecords?.length !== 0) {
            responseRecords?.forEach((record) => {
                this.masterRequests.push(new MasterRequest(record));
            });
            this.requestsPagination.page[this.requestsPagination.pageIndex] = pagingState;
            this.requestsPagination.pages[this.requestsPagination.pageIndex] = this.requestsPagination.pageIndex + 1;
            if (typeof pagingState === 'string') {
                if (pagingState.length > 0) {
                    this.requestsPagination.disableNext = false;
                }
            }
        }
        this.updateRequestTableData(this.masterRequests);
        this.updateRequestPaginationData();
        this.isFromRequests && !this.matDrawer.opened && this.toggleSliders();
    };

    private initiateSelectedMasterActions() {
        this.buildMasterTableCoulmns();
        this.startRecordVariables();
        this.buildSearchAndSortConfig();
        const parentFieldId = this.parentPrimaryKeyColumns?.[0]?.id;
        const tableFieldId = this.masterMetaData?.getAllColumns()?.[0].id;
        const record = this.mastersTable.value?.[0];
        if (
            record?.recordId?.length > 0 &&
            ((parentFieldId && !record.hasOwnProperty(parentFieldId)) || (tableFieldId && !record.hasOwnProperty(tableFieldId)))
        ) {
            const data: MastersResponseRecord[] = CommonUtilsService.cloneObject(this.recordsResponse);
            this.buildMasterTableData(data);
        }
    }

    public onFocusOut(fieldId) {
        const field = this.editRecord.fields[fieldId];
        this._validations.hasValidMasterRecordFieldData(field);
    }

    private prepareinboundMappings = () => {
        this.transformedMappings = [];
        if (!this._utils.isEmpty(this.mappings) && this.isUpload) {
            for (const key in this.mappings) {
                if (this.mappings.hasOwnProperty(key)) {
                    const element = this.mappings[key];
                    const mapping = new Mapping(element, key, this.chainNamesVsDiplayNames[key]);
                    if (mapping) {
                        this.transformedMappings.push(mapping);
                    }
                }
            }
            this.isUpload = false;
            this.uploadMasterFlag = true;
            this.toggleSliders();
        } else if (this._utils.isEmpty(this.mappings) && this.isUpload) {
            this.isUpload = false;
            this._libUtils.alertError(this.translateMsg('No transformation available for upload'));
        }
    };

    private prepareOutboundMappings = () => {
        this.showRefreshIcon = false;
        if (!this._libUtils.isEmpty(this.outboundMappings)) {
            this.isFromDownload = false;
            this.downloadMasterFlag = true;
            this.isDownloadReport = true;
            this.getReportDetails();
        } else {
            this.isFromDownload = false;
            this._libUtils.alertError(this.translateMsg('There are no reports available'));
        }
    };

    private initiateSubscriptions = () => {
        this.store$
            .select(getCurrentOrganizationId$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((orgId) => {
                this.currentOrgId = orgId;
            });
        this.store$
            .select(getMasters$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((response) => {
                this.allMasters = response?.filter((master) => !this.assetMasterIds || this.assetMasterIds.includes(master?.itemId)) || [];
            });
        this.store$
            .select(getSelectedRestApiName$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((response) => {
                this.masterRestApiName = response;
            });
        this.store$
            .select(getSelectedTableId$)
            .pipe(takeUntil(this.unSubscribe))
            .subscribe((masterId) => {
                this.routeMasterId = masterId;
                this.selectedRecordsCount = 0;
                if (this.routeMasterId?.length > 0) {
                    this.startSelectedMaster();
                } else {
                    this.selectedMasterTable = {};
                    this.resetValues();
                }
            });
        this.store$
            .select(getMasterMetadata$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this.masterMetaData = CommonUtilsService.cloneObject(res);
                if (this.masterMetaData?.masterItem?.id) {
                    this.masterDatakeyColumns = res.masterMetadata.keyColumns;
                    this.masterDataName = (res.masterMetadata['displayName'] ?? res.masterItem.displayName) || res.masterMetadata.name;
                    this.masterRestApiName = res.masterMetadata.restAPIName;
                    this.masterData = this.updateMandatoryFields(
                        res.masterMetadata.tableDefinition.columnDefinitions,
                        this.masterDatakeyColumns
                    );
                    this.passwordColumns = this.getPasswordFields(res.masterMetadata.tableDefinition.columnDefinitions);
                    this.masterKeyMetaData = this.masterKeymetaData(this.masterData);
                    this.buildPermissions(res?.permissions);
                    let parentTableId = res?.masterMetadata?.parentTableId !== undefined ? res.masterMetadata.parentTableId : '';
                    if (parentTableId.length > 0) {
                        this.parentRestApiName = this.allMasters.find((master) => master.itemId === parentTableId)?.restApiServiceName;
                        this.store$.dispatch(SetParentTableRestApiName({ parentTableRestApiName: this.parentRestApiName }));
                        this.getParentPrimaryKeys(parentTableId);
                    } else {
                        this.store$.dispatch(SetParentTableId({ parentTableId: '' }));
                        this.store$.dispatch(SetParentTableRestApiName({ parentTableRestApiName: '' }));
                        this.initiateSelectedMasterActions();
                    }
                }
            });
        this.store$
            .select(getSearchFilters$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this.searchFilters = res;
            });
        this.store$
            .select(getMasterRecordsPagingState$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined && JSON.stringify(this.pagingStateResponse) !== JSON.stringify(data))
            )
            .subscribe((res) => {
                this.pagingStateResponse = res;
                this.onDataFetched(res?.count, res?.pagingState);
            });
        this.store$
            .select(getMasterRecords$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined && JSON.stringify(this.recordsResponse) !== JSON.stringify(data))
            )
            .subscribe((res) => {
                this.recordsResponse = res;
                this.searchModalRef && this.searchModalRef.close();
                let data: MastersResponseRecord[] = CommonUtilsService.cloneObject(res);
                this.buildMasterTableData(data);
            });
        this.store$
            .select(getSearchedRecordsCount$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this.updatePaginationCount(res);
            });
        this.mastersTable
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((data) => {
                this.buildMasterData(data);
            });
        this.actions.pipe(ofType(CRUDOperationRecord), takeUntil(this.unSubscribe)).subscribe((data) => {
            let msg = '';
            switch (data.actionType) {
                case 'CREATE':
                    this.toggleSliders();
                    msg = this.translateMsg('Record added successfully');
                    break;
                case 'UPDATE':
                    this.toggleSliders();
                    msg = this.translateMsg('Record updated successfully');
                    break;
                case 'DELETE':
                    msg = this.translateMsg('Record(s) deleted successfully');
                    this.resetMasterRecords();
                    this.startRecordVariables();
                    break;
            }
            this.store$.dispatch(AlertSuccess({ message: msg }));
        });
        this.store$
            .select(getParentPrimaryKeys$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                let keyColumns = res?.keyColumns;
                this.parentKeyColumnIds = [];
                keyColumns.length > 0 && this.prepareParentPrimaryKeyColumns(keyColumns);
                if (this.parentKeyColumnIds.length > 0) {
                    this.getParentRecords();
                } else {
                    this.initiateSelectedMasterActions();
                }
            });

        this.store$
            .select(getParentMasterRecords$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this.parentRecords = res;
                this.prepareParentDependentActions();
                this.initiateSelectedMasterActions();
            });
        this.store$
            .select(getMastersInboundMappings$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this.mappings = res?.mappings;
                this.transformationRepositoryId = res?.repositoryId;
                if (this.synchronizeFlag) {
                    let chains = [];
                    this.transformationMappings = res.mappings || {};
                    const field = this.record.getRecordField('chainName');
                    if (field) {
                        field.options.splice(0);
                        const chainObjects = res?.mappings || {};
                        Object.keys(chainObjects).forEach((chainKey) => {
                            chains.push({
                                chainName: chainObjects[chainKey].name,
                                id: chainObjects[chainKey].name,
                                value: chainObjects[chainKey].name,
                            });
                        });
                        (field.options as any).push({
                            orgName: this.organization.name,
                            repositoryId: this.organization.id,
                            chains: chains,
                        });
                    }
                }
            });
        this.store$
            .select(getMastersOutboundMappings$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this.outboundMappings = res?.mappings;
            });
        this.store$
            .select(getMasterchainDisplayNames$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this.chainNamesVsDiplayNames = res;
                setTimeout(() => {
                    this.isUpload && this.prepareinboundMappings();
                    this.isFromDownload && this.prepareOutboundMappings();
                }, 1000);
            });
        this.store$
            .select(getMasterRequests$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                this.onRequestsFetch(res);
            });

        this.store$
            .select(getReportDetails$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                if (this._utils.isEmpty(res) && this.isFromGenerate) {
                    if (this.reportCallCount < 5) {
                        this._libUtils.showLoading();
                        this._libUtils.hideLoading();
                        this.reportCallCount = this.reportCallCount + 1;
                        this.getReportDetails(this.isFromGenerate);
                    } else {
                        this.reportCallCount = 0;
                        this.showRefreshIcon = true;
                        this._libUtils.alertSuccess(
                            this.translateMsg('Report generation is in progress. Please click on refresh to get generated report')
                        );
                    }
                } else {
                    this.reportCallCount = 0;
                    this.masterDownloads = res;
                    if (this.isFromGenerate || this.isFromRefresh) {
                        if (this.masterDownloads[this.outboundMappings[this.masterDataOutboundMap]?.name] !== undefined) {
                            const allChains = this.masterDownloads[this.outboundMappings[this.masterDataOutboundMap]?.name];
                            allChains.forEach((element) => {
                                if (element.chainName === this.masterDataOutboundMap) {
                                    this.downloadReportName = element?.reportName;
                                    this.downloadMasterReports = true;
                                }
                            });
                        }
                    }
                    if (this.matDrawer && !this._utils.isEmpty(res)) {
                        this.showRefreshIcon = false;
                        if (!this.isDownloadReport) {
                            this._libUtils.alertSuccess(this.translateMsg('Report is generated'));
                        }
                        this.isDownloadReport = false;
                    }
                }
            });
        this.actions.pipe(ofType(GenerateMasterReportSuccess), takeUntil(this.unSubscribe)).subscribe(() => {
            this.getReportDetails(true);
        });
        this.actions.pipe(ofType(GenerateMasterReportFailure), takeUntil(this.unSubscribe)).subscribe((res) => {
            this.getReportDetails();
            setTimeout(() => {
                if (this.masterDownloads) {
                    if (this.masterDownloads[this.outboundMappings[res?.transformationName].name] === undefined) {
                        this._libUtils.alertError(res?.msg);
                    } else {
                        const reportsOfChains = this.masterDownloads[this.outboundMappings[res?.transformationName].name];
                        let chainCount = 0;
                        reportsOfChains.forEach((element) => {
                            if (element.chainName === res?.transformationName) {
                                chainCount++;
                            }
                        });
                        if (chainCount === 0) {
                            this._libUtils.alertError(res?.msg);
                        }
                    }
                }
            }, 2000);
        });
        this.store$
            .select(getMasterUploadFiles$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                if (this.isFromUplodedFiles) {
                    let response = CommonUtilsService.cloneObject(res);
                    if (response?.length === 0) {
                        this.hasChecksum = false;
                        this._libUtils.alertError(this.translateMsg('No Files available'));
                    } else {
                        this.hasChecksum = true;
                        this.checkSumDetails = response;
                        this.createTableColumns();
                        this.isUploadedFiles = true;
                        this.toggleSliders();
                    }
                    this.isFromUplodedFiles = false;
                }
            });

        this.store$
            .select(getAppIntegration$)
            .pipe(
                takeUntil(this.unSubscribe),
                filter((data) => data !== undefined)
            )
            .subscribe((res) => {
                if (this.synchronizeFlag) {
                    let response = CommonUtilsService.cloneObject(res);
                    this.deleteIntegrationSettings(response?.[0] as any);
                    this.record = new AppIntegrationRecord((response?.[0] || {}) as any, this.config);
                    this.store$.dispatch(GetMastersInboundMappings({ masterTableId: this.selectedMasterTable.itemId }));
                }
            });
        this.actions.pipe(ofType(SetMasterRecordChangeLogs), takeUntil(this.unSubscribe)).subscribe((res) => {
            this.getChangelogRecords(res?.changeLogs);
        });
    };

    ngOnInit() {
        this.initiateSubscriptions();
        this.store$.dispatch(GetMasters());
    }

    ngOnDestroy(): void {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.timeout && clearTimeout(this.timeout);
        this.searchModalRef?.close();
    }
}
