import { MasterField, Masters } from 'taxilla-library';

import { CommonUtilsService } from '../services/commonutils/common-utils.service';
import { Field } from './field.class';
import { Message } from './record/message.class';
import { Rule } from './rule.class';

export class FilingAttributeField {
    dataType: string;
    entityId: string;
    fullId: string;
    id: string;
    isBusinessKey: boolean;
    lookupConditionExpressions: any;
    lookupDisplayField: string;
    lookupId: string;
    lookupValueField: string;
    mandatory: boolean;
    name: string;
    displayName: string;
    readOnly: boolean;
    searchable: boolean;
    show: boolean;
    sortable: boolean;
    uiType: string;
    message: Message;
    value: any;
    outputFormat: any;
    rules: Rule[];
    originalUiType: string;
    lookupValues?: any;
    description?: string;
    dateDisplayValue?: string;
    autoCalculate?: boolean;
    hasMasterLookup?: boolean;
    label?: string;
    masterData?: {
        columnName: string;
        columnRefMetadatas: any[];
        tableName: string;
        tableUid: string;
    };
    masterField: MasterField;
    masterSearchEnabled: boolean;

    constructor(field, entityField?: Field, masters?: Masters) {
        this.dataType = field?.dataType;
        this.entityId = field?.entityId;
        this.fullId = field?.fullId;
        this.id = field?.id;
        this.isBusinessKey = field?.isBusinessKey;
        this.lookupConditionExpressions = field?.lookupConditionExpressions;
        this.lookupDisplayField = field?.lookupDisplayField;
        this.lookupId = field?.lookupId;
        this.lookupValueField = field?.lookupValueField;
        this.mandatory = field?.mandatory;
        this.name = entityField?.displayName || entityField?.name || field?.name;
        this.displayName = field?.displayName || entityField?.displayName || entityField?.name || field?.name;
        this.readOnly = field?.readOnly;
        this.searchable = field?.searchable;
        this.show = field?.show;
        this.sortable = field?.sortable;
        this.uiType = field?.uiType || 'TEXT';
        if (field?.dataType === 'boolean') {
            this.uiType = 'BOOLEAN';
        } else if (this.uiType === 'RADIOBUTTON') {
            this.uiType = 'DROPDOWN';
            this.originalUiType = 'RADIOBUTTON';
        }
        if (field?.dataType === 'date' && (!field?.lookupId || !field?.lookupValueField)) {
            this.uiType = 'DATE';
        }
        this.outputFormat = field?.outputFormat;
        this.message = new Message((field?.message !== undefined && field?.message) || {});
        this.value = field?.value !== undefined ? field?.value : field?.defaultValue;
        this.rules = (entityField && CommonUtilsService.cloneObject(entityField.rules)) || [];
        this.lookupValues = (entityField && CommonUtilsService.cloneObject(entityField.lookupValues)) || [];
        this.description = field?.description || entityField?.description;
        this.dateDisplayValue = field?.dateDisplayValue;
        this.autoCalculate = field?.autoCalculate;
        this.label = field?.label;
        this.masterData = field?.masterData;
        this.masterField = field?.masterField || masters?.getTable(field?.masterData?.tableUid)?.getColumn(field?.masterData?.columnName);
        this.hasMasterLookup = field?.hasMasterLookup || this.masterField?.primaryKey;
    }

    setValueInMasterMap = (map: { [property: string]: string }) => {
        if (map && this.entityId && this.id) {
            map[this.entityId + '^' + this.id] = this.value as any;
        }
    };

    buildMasterEntityFieldValueMap = (
        fields: FilingAttributeField[],
        existingValuesMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        map: { [property: string]: string },
        initialRender: boolean,
        fieldReference?: FilingAttributeField
    ) => {
        fields
            .filter((field) => field.masterData?.tableUid && field.show)
            .forEach((field) => {
                field.setValueInMasterMap(map);
            });
        return this.checkMasterEntityFieldValueMap(
            fields,
            CommonUtilsService.cloneObject(existingValuesMap || {}),
            map,
            initialRender,
            fieldReference
        );
    };

    checkMasterEntityFieldValueMap = (
        fields: FilingAttributeField[],
        existingValuesMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        map: { [property: string]: string },
        initialRender: boolean,
        fieldReference: FilingAttributeField
    ) => {
        const masterRefMap = this.getMasterSearchableFieldValues(fields, existingValuesMap);
        Object.keys(existingValuesMap || {}).forEach((tableId) => {
            if (!masterRefMap[tableId]) {
                masterRefMap[tableId] = existingValuesMap[tableId];
            }
        });
        if (fieldReference) {
            this.checkFieldWithFieldReference(fields, map, masterRefMap, initialRender, fieldReference);
        } else {
            fields
                .filter((field) => field.hasMasterLookup && field.show)
                .forEach((field) => {
                    field.checkMasterSearchEnabled(map, masterRefMap, initialRender);
                });
        }
        return masterRefMap;
    };

    private checkFieldWithFieldReference = (
        fields: FilingAttributeField[],
        map: { [property: string]: string },
        masterRefMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        initialRender: boolean,
        fieldReference: FilingAttributeField
    ) => {
        let fieldIndex = -1;
        fieldReference.filterMapWithFieldReference(masterRefMap).valuesMap.forEach((valueMap, index) => {
            let field: FilingAttributeField;
            if (fieldIndex === -1) {
                fieldIndex = valueMap.id === fieldReference.id ? index : -1;
                field = fieldIndex !== -1 ? this.getFieldByFieldId(fields, valueMap.id) : undefined;
                if (field?.hasMasterLookup) {
                    field.checkMasterSearchEnabled(map, masterRefMap, initialRender, field.filterMapWithFieldReference(masterRefMap));
                    fields
                        .filter((checkField) => checkField.hasMasterLookup && checkField.show)
                        .filter((checkField) => checkField.masterData.tableUid !== field.masterData.tableUid)
                        .filter((checkField) =>
                            checkField.masterData.columnRefMetadatas.find((reference) => reference.fieldUid === field.id)
                        )
                        .forEach((checkField) => {
                            checkField.checkMasterSearchEnabled(
                                map,
                                masterRefMap,
                                initialRender,
                                checkField.filterMapWithFieldReference(masterRefMap)
                            );
                            this.checkFieldWithFieldReference(fields, map, masterRefMap, initialRender, checkField);
                        });
                }
            } else {
                const field = this.getFieldByFieldId(fields, valueMap.id);
                field.checkMasterSearchEnabled(map, masterRefMap, initialRender, field.filterMapWithFieldReference(masterRefMap));
                if (field.hasMasterLookup) {
                    fields
                        .filter((checkField) => checkField.hasMasterLookup && checkField.show)
                        .filter((checkField) =>
                            checkField.masterData.columnRefMetadatas.find((reference) => reference.fieldUid === field.id)
                        )
                        .forEach((checkField) => {
                            checkField.checkMasterSearchEnabled(
                                map,
                                masterRefMap,
                                initialRender,
                                checkField.filterMapWithFieldReference(masterRefMap)
                            );
                            this.checkFieldWithFieldReference(fields, map, masterRefMap, initialRender, checkField);
                        });
                }
            }
        });
    };

    getFieldByFieldId = (fields: FilingAttributeField[], fieldId): FilingAttributeField => {
        return fields.find((field) => field.id === fieldId);
    };

    public filterMapWithFieldReference = (masterRefMap: {
        [property: string]: {
            keyColumns: string[];
            valuesMap: {
                id: string;
                entityId: string;
                masterColumn: string;
                value: any;
                references: any[];
            }[];
        };
    }) => {
        const existingMap = CommonUtilsService.cloneObject(masterRefMap || {});
        const field = this;
        var newMap: {
            keyColumns: string[];
            valuesMap: { id: string; entityId: string; masterColumn: string; value: any; references: any[] }[];
        } = {
            keyColumns: [],
            valuesMap: [],
        };
        const currentTableMap = existingMap[field.masterData.tableUid];
        const currentEntityId = field.entityId;
        if (!currentTableMap) {
            return newMap;
        } else {
            currentTableMap.valuesMap
                .filter((ref) => ref.entityId !== currentEntityId)
                .forEach((map) => {
                    this.pushToValuesMap(newMap.valuesMap, map);
                });
            currentTableMap.keyColumns.forEach((column) => {
                column === field.id && this.pushToKeyColumns(newMap.keyColumns, column);
            });
        }
        const currentFieldValueMap = currentTableMap.valuesMap.find((valueMap) => valueMap.id === field.id);
        if (field.hasMasterLookup) {
            // field.masterData.columnRefMetadatas.forEach((reference) => {
            //     this.pushToKeyColumns(newMap.keyColumns, reference.fieldUid);
            //     this.pushToValuesMap(newMap.valuesMap, this.getValueMapFromTablesMap(existingMap, reference.fieldUid));
            // });
            const referenceValueMap = currentTableMap.valuesMap
                .filter((valueMap) => !currentTableMap.keyColumns.includes(valueMap.id))
                .find((valueMap) => valueMap.references?.find((reference) => reference.fieldUid === field.id));
            referenceValueMap?.references.forEach((reference) => {
                this.pushToKeyColumns(newMap.keyColumns, reference.fieldUid);
                !newMap.valuesMap.find((map) => map.id === reference.fieldUid) &&
                    this.pushToValuesMap(newMap.valuesMap, this.getValueMapFromTablesMap(existingMap, reference.fieldUid));
            });
        } else if (currentFieldValueMap.references?.length > 0) {
            // currentFieldValueMap.references.forEach((reference) => {
            //     this.pushToKeyColumns(newMap.keyColumns, reference.fieldUid);
            //     this.pushToValuesMap(newMap.valuesMap, this.getValueMapFromTablesMap(existingMap, reference.fieldUid));
            // });
        }
        currentTableMap.valuesMap.forEach((valueMap) => {
            const hasReference = valueMap.references.some((ref) => ref.fieldUid === field.id);
            (!newMap.valuesMap.find((map) => map.id === valueMap.fieldUid) || valueMap.id === field.id) &&
                hasReference &&
                this.pushToValuesMap(newMap.valuesMap, {
                    ...valueMap,
                    value: valueMap.id === field.id ? field.value : valueMap.value,
                });
        });
        newMap.valuesMap.sort((a, b) => a.references.length - b.references.length);
        newMap.keyColumns.sort((a, b) => {
            const aIndex = newMap.valuesMap.findIndex((mapObject) => mapObject.id === a);
            const bIndex = newMap.valuesMap.findIndex((mapObject) => mapObject.id === b);
            return aIndex - bIndex;
        });
        return newMap;
    };

    checkMasterSearchEnabled = (
        map: { [property: string]: string },
        tablesMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        initialRender: boolean,
        tableMap?: {
            keyColumns: string[];
            valuesMap: {
                id: string;
                masterColumn: string;
                value: any;
                references: any[];
            }[];
        }
    ) => {
        let enabled = true;
        const references = this.masterData.columnRefMetadatas;
        references.forEach((reference) => {
            enabled = enabled && map && map[reference.entityUid + '^' + reference.fieldUid] !== undefined;
        });
        const currentIndexInPair = (tableMap || tablesMap[this.masterData.tableUid]).valuesMap.findIndex((pair) => pair.id === this.id);
        const matchedPair = (tableMap || tablesMap[this.masterData.tableUid]).valuesMap.filter((pair, index) => {
            return (
                ((currentIndexInPair > -1 && index <= currentIndexInPair) || currentIndexInPair === -1) &&
                ((pair.id !== this.id && pair.value !== undefined && typeof pair.value === 'string' && pair.value.toLowerCase() !== 'na') ||
                    pair.id === this.id)
            );
        });
        enabled = enabled && matchedPair.length > currentIndexInPair;
        if (!enabled && !initialRender) {
            this.value = undefined;
        }
        if (initialRender) {
            this.masterSearchEnabled = true;
        } else {
            this.masterSearchEnabled = enabled;
        }
    };

    getMasterSearchableFieldValues = (
        fields: FilingAttributeField[],
        existingMasterRefMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        }
    ) => {
        const masterRefMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        } = {};
        fields
            .filter((field) => field.masterData?.tableUid && field.show)
            .forEach((field) => {
                if (!masterRefMap[field.masterData.tableUid]) {
                    masterRefMap[field.masterData.tableUid] = {
                        keyColumns: [],
                        valuesMap: [],
                    };
                }
                const keyColumns = masterRefMap[field.masterData.tableUid].keyColumns;
                const valuesMap = masterRefMap[field.masterData.tableUid].valuesMap;
                if (field.masterField.primaryKey) {
                    field.masterData.columnRefMetadatas
                        .filter((ref) => ref.entityUid !== field.entityId)
                        .forEach((reference) => {
                            field.pushToKeyColumns(keyColumns, reference.fieldUid);
                            field.pushToValuesMap(valuesMap, field.getValueMapFromTablesMap(existingMasterRefMap, reference.fieldUid));
                        });
                    field.pushToKeyColumns(keyColumns, field.id);
                }
                field.pushToValuesMap(valuesMap, {
                    id: field.id,
                    entityId: field.entityId,
                    masterColumn: field.masterData.columnName,
                    value: field.value,
                    references: field.masterData.columnRefMetadatas,
                });
            });
        return masterRefMap;
    };

    public pushToValuesMap = (
        valuesMap: {
            id: string;
            entityId: string;
            masterColumn: string;
            value: any;
            references: any[];
        }[],
        valueMap: {
            id: string;
            entityId: string;
            masterColumn: string;
            value: any;
            references: any[];
        }
    ) => {
        if (!valueMap) {
            return;
        }
        const mapObject = valuesMap.find((map) => map.id === valueMap.id);
        if (!mapObject) {
            valuesMap.push(valueMap);
        } else if (mapObject.value !== valueMap.value) {
            mapObject.value = valueMap.value;
        }
    };

    public pushToKeyColumns = (keyColumns: string[], column) => {
        column && keyColumns.indexOf(column) === -1 && keyColumns.push(column);
    };

    public readonly getValueMapFromTablesMap = (
        tableMap: {
            [property: string]: {
                keyColumns: string[];
                valuesMap: {
                    id: string;
                    entityId: string;
                    masterColumn: string;
                    value: any;
                    references: any[];
                }[];
            };
        },
        fieldId: string
    ) => {
        let referenceValueMap: {
            id: string;
            entityId: string;
            masterColumn: string;
            value: any;
            references: any[];
        };
        Object.keys(tableMap).forEach((tableId) => {
            const currentTableMap = tableMap[tableId];
            referenceValueMap = referenceValueMap || currentTableMap?.valuesMap?.find((valueMap) => valueMap.id === fieldId);
        });
        return referenceValueMap;
    };
}
