import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { AssetService } from '../../models/assetservice.class';
import { FilingAttributeField } from '../../models/filingattributefield.class';
import { Transformation } from '../../models/transformation';
import { ApiService } from '../../services/api/api.service';
import { CommonUtilsService } from '../../services/commonutils/common-utils.service';
import { NewProcessService } from '../../services/new-process/new-process.service';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
    selector: 'app-lib-consolidate',
    templateUrl: './consolidate.component.html',
    styleUrls: ['./consolidate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsolidateComponent implements OnInit, OnDestroy {
    @Input() apps;
    @Input() selectedRecordIds: string[];
    @Input() sourceApp: AssetService;
    @Input() currentOrganizationId: string;

    @Output() closeConsolidate = new EventEmitter();

    appControl = new UntypedFormControl('', Validators.required);
    subAppControl = new UntypedFormControl('');
    precaptureControl = new UntypedFormControl('', Validators.required);
    transformationControl = new UntypedFormControl('', Validators.required);
    assetToAssetTransformations: {
        orgName: string;
        transformations: Transformation[];
        repositoryId: string;
    }[] = [];
    selectedTransformationName: string;
    selectedAssetToAssetTrnsName: string;
    transformations: any[];
    transformationSources: any[];
    selectedAssetToAssetTransformation: Transformation;
    sourcePartTransformations: any[];
    selectedSourcePartTransformation: Transformation;
    firstFormGroup: UntypedFormGroup;
    secondFormGroup: UntypedFormGroup;
    thirdFormGroup: UntypedFormGroup;
    isOptional = false;
    selectedIndex: number;
    isValidDescription = false;
    subApps: any;
    subAppsPresent = false;
    assetMetaUId: string;
    selectApp: string;
    public filingAttributeFields: FilingAttributeField[];
    chainTransformations: any;
    unsubscribe = new Subject<void>();

    constructor(
        private _api: ApiService,
        private _commonUtils: CommonUtilsService,
        private _utils: UtilsService,
        private _formBuilder: UntypedFormBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _newProcess: NewProcessService
    ) {}

    ngOnInit() {
        this.firstFormGroup = this._formBuilder.group({
            appControl: ['', Validators.required],
        });
        this.secondFormGroup = this._formBuilder.group({
            transformationControl: this.filingAttributeFields?.length > 0 ? ['', Validators.required] : [''],
        });
        this.thirdFormGroup = this._formBuilder.group({
            transformationControl: ['', Validators.required],
        });
    }

    ngOnDestroy(): void {
        this._changeDetectorRef.detach();
    }

    matStepperStepChange = (event: StepperSelectionEvent) => {
        this.selectedIndex = event.selectedIndex;
    };

    onNextClick = async () => {
        await this.consolidateAppChange();
        this.getTransformations();
        this.selectedIndex = this.filingAttributeFields.length > 0 ? 1 : 2;
        this._changeDetectorRef.detectChanges();
    };

    consolidateAppChange = async () => {
        const app = this.subAppControl?.value;
        const bridge = this.appControl?.value;
        /**
         * for bridge based apps, we need to pass bridge as well as bridge associated app
         * for bridge based apps, we need to use that API which accecpts assetId as queryparam in the API
         */
        const attributesResponse = await this._newProcess.buildFilingAttributes(undefined, bridge, app, undefined, undefined);
        this.filingAttributeFields = attributesResponse.filingAttributeFields;
    };

    validateFilingAttributes = async () => {
        const bridge: AssetService =
            (this.appControl.value as AssetService)?.assetType === 'BRIDGE_ASSET' ? this.appControl.value : undefined;
        const app = bridge?.restApiName ? this.subAppControl?.value : this.appControl.value;
        const response = await this._newProcess.validateFilingAttributeFields({
            app: app,
            bridge: bridge,
            filingAttributeFields: this.filingAttributeFields,
        });
        this.selectedIndex = response ? ++this.selectedIndex : this.selectedIndex;
    };

    transformationSelectionChange = () => {
        this.selectedIndex = this.transformationControl.value ? ++this.selectedIndex : 2;
    };

    getOrganizations = (key, relativeOrganizations) => {
        return relativeOrganizations[key];
    };

    getSelectedAssetToAssetTransformation = () => {
        this.transformationSources = [];
        if (this.selectedAssetToAssetTrnsName && this.selectedAssetToAssetTrnsName.length > 0) {
            for (let i = 0; i < this.assetToAssetTransformations.length; i++) {
                for (let j = 0; j < this.assetToAssetTransformations[i].transformations.length; j++) {
                    if (this.selectedAssetToAssetTrnsName === this.assetToAssetTransformations[i].transformations[j].id) {
                        this.selectedAssetToAssetTransformation = this.assetToAssetTransformations[i].transformations[j];
                    }
                }
            }
        }
    };

    getSubApps = ({ assetMetaUId }) => {
        this._api.subscriptions.servicesUnderlyingBridge(assetMetaUId, {
            successCallback: (res) => {
                if (res && !this._utils.isEmpty(res)) {
                    const bridgeNodes = {};
                    Object.keys(res).forEach((id) => {
                        bridgeNodes[id] = res[id];
                    });
                    this.subAppsPresent = true;
                    this.subApps = bridgeNodes;
                } else {
                    this.subAppsPresent = true;
                    this.subApps = [];
                }
            },
            failureCallback: (res) => {
                this._utils.alertError(res.msg || 'Failed to get sub apps');
            },
        });
    };

    resetBridgeStatus = () => {
        this.subAppsPresent = false;
        this.subAppControl.setValue('');
    };

    // trackBy
    trackByFn = (index) => index;

    getTransformations = (callback?) => {
        if (this.appControl.value) {
            if (this.subAppControl.value) this.assetMetaUId = this.subAppControl.value.id;
            else this.assetMetaUId = this.appControl.value.assetMetaUId;
            const cachedTransformations = this.getCachedTransformations(this.assetMetaUId);
            if (!cachedTransformations) {
                this._api.assets
                    .getAllMapTransformations({
                        assetMetaUId: this.assetMetaUId,
                    })
                    .then(async (res) => {
                        this.transformations = [];
                        this.assetToAssetTransformations = [];
                        this.sourcePartTransformations = [];
                        const relativeOrganizations = res.organizations;
                        this.chainTransformations = res.transformations;
                        const organizations = res.transformations;
                        const nameVsDisplayNames = await this.getChainDisplayNames();
                        Object.keys(organizations || {}).forEach((key) => {
                            const transformationObject = {
                                orgName: this.getOrganizations(key, relativeOrganizations),
                                repositoryId: key,
                                transformations: [],
                                assetToAssetTransformations: [],
                            };
                            Object.keys(organizations[key]).forEach((transformationKey) => {
                                const transformation = new Transformation(
                                    organizations[key][transformationKey],
                                    nameVsDisplayNames[transformationKey]
                                );
                                transformation.chainName = transformationKey;
                                if (transformation.assetToAssetMetadata && Object.keys(transformation.assetToAssetMetadata).length > 0) {
                                    transformationObject.assetToAssetTransformations.push(transformation);
                                } else {
                                    transformationObject.transformations.push(transformation);
                                }
                            });
                            if (transformationObject.transformations.length > 0) {
                                this.transformations.push({
                                    orgName: transformationObject.orgName,
                                    transformations: transformationObject.transformations,
                                    repositoryId: transformationObject.repositoryId,
                                });
                            }
                            if (transformationObject.assetToAssetTransformations.length > 0) {
                                this.assetToAssetTransformations.push({
                                    orgName: transformationObject.orgName,
                                    transformations: transformationObject.assetToAssetTransformations,
                                    repositoryId: transformationObject.repositoryId,
                                });
                            }
                            this.sourcePartTransformations = this.getSourcePartTransformations(this.transformations);
                            let transformationsList = this._commonUtils.getFromStorage('transformations');
                            if (transformationsList) {
                                if (transformationsList[this.currentOrganizationId]) {
                                    transformationsList[this.currentOrganizationId][this.assetMetaUId] = {};
                                    transformationsList[this.currentOrganizationId][this.assetMetaUId] = {
                                        transformations: this.transformations,
                                        assetToAssetTransformations: this.assetToAssetTransformations,
                                    };
                                } else {
                                    transformationsList[this.currentOrganizationId] = {};
                                    transformationsList[this.currentOrganizationId][this.assetMetaUId] = {};
                                    transformationsList[this.currentOrganizationId][this.assetMetaUId] = {
                                        transformations: this.transformations,
                                        assetToAssetTransformations: this.assetToAssetTransformations,
                                    };
                                }
                            } else {
                                transformationsList = {};
                                transformationsList[this.currentOrganizationId] = {};
                                transformationsList[this.currentOrganizationId][this.assetMetaUId] = {};
                                transformationsList[this.currentOrganizationId][this.assetMetaUId] = {
                                    transformations: this.transformations,
                                    assetToAssetTransformations: this.assetToAssetTransformations,
                                };
                            }
                            this._commonUtils.setInStorage('transformations', transformationsList);
                        });
                        callback && callback();
                    })
                    .catch(() => {
                        callback && callback();
                    });
            } else {
                setTimeout(() => {
                    this.transformations = cachedTransformations.transformations;
                    this.assetToAssetTransformations = cachedTransformations.assetToAssetTransformations;
                    this.sourcePartTransformations = this.getSourcePartTransformations(this.transformations);
                    callback?.();
                });
            }
        }
    };

    getSourcePartTransformations = (transformations) => {
        const sourcePartTransformations = [];
        transformations.forEach((transformation) => {
            transformation.transformations.forEach((trns) => {
                if (trns.sourceParts && trns.sourceParts.length > 0) {
                    trns.sourceParts.forEach((sourcePart) => {
                        if (sourcePart.sourceType === 'ASSET') {
                            sourcePartTransformations.push(trns);
                        }
                    });
                }
            });
        });
        return sourcePartTransformations;
    };

    getCachedTransformations = (assetId) => {
        const transformations = this._commonUtils.getFromStorage('transformations');
        if (transformations) {
            if (transformations[this.currentOrganizationId]) {
                if (transformations[this.currentOrganizationId][assetId]) {
                    return transformations[this.currentOrganizationId][this.assetMetaUId];
                } else {
                    return undefined;
                }
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    };

    getChainDisplayNames = () => {
        return new Promise((resolve) => {
            const restApiName = this.subAppsPresent ? this.subAppControl.value.restApiName : this.appControl.value.restApiName;
            const storedChainDisplaynames = this.getCachedChainNames(restApiName);
            if (!storedChainDisplaynames) {
                this._api.assets
                    .getChainDisplayNames({
                        restApiName,
                        assetId: this.assetMetaUId,
                    })
                    .then((res) => {
                        let chainVsDisplaynames = this._commonUtils.getFromStorage('chainVsDisplaynames');
                        if (chainVsDisplaynames) {
                            chainVsDisplaynames[restApiName] = res && res.chainNameVsChainDisplayName;
                        } else {
                            chainVsDisplaynames = {};
                            chainVsDisplaynames[restApiName] = res && res.chainNameVsChainDisplayName;
                        }
                        this._commonUtils.setInStorage('chainVsDisplaynames', chainVsDisplaynames);
                        resolve(res && res.chainNameVsChainDisplayName);
                    });
            } else {
                resolve(storedChainDisplaynames);
            }
        });
    };

    getCachedChainNames = (restApiName) => {
        const storedDetails = this._commonUtils.getFromStorage('chainVsDisplaynames');
        if (storedDetails) {
            if (storedDetails[restApiName]) {
                return storedDetails[restApiName];
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    };

    // Note assetDataIds limited to 100. make sure it doenst cross 100
    submitConsolidation = () => {
        const payloadFormData = new FormData();
        const assetDataIds = this.selectedRecordIds;
        const payload = [
            {
                type: 'AssetIdDataSourceType',
                name: `${this.transformationControl.value.sourceParts[0]?.name}`,
                assetDetail: {
                    assetId: `${this.sourceApp.assetMetaUId}`,
                },
                assetDataIds,
            },
        ];
        if (this.filingAttributeFields.length) {
            this.filingAttributeFields
                .filter((field) => !field.autoCalculate && field.value !== undefined)
                .forEach((field) => {
                    if (Array.isArray(field.value)) {
                        field.value.forEach((element) => {
                            payloadFormData.append(field.fullId, element);
                        });
                    } else {
                        let value;
                        if (field.uiType === 'DATE') {
                            value = CommonUtilsService.transformDate(field.value as string, 'dd/mm/yyyy', field.outputFormat as any);
                            payloadFormData.append(field.fullId, value);
                        } else {
                            payloadFormData.append(field.fullId, field.value);
                        }
                    }
                });
        }
        payloadFormData.append('dataSources', JSON.stringify(payload));
        const data = {
            appRestApiName: this.subAppsPresent ? this.subAppControl.value.restApiName : this.appControl.value.restApiName,
            payload: payloadFormData,
            transformationName: this.transformationControl.value.chainName,
        };
        this._api.requests.processConsignorReport(data).subscribe(
            (res: any) => {
                if (res) {
                    this.closeConsolidate.emit();
                    this._utils.alertSuccess(`${res.msg ?? 'Consolidation successfull'}`);
                    this.navigateToTarget();
                }
            },
            (res: any) => {
                this._utils.alertError(`${res.msg ?? 'Consolidation failed'}`);
            }
        );
    };

    navigateToTarget = () => {
        const routerUrl = this._router.url.split('/');
        const isEnreport = routerUrl.includes('enreport');
        const orgId = this._commonUtils.getFromStorage('currentOrganizationId');
        const restApiName = this.subAppsPresent ? this.subAppControl.value.restApiName : this.appControl.value.restApiName;
        if (isEnreport) {
            this._router.navigateByUrl('enreport/organizations/' + orgId + '/apps/' + restApiName + '/processes/state/all', {
                skipLocationChange: false,
            });
        } else {
            this._router.navigate(['eninvoice', 'organizations', orgId, 'apps', restApiName, 'processes']);
        }
    };
}
