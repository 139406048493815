import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { ApiService } from '../api/api.service';
import { CommonUtilsService } from '../commonutils/common-utils.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
    providedIn: 'root',
})
export class CommonNotificationsService {
    translateMsg = (string: string): string => {
        return translate(`${string}`);
    };
    activityMonths = [
        {
            id: 1,
            displayName: 'Jan',
            name: 'Jan',
        },
        {
            id: 2,
            displayName: 'Feb',
            name: 'Feb',
        },
        {
            id: 3,
            displayName: 'Mar',
            name: 'Mar',
        },
        {
            id: 4,
            displayName: 'Apr',
            name: 'Apr',
        },
        {
            id: 5,
            displayName: 'May',
            name: 'May',
        },
        {
            id: 6,
            displayName: 'Jun',
            name: 'Jun',
        },
        {
            id: 7,
            displayName: 'Jul',
            name: 'Jul',
        },
        {
            id: 8,
            displayName: 'Aug',
            name: 'Aug',
        },
        {
            id: 9,
            displayName: 'Sep',
            name: 'Sep',
        },
        {
            id: 10,
            displayName: 'Oct',
            name: 'Oct',
        },
        {
            id: 11,
            displayName: 'Nov',
            name: 'Nov',
        },
        {
            id: 12,
            displayName: 'Dec',
            name: 'Dec',
        },
    ];

    constructor(public _utils: CommonUtilsService, public _taxilla: ApiService, private _libUtils: UtilsService) {}

    activityYears = () => {
        const currentYear = new Date().getFullYear();
        const activityYearsArray = [];
        for (let i = 2016; i < currentYear + 1; i++) {
            const year = {
                id: i,
                displayName: i,
            };
            activityYearsArray.push(year);
        }
        return activityYearsArray;
    };

    getEventDetails = async (model, eType?) => {
        if (model.payload !== undefined) {
            const temp = model.payload;
            const isProcessEvent = temp.type === 'InstanceTaskEvent' || temp.type === 'InstanceEvent';
            temp.transformationName = temp.chainName || temp.transformationName;
            switch (model.eventType) {
                case 'TASK_COMPLETION':
                    if (eType === 'type') {
                        return `${this.translateMsg('Task Completed')}`;
                    } else if (eType === 'masterEvents') {
                        return temp.stageResponse.executionStatus;
                    } else {
                        return (
                            temp.stageResponse.executionStatus +
                            `<span class="d-inline-block">${this.translateMsg('for')}</span>` +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'TASK_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Task Completed')}`;
                    } else if (eType === 'masterEvents') {
                        return this.translateMsg(temp?.stageResponse?.executionStatus || temp?.executionStatus);
                    } else {
                        return `${temp.taskName || 'Task'} <span class="d-inline-block">${
                            temp?.instanceId ? this.translateMsg('completed for process id') : this.translateMsg('completed for request id')
                        }</span>: ${temp?.instanceId ? `${temp.instanceId}` : `${temp.requestId}`}`;
                    }
                case 'REQUEST_DONE_FOR_NOW':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transmission Done For Now')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Inbound Transmission done for id')}</span>` +
                            ':' +
                            ` ${temp.requestId} `
                        );
                    }
                case 'INSTANCE_INDEXING_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Indexing Completed')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Process Indexing Completed for process id')}</span>` +
                            ':' +
                            ` ${temp?.instanceId ? `${temp.instanceId}` : `${temp.requestId}`}`
                        );
                    }
                case 'TRANSFORMATION_COMPLETION':
                    const errorstatus = temp.errorStatus !== undefined ? temp.errorStatus : '';
                    if (eType === 'type') {
                        return `${this.translateMsg('Transformation Completion')}`;
                    } else if (eType === 'masterEvents') {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Transformation completed for transformationName')}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ', ' +
                            errorstatus
                        );
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Transformation completed for transformationName')}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers) +
                            ', ' +
                            errorstatus
                        );
                    }
                case 'TRANSFORMATION_STARTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Transformation Started')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Transformation started for transformationName')}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'TRANSFORMATION_SKIPPED':
                    const skipErrorstatus = temp.errorStatus !== undefined ? temp.errorStatus : '';
                    if (eType === 'type') {
                        return `${this.translateMsg('Transformation Skipped')}`;
                    } else if (eType === 'masterEvents') {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Transformation skipped for transformationName')}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ', ' +
                            skipErrorstatus
                        );
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Transformation skipped for transformationName')}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers) +
                            ', ' +
                            skipErrorstatus
                        );
                    }
                case 'INTEGRATION_STARTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Integration Started')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Integration started for transformationName')}</span>` +
                            ':' +
                            ` ${temp.chainName} ` +
                            ',' +
                            `<span class="d-inline-block">${this.translateMsg('reportName')}</span>` +
                            ':' +
                            ` ${temp.reportName} ` +
                            ',  ' +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'INTEGRATION_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Integration Completed')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Integration completed for chainName')}</span>` +
                            ':' +
                            ` ${temp.chainName} ` +
                            ',' +
                            `<span class="d-inline-block">${this.translateMsg('reportName')}</span>` +
                            ':' +
                            ` ${temp.reportName} ` +
                            ', ' +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'INTEGRATION_TERMINATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Integration Terminated')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Integration terminated for chainName')}</span>` +
                            ':' +
                            ` ${temp.chainName} ` +
                            ',' +
                            `<span class="d-inline-block">${this.translateMsg('reportName')}</span>` +
                            ':' +
                            ` ${temp.reportName} ` +
                            ',' +
                            `<span class="d-inline-block">${this.translateMsg('error message')}</span>` +
                            ':' +
                            temp.errorMessage +
                            `<span class="d-inline-block">${this.translateMsg('and ')}</span>` +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'REQUEST_CANCELLED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transmission Cancelled')}`;
                    } else if (temp.stageResponse) {
                        if (temp.stageResponse.executionStatus === this.translateMsg('Request cancelled')) {
                            temp.stageResponse.executionStatus = this.translateMsg('Inbound transmission cancelled');
                        }
                        return temp !== undefined
                            ? temp.stageResponse.executionStatus +
                                  `<span class="d-inline-block">${this.translateMsg('for')}</span>` +
                                  ' ' +
                                  ` ${temp.assetName !== undefined ? temp.assetName : temp.bridgeName} ` +
                                  `<span class="d-inline-block">${this.translateMsg('asset')}</span>` +
                                  ', ' +
                                  this.constructId(temp, temp.partitionIdentifiers)
                            : `<span class="d-inline-block">${this.translateMsg('Inbound transmission cancelled for id')}</span>` +
                                  ':' +
                                  ` ${model.userId} `;
                    } else {
                        temp.stageResponse = {
                            executionStatus: `${this.translateMsg('Inbound transmission cancelled')}`,
                        };
                        return temp !== undefined
                            ? temp.stageResponse.executionStatus +
                                  ' ' +
                                  `<span class="d-inline-block">${this.translateMsg('for')}</span>` +
                                  ' ' +
                                  ` ${
                                      temp.assetName !== undefined
                                          ? temp.assetName
                                          : temp.bridgeName
                                          ? temp.bridgeName
                                          : this.translateMsg('this')
                                  } ` +
                                  `${this.translateMsg('asset')}` +
                                  ', ' +
                                  this.constructId(temp, temp.partitionIdentifiers)
                            : `<span class="d-inline-block">${this.translateMsg('Inbound transmission cancelled for id')}</span> ` +
                                  ':' +
                                  ` ${model.userId} `;
                    }
                case 'TASK_STARTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Task Started')}`;
                    } else if (temp.type === 'InstanceTaskEvent' || temp.type === 'InstanceEvent') {
                        if (eType === 'masterEvents') {
                            return this.translateMsg(temp?.stageResponse?.executionStatus || temp?.executionStatus);
                        } else {
                            return `${temp.taskName || 'Task'} <span class="d-inline-block"> ${
                                temp?.instanceId ? this.translateMsg('started for process id') : this.translateMsg('started for request id')
                            } </span>: ${temp?.instanceId ? `${temp.instanceId}` : `${temp.requestId}`}`;
                        }
                    } else {
                        if (temp.taskName === this.translateMsg('Request Cancellation')) {
                            temp.taskName = this.translateMsg('Process cancellation');
                        }
                        return (
                            temp.taskName +
                            ` <span class="d-inline-block"> ${this.translateMsg('for')} </span> ` +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'TASK_INITIATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Task Initiated')}`;
                    } else if (temp.type === 'InstanceTaskEvent' || temp.type === 'InstanceEvent') {
                        if (eType === 'masterEvents') {
                            return this.translateMsg(temp?.stageResponse?.executionStatus || temp?.executionStatus);
                        } else {
                            return `${temp.taskName || 'Task'} <span class="d-inline-block"> ${
                                temp?.instanceId
                                    ? this.translateMsg('initiated for process id')
                                    : this.translateMsg('initiated for request id')
                            } </span>: ${temp?.instanceId ? `${temp.instanceId}` : `${temp.requestId}`}`;
                        }
                    } else {
                        if (temp.taskName === this.translateMsg('Request Cancellation')) {
                            temp.taskName = this.translateMsg('Process cancellation');
                        }
                        return (
                            temp.taskName +
                            ` <span class="d-inline-block"> ${this.translateMsg('for')} </span> ` +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'LOGIN_FAILURE':
                    if (eType === 'type') {
                        return `${this.translateMsg('Login Failure')}`;
                    } else {
                        return model.details;
                    }
                case 'TASK_FAILURE':
                    if (eType === 'type') {
                        return `${this.translateMsg('Task Failed')}`;
                    } else {
                        if (temp.stageResponse === undefined && temp.exception === undefined) {
                            return `${temp.taskName || 'Task'} failure ` + JSON.stringify(temp.validations.toString());
                        }
                        if (temp.stageResponse === undefined && temp.exception !== undefined) {
                            return temp.exception.message;
                        } else {
                            const errorMsg =
                                temp.stageResponse.errorStatus !== undefined
                                    ? temp.stageResponse.errorStatus
                                    : temp.stageResponse.executionStatus;
                            return errorMsg + this.constructId(temp, temp.partitionIdentifiers);
                        }
                    }
                case 'TASK_FAILED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Task Failed')}`;
                    } else {
                        return temp.errorStatus
                            ? `${temp.errorStatus}`
                            : `${
                                  temp.executionStatus
                                      ? `<span class="d-inline-block">${this.translateMsg('Task Failed with status')}</span>` +
                                        ':' +
                                        temp.executionStatus
                                      : `<span class="d-inline-block">${this.translateMsg('Task Failed')}</span>`
                              }`;
                    }
                case 'LOGOFF_SUCCESSFUL':
                    if (eType === 'type') {
                        return `${this.translateMsg('Logoff Successful')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Logged off successfully by')}</span>` +
                            ` ${temp.updatedBy ?? temp.userId} ` +
                            `<span class="d-inline-block">${this.translateMsg('user')}</span>` +
                            '.'
                        );
                    }
                case 'PARTNER_ASSOCIATION_CREATION':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partner Association Creation')}`;
                    } else {
                        return (
                            ` ${temp.partnerOrgName} ` +
                            `<span class="d-inline-block">${this.translateMsg('organization')}</span>` +
                            ` ${temp.association} ` +
                            `<span class="d-inline-block">${this.translateMsg('association created')}</span>` +
                            '.' +
                            ``
                        );
                    }
                case 'PARTNER_DETAILS_UPDATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partner Details Updated')}`;
                    } else {
                        return `<span class="d-inline-block">${this.translateMsg('Partner')}</span> ${
                            temp.partnerOrgName
                        } <span class="d-inline-block">${this.translateMsg('s external id changed to')}</span> ${temp.partnerExternalId}`;
                    }
                case 'ORG_DETAILS_UPDATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Organization Details Updated')}`;
                    } else {
                        const orgNames = await this._taxilla.locations.getOrgNamesByIds({
                            orgIds: [temp.orgId],
                            noAlerts: false,
                        });
                        const organizationName = orgNames[temp.orgId];
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Organization')}</span>` +
                            ':' +
                            `'${organizationName}' <span class="d-inline-block">${this.translateMsg('details updated by')}</span> '${
                                temp.updatedBy
                            }'`
                        );
                    }
                case 'ORG_UPDATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Organization Updated')}`;
                    } else {
                        return (
                            ` ${this.getAddress(temp.addressesOldNewValues, temp)} ` +
                            `<span class="d-inline-block">${this.translateMsg('address has been updated by user')}</span>` +
                            ` ${temp.updatedBy} `
                        );
                    }
                case 'INSTANCE_CLOSED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Closed')}`;
                    } else {
                        if (temp?.assetDetail !== undefined) {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process closed for')}</span> ` +
                                this.constructId(temp.assetDetail, temp.assetDetail.partitionIdentifiers, null, isProcessEvent)
                            );
                        } else {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process closed for')}</span> ` +
                                this.constructId(temp, temp.partitionIdentifiers, null, isProcessEvent)
                            );
                        }
                    }
                case 'DATA_RECEIVED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Data Received')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Data received for process id')}</span>` +
                            ':' +
                            ` ${model.resourceId} `
                        );
                    }
                case 'TRANSFORMATION_TERMINATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Transformation Terminated')}`;
                    } else {
                        const errorStatus = temp.errorStatus !== undefined ? temp.errorStatus : '';
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Transformation terminated for transformationName')}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            `<span class="d-inline-block">${this.translateMsg('and errorStatus')}</span>` +
                            ':' +
                            errorStatus
                        );
                    }
                case 'INSTANCE_CREATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Created')}`;
                    } else {
                        if (temp?.assetDetail !== undefined) {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process created for')}</span> ` +
                                this.constructId(temp.assetDetail, temp.assetDetail.partitionIdentifiers, null, isProcessEvent)
                            );
                        } else {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process created for')}</span> ` +
                                this.constructId(temp, temp.partitionIdentifiers, null, isProcessEvent)
                            );
                        }
                    }
                case 'PARTITIONING_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partitioning Completed')}`;
                    } else {
                        if (temp?.assetDetail !== undefined) {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Partitioning completed for')}</span>` +
                                this.constructId(temp.assetDetail, temp.assetDetail.partitionIdentifiers, model.eventType)
                            );
                        }
                        if (temp.validations && temp.validations.length !== 0) {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Partitioning completed')}</span>` +
                                JSON.stringify(temp.validations.join(', '))
                            );
                        } else {
                            return `<span class="d-inline-block">${this.translateMsg('Partitioning Completed')}</span>`;
                        }
                    }
                case 'PARTITION_FAILURE':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partiton Failure')}`;
                    } else {
                        return (
                            temp.assetDetail.errorStatus +
                            `<span class="d-inline-block">${this.translateMsg('for')}</span>` +
                            this.constructId(temp.assetDetail, temp.errorMsgs)
                        );
                    }
                case 'PARTITION_CREATION':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partiton Creation')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Partition created for')}</span>` +
                            this.constructId(temp.assetDetail, temp.partitionIdentifiers)
                        );
                    }
                case 'INSTANCE_OVERRIDEN':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Overriden')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Process overriden for')}</span>` +
                            this.constructId(temp.stageResponse, temp.stageResponse.partitionIdentifier, null, isProcessEvent)
                        );
                    }
                case 'INSTANCE_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Completed')}`;
                    } else {
                        if (temp?.assetDetail !== undefined) {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process completed for')}</span> ` +
                                this.constructId(temp.assetDetail, temp.assetDetail.partitionIdentifiers, null, isProcessEvent)
                            );
                        } else {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process completed for')}</span> ` +
                                this.constructId(temp, temp.partitionIdentifiers, null, isProcessEvent)
                            );
                        }
                    }
                // case 'PARTNER_APPROVED':
                //     if (eType === 'type') {
                //         return 'Partner Approved';
                //     } else {
                //         return ` ${temp.organizationName} ` + ' organization partner status is' + ` ${temp.status} `;
                //     }
                //     break;
                // case 'PARTNER_REJECTED':
                //     if (eType === 'type') {
                //         return 'Partner Rejected';
                //     } else {
                //         return ` ${temp.organizationName} ` + ' organization partner status is' + ` ${temp.status} `;
                //     }
                //     break;
                // case 'ORG_SIGNUP':
                //     if (eType === 'type') {
                //         return 'Org Signup';
                //     } else {
                //         return `Organization setup is completed by '${temp.orgName}' organization.`;
                //     }
                //     break;
                case 'LOGIN_SUCCESSFUL':
                    if (eType === 'type') {
                        return `${this.translateMsg('Login Successful')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Logged in successfully by')}</span> '${
                                temp.updatedBy ?? temp.userId
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'PASSWORD_RESET_REQUESTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Password Reset Requested')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Reset password requested is successfully by')}</span> '${
                                temp.userName
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'PASSWORD_UPDATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Password Updated')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Password Updated by')}</span> '${
                                temp.userName
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'USER_PROFILE_UPDATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('User Profile Updated')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('User profile is updated by')}</span> '${
                                temp.userName
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'PASSWORD_UPDATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('password updated')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('password updated by')}</span> '${
                                temp.userName
                            }' '<span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'USER_ADDED':
                    if (eType === 'type') {
                        return `${this.translateMsg('User Added')}`;
                    } else {
                        return (
                            `'${temp.userName}' <span class="d-inline-block">${this.translateMsg('user is added by')}</span> '${
                                temp.addedBy
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'ORG_REGISTERED':
                case 'ORGANIZATION_REGISTERED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Organization Registered')}`;
                    } else {
                        return (
                            `'${temp.orgName}' <span class="d-inline-block">${this.translateMsg('organization is registered by')}</span>'${
                                temp.userDisplayName || temp.userName
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'ROLE_CREATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Role Created')}`;
                    } else {
                        return (
                            `'${temp.roleName}' <span class="d-inline-block">${this.translateMsg('role is created by')}</span> '${
                                temp.createdBy ?? temp.userName
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'USER_SETUP_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('User Setup Completed')}`;
                    } else {
                        return (
                            `'${temp.userName}' <span class="d-inline-block">${this.translateMsg('user setup completed by')}</span> '${
                                temp.activatedBy
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'ROLE_DELETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Role Deleted')}`;
                    } else {
                        return (
                            `'${temp.roleName}' <span class="d-inline-block">${this.translateMsg('role is deleted by')}</span> '${
                                temp.deletedBy
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'ROLE_UPDATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Role Updated')}`;
                    } else {
                        return (
                            `'${temp.roleName}' <span class="d-inline-block">${this.translateMsg('role is updated by')}</span> '${
                                temp.updatedBy
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'USER_ACTIVATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('User Activated')}`;
                    } else {
                        return (
                            `'${temp.userName}' <span class="d-inline-block">${this.translateMsg('user is activated by')}</span> '${
                                temp.activatedBy ?? temp.updatedBy ?? temp.orgName
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'IDP_USER_ACTIVATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Idp User Activated')}`;
                    } else {
                        return (
                            `'${temp.userName}' <span class="d-inline-block">${this.translateMsg('user is activated by')}</span> '${
                                temp.updatedBy ?? temp.orgName
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'IDP_USER_ONBOARDED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Idp User Onboarded')}`;
                    } else {
                        return (
                            `'${temp.userName}' <span class="d-inline-block">${this.translateMsg('Idp user onboarded  by')}</span> '${
                                temp.updatedBy ?? temp.orgName
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'USER_DEACTIVATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('User Deactivated')}`;
                    } else {
                        return (
                            `'${temp.userName}' <span class="d-inline-block">${this.translateMsg('user is deactivated by')}</span> '${
                                temp.deactivatedBy
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                        );
                    }
                case 'ROLE_ASSIGNED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Role Assigned')}`;
                    } else {
                        let assignEvent = '';
                        if (temp.userDetails.length > 1) {
                            temp.userDetails.forEach((index) => {
                                assignEvent += `${index.userName}, `;
                            });
                            assignEvent = assignEvent.slice(0, -2);
                        } else {
                            assignEvent = `${temp.userDetails[0].userName}`;
                        }
                        return (
                            assignEvent +
                            ` <span class="d-inline-block">${this.translateMsg('is assigned to')}</span> '${
                                temp.roleName
                            }' <span class="d-inline-block">${this.translateMsg('role')}</span>` +
                            '.'
                        );
                    }
                case 'ROLE_UNASSIGNED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Role Unassigned')}`;
                    } else {
                        let unassignEvent = '';
                        if (temp.userDetails.length > 1) {
                            temp.userDetails.forEach((index) => {
                                unassignEvent += `${index.userName}, `;
                            });
                            unassignEvent = unassignEvent.slice(0, -2);
                        } else {
                            unassignEvent = `${temp.userDetails[0].userName}`;
                        }
                        return (
                            unassignEvent +
                            `<span class="d-inline-block">${this.translateMsg('is unassigned to')}</span> '${
                                temp.roleName
                            }' <span class="d-inline-block">${this.translateMsg('role')}</span>` +
                            '.'
                        );
                    }
                case 'PERMISSION_ASSIGNED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Permission Assigned/Unassigned')}`;
                    } else {
                        if (temp.oldNewVsPermIds.newPermissions !== undefined && temp.oldNewVsPermIds.oldPermissions === undefined) {
                            const newUsersAssigned = this.getNewUsersValueList(temp.oldNewVsPermIds.newPermissions);
                            return (
                                `'${newUsersAssigned}' <span class="d-inline-block">${this.translateMsg(
                                    'permission assigned to'
                                )}</span> '${temp.roleName}' <span class="d-inline-block">${this.translateMsg('role by')}</span> '${
                                    temp.userName
                                }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                            );
                        } else if (temp.oldNewVsPermIds.oldPermissions !== undefined && temp.oldNewVsPermIds.newPermissions === undefined) {
                            const oldUsersAssigned = this.getOldUsersValueList(temp.oldNewVsPermIds.oldPermissions);
                            return (
                                `${oldUsersAssigned}' <span class="d-inline-block">${this.translateMsg(
                                    'permission unassigned to'
                                )}</span> '${temp.roleName}' <span class="d-inline-block">${this.translateMsg('role by')}</span> '${
                                    temp.userName
                                }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                            );
                        } else {
                            const newUsersAssigned = this.getNewUsersValueList(temp.oldNewVsPermIds.newPermissions);
                            const oldUsersAssigned = this.getOldUsersValueList(temp.oldNewVsPermIds.oldPermissions);
                            return (
                                `${newUsersAssigned}' <span class="d-inline-block">${this.translateMsg(
                                    'permission assigned and'
                                )}</span> '${oldUsersAssigned}' <span class="d-inline-block">${this.translateMsg(
                                    'permission unassigned to'
                                )}</span> '${temp.roleName}' <span class="d-inline-block">${this.translateMsg('role by')}</span> '${
                                    temp.userName
                                }' <span class="d-inline-block">${this.translateMsg('user')}</span>` + '.'
                            );
                        }
                    }
                case 'SUBSCRIPTION_CREATION':
                    if (eType === 'type') {
                        return `${this.translateMsg('Subscription Creation')}`;
                    } else {
                        return (
                            `${temp.assetNames.join(', ')}' <span class="d-inline-block">${this.translateMsg(
                                'subscription is created by'
                            )}</span> '${temp.userName}' <span class="d-inline-block">${this.translateMsg(
                                'user for'
                            )}</span> '${temp.orgNames.join(', ')}' <span class="d-inline-block">${this.translateMsg(
                                'organization'
                            )}<span class="d-inline-block">` + '.'
                        );
                    }
                case 'SUBSCRIPTION_ACTIVATION':
                    if (eType === 'type') {
                        return `${this.translateMsg('Subscription Activation')}`;
                    } else {
                        return (
                            `${temp.assetNames.join(', ')} <span class="d-inline-block">${this.translateMsg(
                                'subscription is activated by'
                            )}</span> '${temp.userName}' <span class="d-inline-block">${this.translateMsg(
                                'user for'
                            )}</span> '${temp.orgNames.join(', ')}' <span class="d-inline-block">${this.translateMsg(
                                'organization'
                            )}</span>` + '.'
                        );
                    }
                case 'SUBSCRIPTION_DEACTIVATION':
                    if (eType === 'type') {
                        return `${this.translateMsg('Subscription Deactivation')}`;
                    } else {
                        return (
                            `'${temp.assetNames.join(', ')}' <span class="d-inline-block">${this.translateMsg(
                                'subscription is deactivated by'
                            )}</span> '${temp.userName}' <span class="d-inline-block">${this.translateMsg(
                                'user for'
                            )}</span> '${temp.orgNames.join(', ')}' <span class="d-inline-block">${this.translateMsg(
                                'organization'
                            )}</span>` + '.'
                        );
                    }
                case 'SUBSCRIPTION_REJECTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Subscription Rejected')}`;
                    } else {
                        return (
                            `'${temp.services
                                ?.reduce((services, service) => {
                                    services.push(service.displayName);
                                    return services;
                                }, [])
                                .join(', ')}' <span class="d-inline-block">${this.translateMsg('subscription is rejected by')}</span> '${
                                temp.userName
                            }' <span class="d-inline-block">${this.translateMsg('user for')}</span> '${
                                temp.subscriberName
                            }' <span class="d-inline-block">${this.translateMsg('organization')}</span>` + '.'
                        );
                    }
                case 'SUBSCRIPTION_ACTIVATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Subscription Activated')}`;
                    } else {
                        return (
                            `'${temp.services
                                ?.reduce((services, service) => {
                                    services.push(service.displayName);
                                    return services;
                                }, [])
                                .join(', ')}' <span class="d-inline-block">${this.translateMsg('subscription is activated by')}</span> '${
                                temp.userName
                            }' <span class="d-inline-block">${this.translateMsg('user for')}</span> '${
                                temp.subscriberName
                            }' <span class="d-inline-block">${this.translateMsg('organization')}</span>` + '.'
                        );
                    }
                case 'SUBSCRIPTION_DEACTIVATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Subscription Deactivated')}`;
                    } else {
                        return (
                            `'${temp.services
                                ?.reduce((services, service) => {
                                    services.push(service.displayName);
                                    return services;
                                }, [])
                                .join(', ')}' <span class="d-inline-block">${this.translateMsg('subscription is deactivated by')}</span> '${
                                temp.userName
                            }' <span class="d-inline-block">${this.translateMsg('user for')}</span> '${
                                temp.subscriberName
                            }' <span class="d-inline-block">${this.translateMsg('organization')}</span>` + '.'
                        );
                    }
                case 'SUBSCRIPTION_APPROVED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Subscription Approved')}`;
                    } else {
                        return (
                            `'${temp.services
                                ?.reduce((services, service) => {
                                    services.push(service.displayName);
                                    return services;
                                }, [])
                                .join(', ')}' <span class="d-inline-block">${this.translateMsg('subscription is approved by')}</span> '${
                                temp.userName
                            }' <span class="d-inline-block">${this.translateMsg('user for')}</span> '${
                                temp.subscriberName
                            }' <span class="d-inline-block">${this.translateMsg('organization')}</span>` + '.'
                        );
                    }
                case 'LOCATION_ADDED':
                    const curentOrganization = this._utils.getFromStorage('currentOrganization');
                    if (eType === 'type') {
                        return `${this.translateMsg('Location Added')}`;
                    } else {
                        return (
                            `'${temp.orgName}' <span class="d-inline-block">${this.translateMsg('location is added by')}</span> '${
                                curentOrganization.name
                            }' <span class="d-inline-block">${this.translateMsg('organization')}</span>` + '.'
                        );
                    }
                case 'REQUEST_CREATION':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transmission Creation')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Inbound transmission is created for')}</span> '${
                                temp.serviceName
                            }' <span class="d-inline-block">${this.translateMsg('asset')}</span>` + '.'
                        );
                    }
                case 'REQUEST_DELETION':
                case 'REQUEST_DELETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transmission Deleted')}`;
                    } else {
                        return temp !== undefined
                            ? `<span class="d-inline-block">${this.translateMsg('Inbound transmission is deleted for processId')}</span>` +
                                  ':' +
                                  ` ${temp.requestId}` +
                                  `<span class="d-inline-block">${this.translateMsg('and asset name')}</span>` +
                                  ':' +
                                  `${temp.assetName !== undefined ? temp.assetName : temp.bridgeName}`
                            : `<span class="d-inline-block">${this.translateMsg('Inbound transmission is deleted for userId')}</span>` +
                                  ':' +
                                  `${model.userId}`;
                    }
                case 'EVENT_TRIGGER':
                case 'EVENT_TRIGGERED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Event Triggered')}`;
                    } else {
                        if (temp.status === 'SUCCESS') {
                            return (
                                ` ${this._libUtils.convertEnumsToNormalWords(temp.eventType)} `.replace(/_/g, ' ') +
                                `<span class="d-inline-block">${this.translateMsg('event trigger is successful')}</span>` +
                                '.'
                            );
                        }
                        if (temp.status === 'FAILED') {
                            return (
                                ` ${this._libUtils.convertEnumsToNormalWords(temp.eventType)} `.replace(/_/g, ' ') +
                                `<span class="d-inline-block">${this.translateMsg('event trigger failed due to')}</span>` +
                                ` ${temp.message} `
                            );
                        }
                    }
                    break;
                case 'APPLICATION_WARNING':
                    if (eType === 'type') {
                        return `${this.translateMsg('Application Warning')}`;
                    } else {
                        return ` ${temp.message} `;
                    }
                case 'APPLICATION_INFO':
                    if (eType === 'type') {
                        return `${this.translateMsg('Application Info')}`;
                    } else {
                        return ` ${temp.message} `;
                    }
                case 'APPLICATION_ERROR':
                    if (eType === 'type') {
                        return `${this.translateMsg('Application Error')}`;
                    } else {
                        return ` ${temp.message} `;
                    }
                case 'SETUP_ACCOUNT':
                    if (eType === 'type') {
                        return `${this.translateMsg('Setup Account')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Account setup successfully for')}</span>` +
                            ` ${temp.userName} ` +
                            `<span class="d-inline-block">${this.translateMsg('user of')}</span>` +
                            ` ${temp.orgName} ` +
                            `<span class="d-inline-block">${this.translateMsg('organization')}</span>` +
                            '.'
                        );
                    }
                case 'REQUEST_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transmission Completed')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Inbound transmission completed for id')}</span>` +
                            ':' +
                            ` ${temp.requestId} `
                        );
                    }
                case 'REQUEST_INDEXING_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transmission Indexing Completed')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Inbound Transmission Indexing Completed for id')}</span>` +
                            ':' +
                            ` ${temp.requestId} `
                        );
                    }
                case 'REQUEST_QUEUE':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transmission Queued')}`;
                    } else {
                        if (temp.action === 'PUSH') {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Inbound Transmission queued for business key')}</span>` +
                                this.constructId(temp, temp.businessKeys) +
                                `<span class="d-inline-block">${this.translateMsg('for subsequent execution for')}</span>` +
                                this.constructId(temp, temp.partitionIdentifiers)
                            );
                        }
                        if (temp.action === 'POP') {
                            return (
                                `<span class="d-inline-block">${this.translateMsg(
                                    'Received inbound transmission from queue for business key'
                                )}</span>` +
                                this.constructId(temp, temp.businessKeys) +
                                `<span class="d-inline-block">${this.translateMsg('for execution for')}</span>` +
                                this.constructId(temp, temp.partitionIdentifiers)
                            );
                        }
                    }
                    break;
                case 'INSTANCE_QUEUED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Queued')}`;
                    } else {
                        if (temp.action === 'PUSH') {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process queued for business key')}</span>` +
                                this.constructId(temp, temp.businessKeys) +
                                `<span class="d-inline-block">${this.translateMsg('for subsequent execution for')}</span>` +
                                this.constructId(temp, temp.partitionIdentifiers, null, isProcessEvent)
                            );
                        }
                        if (temp.action === 'POP') {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Received process from queue for business key')}</span>` +
                                this.constructId(temp, temp.businessKeys) +
                                `<span class="d-inline-block">${this.translateMsg('for execution for')}</span>` +
                                this.constructId(temp, temp.partitionIdentifiers, null, isProcessEvent)
                            );
                        }
                        if (temp.instanceId) {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process Queued for process id')}</span>` +
                                ':' +
                                ` ${temp.instanceId}`
                            );
                        } else if (temp.partitionIdentifiers) {
                            return `<span class="d-inline-block">${this.translateMsg('Process Queued for')}</span> ${this.constructId(
                                temp,
                                temp.partitionIdentifiers
                            )}`;
                        }
                    }
                    break;
                case 'QUEUED_INSTANCE_INITIATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Queued Process Initiated')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Queued process initiated for Process Id')}</span>` +
                            ':' +
                            `${temp.instanceId || temp.partitionIdentifier}`
                        );
                    }
                case 'REQUEST_CANCELLED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transmission Cancelled')}`;
                    } else {
                        if (temp.stageResponse.executionStatus === this.translateMsg('Request cancelled')) {
                            temp.stageResponse.executionStatus = this.translateMsg('Inbound transmission cancelled');
                        }
                        return temp !== undefined
                            ? temp.stageResponse.executionStatus +
                                  ' ' +
                                  `<span class="d-inline-block">${this.translateMsg('for')}</span>` +
                                  ` ${temp.assetName !== undefined ? temp.assetName : temp.bridgeName} ` +
                                  `<span class="d-inline-block">${this.translateMsg('asset')}</span>` +
                                  ',' +
                                  ' ' +
                                  this.constructId(temp, temp.partitionIdentifiers)
                            : `<span class="d-inline-block">${this.translateMsg('Inbound transmission cancelled for id')}</span>` +
                                  ':' +
                                  ` ${model.userId} `;
                    }
                case 'REQUEST_TERMINATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transmission Terminated')}`;
                    } else {
                        const requestTerminatedErorStatus =
                            temp.message !== undefined && temp.message !== ''
                                ? `<span class="d-inline-block">${this.translateMsg(', error message')}</span>` + ':' + `${temp.message}`
                                : '';
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Inbound Transmission Terminated')}</span>` +
                            requestTerminatedErorStatus
                        );
                    }
                case 'USER_UNLOCKED':
                    if (eType === 'type') {
                        return `${this.translateMsg('User Unlocked')}`;
                    } else {
                        return (
                            `'${temp.userName}' <span class="d-inline-block">${this.translateMsg('user is unlocked by')}</span> '${
                                temp.unlockedBy
                            }' <span class="d-inline-block">${this.translateMsg('user')}</span>
                            ` + '.'
                        );
                    }
                case 'BULK_REPORTS_DOWNLOAD_STARTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Bulk Reports Download Started')}`;
                    } else {
                        return `${this.translateMsg('Bulk report download is started')}`;
                    }
                case 'BULK_REPORTS_DOWNLOAD_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Bulk Reports Download Completed')}`;
                    } else {
                        return `${this.translateMsg('Bulk report download is completed')}`;
                    }
                case 'BULK_REPORTS_DOWNLOAD_TERMINATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Bulk Reports Download Terminated')}`;
                    } else {
                        return `<span class="d-inline-block">${this.translateMsg('Bulk report download is terminated due to')}</span> '${
                            temp.errorMessage
                        }`;
                    }
                case 'PARTNER_ASSOCIATION_REQUESTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partner Association Requested')}`;
                    } else {
                        return `<span class="d-inline-block">${this.translateMsg(
                            'Partner association requested for organization'
                        )}</span> '${temp.partnerOrgName}`;
                    }
                case 'INBOUND_TRANSFORMATION_STARTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transformation Started')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Inbound transformation started for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'INBOUND_TRANSFORMATION_COMPLETED':
                    const inboundErorStatus = temp.errorStatus !== undefined ? temp.errorStatus : '';
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transformation Completed')}`;
                    } else if (eType === 'masterEvents' && inboundErorStatus.length === 0) {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Inbound transformation completed for transformationName'
                            )}</span>` +
                            ':' +
                            `${temp.transformationName}`
                        );
                    } else if (eType === 'masterEvents') {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Inbound transformation completed for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ', ' +
                            inboundErorStatus
                        );
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Inbound transformation completed for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers) +
                            ', ' +
                            inboundErorStatus
                        );
                    }

                case 'SUBSCRIPTION_CREATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Subscription Created')}`;
                    } else {
                        return (
                            `'${temp.services
                                ?.reduce((services, service) => {
                                    services.push(service.displayName);
                                    return services;
                                }, [])
                                .join(', ')}' <span class="d-inline-block">${this.translateMsg('subscription is created by')}</span> ${
                                temp.userName
                            } <span class="d-inline-block">${this.translateMsg('user for')}</span> '${
                                temp.subscriberName
                            }' <span class="d-inline-block">${this.translateMsg('organization')}</span>` + '.'
                        );
                    }
                case 'PARTNER_INVITED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partner Invited')}`;
                    } else {
                        return `'${temp.partnerOrgName}' <span class="d-inline-block">${this.translateMsg(
                            'Partner Invited for organization'
                        )}</span> '${temp.orgName}'`;
                    }
                case 'PARTNER_INVITATION_APPROVED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partner Invitation Approved')}`;
                    } else {
                        return (
                            `'${temp.partnerOrgName}'  <span class="d-inline-block">${this.translateMsg(
                                'partner invitation approved for'
                            )}</span> '${temp.orgName}' <span class="d-inline-block">${this.translateMsg('organization')}</span>` + '.'
                        );
                    }
                case 'PARTNER_INVITATION_REJECTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partner Invitation Rejected')}`;
                    } else {
                        return (
                            `'${temp.partnerOrgName}'  <span class="d-inline-block">${this.translateMsg(
                                'partner invitation rejected for'
                            )}</span> '${temp.orgName}' <span class="d-inline-block">${this.translateMsg('organization')}</span> ` + '.'
                        );
                    }
                case 'INBOUND_TRANSFORMATION_TERMINATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transformation Terminated')}`;
                    } else {
                        const terminatedErrorStatus = temp.errorStatus !== undefined ? temp.errorStatus : '';
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Inbound transformation terminated for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            `<span class="d-inline-block">${this.translateMsg('and errorStatus')}</span>` +
                            ':' +
                            terminatedErrorStatus
                        );
                    }
                case 'INBOUND_TRANSFORMATION_SKIPPED':
                    const inboundSkipErrorStatus = temp.errorStatus !== undefined ? temp.errorStatus : '';
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound Transformation Skipped')}`;
                    } else if (eType === 'masterEvents') {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Inbound transformation skipped for transformationName '
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ', ' +
                            inboundSkipErrorStatus
                        );
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Inbound transformation skipped for transformationName '
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers) +
                            ', ' +
                            inboundSkipErrorStatus
                        );
                    }
                case 'OUTBOUND_TRANSFORMATION_STARTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Outbound Transformation Started')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation started for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'OUTBOUND_TRANSFORMATION_COMPLETED':
                    const outboundErorStatus = temp.errorStatus !== undefined ? temp.errorStatus : '';
                    if (eType === 'type') {
                        return `${this.translateMsg('Outbound Transformation Completed')}`;
                    } else if (eType === 'masterEvents' && outboundErorStatus.length === 0) {
                        return (
                            `  <span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation completed for transformationName'
                            )}</span>` +
                            ':' +
                            `${temp.transformationName}`
                        );
                    } else if (eType === 'masterEvents') {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation completed for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ', ' +
                            outboundErorStatus
                        );
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation completed for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers) +
                            ', ' +
                            outboundErorStatus
                        );
                    }
                case 'OUTBOUND_TRANSFORMATION_INFO':
                    const outboundEror = temp.errorStatus !== undefined ? temp.errorStatus : '';
                    if (eType === 'type') {
                        return `${this.translateMsg('Outbound Transformation Info')}`;
                    } else if (eType === 'masterEvents' && outboundEror.length === 0) {
                        return (
                            `  <span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation info for transformationName'
                            )}</span>` +
                            ':' +
                            `${temp.transformationName}`
                        );
                    } else if (eType === 'masterEvents') {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation info for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ', ' +
                            outboundEror
                        );
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation info for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers) +
                            ', ' +
                            outboundEror
                        );
                    }
                case 'OUTBOUND_TRANSFORMATION_TERMINATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Outbound Transformation Terminated')}`;
                    } else {
                        const terminatedErrorStatus = temp.errorStatus !== undefined ? temp.errorStatus : '';
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation terminated for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            `<span class="d-inline-block">${this.translateMsg('and errorStatus')}</span>` +
                            ':' +
                            terminatedErrorStatus
                        );
                    }
                case 'OUTBOUND_TRANSFORMATION_SKIPPED':
                    const outboundSkipErrorStatus = temp.errorStatus !== undefined ? temp.errorStatus : '';
                    if (eType === 'type') {
                        return `${this.translateMsg('Outbound Transformation Skipped')}`;
                    } else if (eType === 'masterEvents') {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation skipped for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ', ' +
                            outboundSkipErrorStatus
                        );
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Outbound transformation skipped for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.transformationName} ` +
                            ' , ' +
                            this.constructId(temp, temp.partitionIdentifiers) +
                            ', ' +
                            outboundSkipErrorStatus
                        );
                    }
                case 'OUTBOUND_INTEGRATION_STARTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Outbound Integration Started')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg(
                                'Outbound integration started for transformationName'
                            )}</span>` +
                            ':' +
                            ` ${temp.chainName} ` +
                            ',' +
                            `<span class="d-inline-block">${this.translateMsg('reportName')}</span>` +
                            ':' +
                            ` ${temp.reportName} ` +
                            ',  ' +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'OUTBOUND_INTEGRATION_COMPLETED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Outbound Integration Completed')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Outbound integration completed for chainName')}</span>` +
                            ':' +
                            ` ${temp.chainName} ` +
                            ',' +
                            `<span class="d-inline-block">${this.translateMsg('reportName')}</span>` +
                            ':' +
                            ` ${temp.reportName} ` +
                            ', ' +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'OUTBOUND_INTEGRATION_TERMINATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Outbound Integration Terminated')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Outbound integration terminated for chainName')}</span>` +
                            ':' +
                            ` ${temp.chainName} ` +
                            ',' +
                            `<span class="d-inline-block">${this.translateMsg('reportName')}</span>` +
                            ':' +
                            ` ${temp.reportName} ` +
                            ',' +
                            `<span class="d-inline-block">${this.translateMsg('error message')}</span>` +
                            ':' +
                            temp.errorMessage +
                            `<span class="d-inline-block">${this.translateMsg('and')}</span>` +
                            this.constructId(temp, temp.partitionIdentifiers)
                        );
                    }
                case 'PARTITIONING_STARTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partitioning started')}`;
                    } else {
                        return `${this.translateMsg('Partitioning started')}`;
                    }
                case 'PARTITIONS_CREATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partitions Created')}`;
                    } else {
                        return `${this.translateMsg('Partitions Created')}`;
                    }
                case 'INSTANCES_CREATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Processes Created')}`;
                    } else {
                        return `${this.translateMsg('Processes Created')}`;
                    }
                case 'PARTITIONING_FAILED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partitioning Failed for')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Partitioning Failed for')}</span>` +
                            this.constructId(temp, temp.errorMessages)
                        );
                    }
                case 'REQUEST_CREATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Inbound transmission created')}`;
                    } else {
                        return `${this.translateMsg('Inbound transmission is created')}`;
                    }
                case 'INSTANCE_UPDATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Updated')}`;
                    } else {
                        if (temp?.assetDetail !== undefined) {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process updated for')}</span>` +
                                this.constructId(temp.assetDetail, temp.assetDetail.partitionIdentifiers, null, isProcessEvent)
                            );
                        } else {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process updated for')}</span>` +
                                this.constructId(temp, temp.partitionIdentifiers, null, isProcessEvent)
                            );
                        }
                    }
                case 'INSTANCE_TERMINATED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process terminated')}`;
                    } else {
                        if (temp?.assetDetail !== undefined && !this._utils.isEmpty(temp.assetDetail.partitionIdentifiers)) {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process terminated for')}</span>` +
                                this.constructId(temp.assetDetail, temp.assetDetail.partitionIdentifiers, null, isProcessEvent)
                            );
                        } else if (!this._utils.isEmpty(temp.partitionIdentifiers)) {
                            return (
                                `<span class="d-inline-block">${this.translateMsg('Process terminated for')}</span>` +
                                this.constructId(temp, temp.partitionIdentifiers, null, isProcessEvent)
                            );
                        } else {
                            return `${this.translateMsg('Process terminated')}`;
                        }
                    }
                case 'INSTANCE_CANCELLED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Cancelled')}`;
                    } else {
                        if (temp.assetDetail?.executionStatus === this.translateMsg('Request cancelled')) {
                            temp.assetDetail.executionStatus = this.translateMsg('Process cancelled') + ' ';
                        }
                        return (
                            temp.assetDetail?.executionStatus +
                            `<span class="d-inline-block">${this.translateMsg('for')}</span>` +
                            ` ${temp.serviceName} ` +
                            `<span class="d-inline-block">${this.translateMsg('asset')}</span>` +
                            ',' +
                            this.constructId(temp, temp.assetDetail?.partitionIdentifiers, null, isProcessEvent)
                        );
                    }
                case 'INSTANCE_CANCELLATION_STARTED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Cancellation Started')}`;
                    } else {
                        return (
                            `<span class="d-inline-block">${this.translateMsg('Process cancellation started for')}</span>` +
                            ` ${temp.serviceName} ` +
                            `<span class="d-inline-block">${this.translateMsg('asset')}</span>` +
                            ',' +
                            this.constructId(temp, temp.assetDetail?.partitionIdentifiers, null, isProcessEvent)
                        );
                    }
                case 'INSTANCE_CANCELLATION_FAILED':
                    if (eType === 'type') {
                        return `${this.translateMsg('Process Cancellation Failed')}`;
                    } else {
                        return (
                            temp.assetDetail?.executionStatus +
                            `<span class="d-inline-block">${'for'}</span>` +
                            ` ${temp.serviceName} ` +
                            `<span class="d-inline-block">${this.translateMsg('asset')}</span>` +
                            ',' +
                            this.constructId(temp, temp.assetDetail?.partitionIdentifiers, null, isProcessEvent)
                        );
                    }
                case 'DOCUMENT_SHARED_WITH_PARTNER':
                    if (eType === 'type') {
                        return `${this.translateMsg('Document shared with partner')}`;
                    } else {
                        const message =
                            `<span class="d-inline-block">${this.translateMsg('Document shared with partners')}</span>` +
                            (temp.emails ? temp.emails : '') +
                            `<span class="d-inline-block">${'for request id'}</span> ` +
                            ':' +
                            ` ${temp.requestId}`;
                        return message;
                    }
                case 'PARTNER_UPDATED_DOCUMENT':
                    if (eType === 'type') {
                        return `${this.translateMsg('Partner Updated Document')}`;
                    } else {
                        const message =
                            `<span class="d-inline-block">${this.translateMsg('Partner')}</span>` +
                            (temp.partnerName ? temp.partnerName : '') +
                            (temp.emails ? ' (' + temp.emails + ') ' : '') +
                            ` <span class="d-inline-block">${this.translateMsg('updated document for process id')}</span>` +
                            ':' +
                            ` ${temp.instanceId}`;
                        return message;
                    }
            }
        }
    };

    constructId = (eventDetails, partitionIdentifier, eventType?, isProcessEvent?) => {
        if (partitionIdentifier !== undefined && !this._utils.isEmpty(partitionIdentifier)) {
            let temp = '';
            for (const key in partitionIdentifier) {
                if (partitionIdentifier.hasOwnProperty(key)) {
                    temp = temp + ' | ' + partitionIdentifier[key];
                }
            }
            if (temp.length > 1) {
                temp = temp.substring(1, temp.length);
            }
            return `<span class="d-inline-block"> ${this.translateMsg('Partition Identifiers')}</span>` + ':' + ` ${temp} `;
        } else if (eventDetails.assetDataId !== undefined) {
            if (eventType === 'PARTITIONING_COMPLETED') {
                return (
                    `<span class="d-inline-block"> ${this.translateMsg('inbound transmissionId')}</span>` +
                    ':' +
                    ` ${eventDetails.bulkRequestId} `
                );
            } else {
                return `<span class="d-inline-block"> ${this.translateMsg('process id')}</span> ` + ':' + ` ${eventDetails.assetDataId} `;
            }
        } else if (isProcessEvent) {
            return ` <span class="d-inline-block"> ${this.translateMsg('process id')}</span>` + ':' + `  ${eventDetails.instanceId} `;
        } else {
            if (eventDetails?.assetDetail !== undefined) {
                return (
                    `<span class="d-inline-block"> ${this.translateMsg('inbound transmissionId')}</span>` +
                    ':' +
                    ` ${eventDetails.assetDetail.bulkRequestId} `
                );
            } else {
                return (
                    `<span class="d-inline-block"> ${this.translateMsg('inbound transmissionId')}</span>` +
                    ':' +
                    ` ${eventDetails.bulkRequestId || eventDetails.requestId} `
                );
            }
        }
    };

    getNewUsersList = (newUsers) => {
        const tempNewArray = [];
        if (newUsers !== undefined && newUsers !== null) {
            const keys = Object.keys(newUsers);
            keys.forEach((key) => {
                tempNewArray.push(key);
            });
            if (tempNewArray.toString().length > 100) {
                return 'All';
            }
        }
        return tempNewArray;
    };

    getNewUsersValueList = (newUsers) => {
        const tempNewArray = [];
        if (newUsers !== undefined && newUsers !== null) {
            const keys = Object.values(newUsers);
            keys.forEach((value) => {
                tempNewArray.push(value);
            });
            if (tempNewArray.toString().length > 100) {
                return 'All';
            }
        }
        return tempNewArray;
    };

    getOldUsersList = (oldUsers) => {
        const tempOldArray = [];
        if (oldUsers !== undefined && oldUsers !== null) {
            const keys = Object.keys(oldUsers);
            keys.forEach((key) => {
                tempOldArray.push(key);
            });
            if (tempOldArray.toString().length > 100) {
                return 'All';
            }
        }
        return tempOldArray;
    };

    getOldUsersValueList = (oldUsers) => {
        const tempOldArray = [];
        if (oldUsers !== undefined && oldUsers !== null) {
            const keys = Object.values(oldUsers);
            keys.forEach((value) => {
                tempOldArray.push(value);
            });
            if (tempOldArray.toString().length > 100) {
                return 'All';
            }
        }
        return tempOldArray;
    };

    getUserProfileUpdated = (userDetails) => {
        if (userDetails.oldNewValues !== undefined) {
            const tempArray = [];
            const keys = Object.keys(userDetails.oldNewValues);
            keys.forEach((value) => {
                if (value.substring(0, 8) !== 'password' && value.substring(0, 11) !== 'displayName') {
                    tempArray.push(value);
                }
            });
            return ' ( ' + tempArray + ' ) ';
        }
    };

    getAddress = (address, org) => {
        if (address?.length > 0) {
            const temp = [];
            address.forEach((addressData) => {
                if (addressData.addressType !== undefined) {
                    temp.push(addressData.addressType);
                }
            });
            if (temp.length === 0) {
                return Object.keys(address[0]);
            } else {
                return temp;
            }
        } else {
            return org?.orgName || '';
        }
    };

    getEventTypes = (s: any, f: any, type?) => {
        this._taxilla.common.getEventTypes({
            successCallback: (res) => {
                const response = type ? res[type] : res;
                s(response);
            },
            failureCallback: (res) => {
                f(res);
            },
        });
    };
}
