<div *ngIf="!isViewDocument" class="!min-h-[3.5%] d-flex justify-content-between align-items-center">
    <h2 class="col-11 !m-0" mat-dialog-title>{{ doc?.fileName }} Preview</h2>
    <div class="col-1 d-flex justify-end align-items-center" *transloco="let translate;">
        <span *ngIf="docData?.fileExtension === 'pdf'"
              class="material-icons mr-1 px-1 py-[4px] rounded-md"
              [title]="showThumbnails ? translate('Hide Thumbnails') : translate('Show Thumbnails')"
              [ngStyle]="{ 'background-color': showThumbnails ? '#00a651' : 'transparent','color': showThumbnails ? 'white' : 'black' }"
              (click)="showThumbnails = !showThumbnails">
            description
        </span>
        <a [href]="docData.fileLink" target="_blank" rel="noopener" style="cursor: pointer; display: flex;">
            <span class="material-symbols-outlined">
                download
            </span>
        </a>
        <span class="material-symbols-outlined pl-[20px]" mat-dialog-close>close</span>
    </div>
</div>
<mat-dialog-content class="mat-typography !m-0 !p-0 !overflow-auto !max-h-[96%] px-2 contentClass" *transloco="let translate;">
    <ng-container *ngIf="doc?.acceptheader === 'application/pdf'">
        <div class="doc-preview-container d-flex">
            <div *ngIf="showThumbnails" class="thumbnails-container">
                <div class="file-name">{{ doc?.fileName }}</div>
                <div
                     *ngFor="let thumbnail of pdfThumbnails; let i = index"
                     class="thumbnail"
                     (click)="navigateToPage(i + 1)">
                    <div class="page-number">Page: {{ i + 1 }}</div>
                    <img [src]="thumbnail" alt="Thumbnail {{i + 1}}" />
                </div>
            </div>

            <div class="main-preview !overflow-auto">
                <div class="pdf-page-container !h-full">
                    <canvas #pdfCanvas></canvas>
                    <div #textLayer class="pdf-text-layer"></div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="doc?.acceptheader === 'text/html'">
        <iframe class="frame" [src]='safeHtmlContent' title="{{translate('Doc File Url')}}" width="100%" height="100%" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </ng-container>

    <ng-container *ngIf="doc?.acceptheader === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
        <iframe class="frame" [src]='doc?.fileUrl' title="{{translate('Doc File Url')}}" width="100%" height="100%" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </ng-container>

    <ng-container *ngIf="doc?.acceptheader === 'text/plain'">
        <div class="!pt-[30px] text-xl">
            <pre>{{ docData.htmlContent }}</pre>
        </div>
    </ng-container>
</mat-dialog-content>
