import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentOrganizationId$ } from 'ngrx-store';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ApiService, AssetData, CommonUtilsService, MasterRecord, Masters } from 'taxilla-library';

import { FilingAttributeField } from '../../models/filingattributefield.class';

@Component({
    selector: 'filingattribute-field',
    templateUrl: './filingattributefield.component.html',
})
export class FilingAttributeFieldComponent implements OnInit, OnDestroy {
    @Input() field: FilingAttributeField;
    @Input() service: any;
    @Input() bridge: any;
    @Input() classList: any;
    @Input() type: any;
    @Input() record: FilingAttributeField[];
    @Input() metaData: AssetData;
    @Input() fieldsMap: BehaviorSubject<{
        [property: string]: {
            keyColumns: string[];
            valuesMap: { id: string; entityId: string; masterColumn: string; value: any; references: any[] }[];
        };
    }>;
    @Input() masterEntityFieldValueMap: { [property: string]: string };

    @Output() modelChange = new EventEmitter();
    @Output() debounceEventHandler = new EventEmitter();

    private unsubscribe = new Subject<void>();
    public organizationId: string;
    public masters: Masters;

    constructor(private store$: Store, private _api: ApiService) {}

    dateFormatCap = (outputFormat) => {
        return outputFormat.toUpperCase();
    };

    getConditionValue = () => {
        let value;
        if (this.field.lookupConditionExpressions?.length > 0) {
            const conditionalFieldId = this.field.lookupConditionExpressions[0]?.lookupConditionField;
            const conditionalField = this.record.find((field) => field.id === conditionalFieldId);
            value = conditionalField?.value;
        }
        return value;
    };

    setMastersVariables = () => {
        this.masters = CommonUtilsService.cloneObject(this.metaData.masters);
        this.fieldChanged();
    };

    valueChanged = (event: any) => {
        this.field.value = event;
        this.modelChange.emit(event);
    };

    fieldChanged = () => {
        const field = this.field;
        this.clearDependentValues(field.entityId, field.id);
        const { canGetMasterRecord, values, masterTableId } = this.setDependents(false);
        if (canGetMasterRecord) {
            const masterMetaData = this.metaData.masters.getTable(masterTableId);
            this._api.masters.getMasterRecordWithprimaryValue(
                {
                    masterDataRow: {
                        columnValues: values,
                        tableId: masterTableId,
                        unitId: this.organizationId,
                    },
                    masterSearchDetail: {
                        pageNo: 0,
                        searchString: '',
                    },
                },
                {
                    successCallback: (response) => {
                        const record = new MasterRecord(response?.[0], masterMetaData as any);
                        this.record
                            .filter((field) => !!field.masterData?.columnRefMetadatas?.find((ref) => ref.fieldUid === this.field.id))
                            .forEach((field) => {
                                field.value = record.getFieldByFieldId(field.masterData.columnName)?.value;
                            });
                    },
                    failureCallback: (res) => {
                        console.log(res);
                    },
                }
            );
        }
    };

    clearDependentValues = (entityUid: string, fieldId: string) => {
        this.record
            .filter((field) => field.hasMasterLookup)
            .filter((field) => field.masterData.columnRefMetadatas && field.masterData.columnRefMetadatas.length > 0)
            .forEach((field) => {
                const masterData = field.masterData;
                const references = masterData.columnRefMetadatas;
                const referenceFound = references.find((reference) => reference.entityUid === entityUid && reference.fieldUid === fieldId);
                if (referenceFound) {
                    field.value = undefined;
                    this.clearDependentValues(field.entityId, field.id);
                }
            });
    };

    setDependents = (initialRender: boolean) => {
        const map = this.field.buildMasterEntityFieldValueMap(
            this.record,
            CommonUtilsService.cloneObject(this.fieldsMap.value),
            this.masterEntityFieldValueMap,
            initialRender
        );
        const masterTableId = this.field.masterData.tableUid;
        const changedFields = map[masterTableId];
        const values = {};
        this.field.masterData.columnRefMetadatas.forEach((reference: { fieldUid: string; entityUid: string }) => {
            const tableMapId = Object.keys(map).find((tableId) => map[tableId].keyColumns.indexOf(reference.fieldUid) > -1);
            const tableMap = map[tableMapId];
            const fieldValueMap = tableMap.valuesMap.find((fieldInMap) => fieldInMap.id === reference.fieldUid);
            if (fieldValueMap) {
                values[fieldValueMap.masterColumn] = {
                    newValue: fieldValueMap.value,
                };
            }
        });
        // if (
        //     JSON.stringify(map?.[this.field.masterData.tableUid]) ===
        //         JSON.stringify(this.fieldsMap.value?.[this.field.masterData.tableUid]) &&
        //     JSON.stringify(map) !== JSON.stringify(this.fieldsMap.value)
        // ) {
        //     this.fieldsMap.next(map);
        //     return {
        //         canGetMasterRecord: false,
        //         values,
        //         masterTableId,
        //     };
        // } else
        if (JSON.stringify(map) !== JSON.stringify(this.fieldsMap.value)) {
            const currentTableId = this.field.masterData.tableUid;
            const tableMap = map[currentTableId];
            const valueMapIndex = tableMap.keyColumns.indexOf(this.field.id);
            !initialRender &&
                tableMap.keyColumns
                    .filter((_id, index) => index > valueMapIndex)
                    .forEach((id) => {
                        const fieldWithKey = this.record.find((field) => field.id === id);
                        if (fieldWithKey) {
                            if (fieldWithKey.id === this.field.id) {
                                fieldWithKey.value = undefined;
                            } else if (fieldWithKey.masterData) {
                                if (
                                    fieldWithKey.masterData.tableUid === masterTableId &&
                                    fieldWithKey.masterData.columnName !== this.field.masterData.columnName
                                ) {
                                    fieldWithKey.value = undefined;
                                }
                            }
                        }
                    });
            tableMap.valuesMap
                .filter((_id, index) => index > valueMapIndex)
                .forEach((valueMap) => {
                    valueMap.value = initialRender ? valueMap.value : undefined;
                });
            if (this.field.value !== undefined) {
                if (valueMapIndex === -1) {
                    const table = this.metaData.masters.getTable(this.field.masterData.tableUid);
                    const tableField = table.getColumn(this.field.masterData.columnName);
                    if (tableField.primaryKey) {
                        tableMap.keyColumns.push(this.field.id);
                    }
                    tableMap.valuesMap.push({
                        id: this.field.id,
                        entityId: this.field.entityId,
                        masterColumn: this.field.masterData.columnName,
                        value: this.field.value,
                        references: this.field.masterData.columnRefMetadatas,
                    });
                } else {
                    tableMap.valuesMap[valueMapIndex] = {
                        id: this.field.id,
                        entityId: this.field.entityId,
                        masterColumn: this.field.masterData.columnName,
                        value: this.field.value,
                        references: this.field.masterData.columnRefMetadatas,
                    };
                }
            }
            this.fieldsMap.next(map);
        }
        let canGetMasterRecord = true;
        changedFields.valuesMap
            .filter(
                (valueMap) =>
                    changedFields.keyColumns.includes(valueMap.id) &&
                    (valueMap.id === this.field.id || valueMap.masterColumn !== this.field.masterData.columnName)
            )
            .forEach((valueMap) => {
                const primaryKeyValue = values[valueMap.masterColumn]?.newValue || valueMap.value;
                values[valueMap.masterColumn] = {
                    newValue: primaryKeyValue,
                };
                canGetMasterRecord = canGetMasterRecord && primaryKeyValue !== undefined && primaryKeyValue.toLowerCase() !== 'na';
            });
        return {
            canGetMasterRecord,
            values,
            masterTableId,
        };
    };

    ngOnInit() {
        this.store$
            .select(getCurrentOrganizationId$)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((orgId) => {
                this.organizationId = orgId;
            });
        this.field.masterData && this.setMastersVariables();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
