<div class="integrationTabStructure integrationsPostManStructure" *transloco="let translate;">
    <div class="integrationTabHeaders">
        <ng-container *ngFor="let tab of subSection.tabs">
            <span class="integrationTabHeaderText col-2" [class.activeIntegrationTabHeader]="tab.active" [class.disabledTab]="isTabDisabled(tab, record, subSection.tabs, subSectionRecord)" (click)="setAsActiveTab(tab, subSection.tabs)">
                {{translate(tab.displayName)}}
            </span>
        </ng-container>
    </div>
    <div class="integrationSectionTabContent">
        <ng-container *ngFor="let tab of subSection.tabs">
            <ng-container *ngIf="tab.active" [ngSwitch]="tab.array">
                <ng-container *ngSwitchCase="true">
                    <div class="tabRecordsContainer" *ngIf="tab.records && tab.records.length > 0">
                        <ng-container *ngFor="let tabRecord of tab.records; let i = index">
                            <div class="tabRecordContainer row">
                                <div [ngClass]="{
                                    'addRecords': tab.canAddProperty,
                                    'tabRecordFieldsContainer': !tab.canAddProperty && !tab.placHolderMapping,
                                    'placeHolderMapingClass': tab.placHolderMapping
                                  }" [class.hasTabRecordActions]="tabRecord.recordActions && tabRecord.recordActions.length > 0">
                                    <ng-container *ngFor="let field of tabRecord.fields">
                                        <ng-container *ngIf="!field.position">
                                            Not implemented yet
                                        </ng-container>
                                        <ng-container *ngIf="field.position === 'SBS' || field.position === 'UNIQUE-SBS'">
                                            <div [ngClass]="tab.placHolderMapping ? 'placeholderField' : 'fieldPositionSBS'" class="flex-1 min-w-[200px] max-w-[300px] p-2">
                                                <ng-container *ngTemplateOutlet="integrationField; context: { record: tabRecord, field: field, hide: hideField(field, record, tab, tabRecord), parent: record }"></ng-container>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div [ngClass]="(tab.canAddProperty || tab.placHolderMapping) ?'addRecordsActions': 'tabRecordActionsContainer'" *ngIf="(tabRecord.recordActions && tabRecord.recordActions.length > 0) || (tab.canAddProperty&& tab.canDeleteProperty) || tab.placHolderMapping">
                                    <ng-container *ngFor="let field of tabRecord.recordActions">
                                        <div [ngClass]="{
                                            'addRecordsActions': tab.canAddProperty,
                                            'tabRecordActionContainer': !tab.canAddProperty && !tab.placHolderMapping,
                                            'placeHolderMaping': tab.placHolderMapping
                                          }">
                                            <ng-container *ngTemplateOutlet="integrationField; context: { record: tabRecord, field: field, hide: hideField(field, record, tab), parent: record }"></ng-container>
                                        </div>
                                    </ng-container>
                                    <div [ngClass]="{
                                        'addRecordsActions': tab.canAddProperty,
                                        'tabRecordActionContainer': !tab.canAddProperty && !tab.placHolderMapping,
                                        'placeHolderMaping': tab.placHolderMapping
                                      }" *ngIf="tab.canAddRecord || tab.canAddProperty || tab.placHolderMapping">
                                        <mat-icon (click)="addNewTabRecord(tab)">add</mat-icon>
                                    </div>
                                    <div [ngClass]="{
                                        'addRecordsActions': tab.canAddProperty,
                                        'tabRecordActionContainer': !tab.canAddProperty && !tab.placHolderMapping,
                                        'placeHolderMaping': tab.placHolderMapping
                                      }" *ngIf="tab.canDeleteRecord || tab.canDeleteProperty || tab.placHolderMapping">
                                        <mat-icon (click)="deleteTabRecord(tab, i)">delete</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="false">
                    <div class="integrationRecordField" *ngFor="let field of tab.fields">
                        <ng-container *ngTemplateOutlet="integrationField; context: { record: tab, field: field, hide: hideField(field, record, tab), parent: record }"></ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>

<ng-template #integrationField let-record="record" let-field="field" let-hide="hide" let-parent="parent">
    <ng-container *ngIf="field.displayName !== 'Key' && field.displayName !== 'Value' && field.displayName !== 'Raw Text' && field.displayName !== 'Body Type'">
        <div [ngClass]="field.placeHolderMapping ? 'placeholderFields' : 'col-5'">
            <app-integrations-field-template [subTenants]="subTenants" [masterTables]="masterTables" [record]="record" [field]="field" [hide]="hide" [parentRecord]="parent" [masterTableColumns]="masterTableColumns" (checkValidateButton)="checkValidateButton.emit($event)"></app-integrations-field-template>
        </div>
    </ng-container>
    <ng-container *ngIf="field.displayName === 'Key' || field.displayName === 'Value' || field.displayName === 'Raw Text' || field.displayName === 'Body Type'">
        <app-integrations-field-template [subTenants]="subTenants" [masterTables]="masterTables" [record]="record" [field]="field" [hide]="hide" [parentRecord]="parent"></app-integrations-field-template>
    </ng-container>
</ng-template>
